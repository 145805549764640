import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiRequest, notify } from "../../../utils/utils";

export const getAllActiveCategoryAction = createAsyncThunk(
  "getAllActiveCategory",
  async (languageType) => {
    return await makeApiRequest(
      `/vac/api/category/getAllActiveCategory?languageType=${languageType}`,
      {
        apiKey: process.env.REACT_APP_API_KEY,
      }
    );
  }
);

const initialState = {
  category: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getAllActiveCategorySlice = createSlice({
  name: "getAllActiveCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllActiveCategoryAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getAllActiveCategoryAction.fulfilled, (state, { payload }) => {
        state.category = payload.categoryData;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getAllActiveCategoryAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getAllActiveCategorySlice.reducer;
