import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const addDoctorAction = createAsyncThunk("addDoctor", async (data) => {
  return await makeApiRequest(`/vac/api/signup`, {
    token: getToken(),
    method: "POST",
    data,
    apiKey: process.env.REACT_APP_API_KEY,
  });
});

const initialState = {
  doctor: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const addDoctorSlice = createSlice({
  name: "addDoctor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDoctorAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(addDoctorAction.fulfilled, (state, { payload }) => {
        state.doctor = payload;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(addDoctorAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default addDoctorSlice.reducer;
