import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const updateCMSAction = createAsyncThunk(
  "updateCMS",
  async ({ id, data }) => {
    return await makeApiRequest(`/api/cms/update?cmsId=${id}`, {
      token: getToken(),
      data,
      method: "POST",
    });
  }
);

const initialState = {
  cms: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const updateCMSSlice = createSlice({
  name: "updateCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCMSAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(updateCMSAction.fulfilled, (state, { payload }) => {
        state.cms = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(updateCMSAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default updateCMSSlice.reducer;
