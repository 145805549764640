import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import AddCMS from "../../components/UI/CMS/AddCMS";
import { useDispatch, useSelector } from "react-redux";
import { deleteCMSAction } from "../../redux/slice/cms/deleteCMSSlice";
import { getCMSByIdAction } from "../../redux/slice/cms/getCMSByIdSlice";
import { getAllCMSAction } from "../../redux/slice/cms/getAllCMSSlice";
import { cmsStatusAction } from "../../redux/slice/cms/cmsStatusSlice";
import { truncateHTML, truncateString } from "../../utils/utils";

const CMS = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateCMS, setShowUpdateCMS] = useState(false);
  const [showViewCMS, setShowViewCMS] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteCmsId, setDeleteCmsId] = useState();

  const dispatch = useDispatch();
  const { getAllCMS, cmsStatus, deleteCMS, addCMS, updateCMS } = useSelector(
    (state) => ({
      getAllCMS: state.getAllCMS,
      cmsStatus: state.cmsStatus,
      deleteCMS: state.deleteCMS,
      addCMS: state.addCMS,
      updateCMS: state.updateCMS,
    })
  );

  useEffect(() => {
    dispatch(getAllCMSAction(pageNumber));
  }, [
    pageNumber,
    cmsStatus.success,
    deleteCMS.success,
    addCMS.success,
    updateCMS.success,
  ]);

  const startSerialNumber = pageNumber * 20 + 1;

  const handleStatusChange = (value, id) => {
    dispatch(cmsStatusAction({ data: { status: value }, id }));
  };
  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2></h2>
          </div>

          <div className="colBox">
            <div className="mid-head">
              <h2>CMS Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add CMS
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Language</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllCMS.cms?.dataItems?.map((cms, index) => {
                    const serialNumber = (startSerialNumber + index)
                      .toString()
                      .padStart(2, "0");
                    return (
                      <tr key={index}>
                        <td data-label="S.No">{serialNumber}</td>
                        <td data-label="Title">
                          {truncateString(cms.title, 50)}
                        </td>
                        {/* <td>{cms.description.slice(0, 20)}</td> */}
                        <td
                          data-label="Description"
                          className="align-middle"
                          dangerouslySetInnerHTML={{
                            __html: truncateHTML(cms.description, 50),
                          }}
                        ></td>
                        <td>
                          {cms.languageType === "0" ? "English" : "Hindi"}
                        </td>
                        <td data-label="Status" className="tdGape">
                          <Switch
                            switchValue={cms.status}
                            switchId={cms.id}
                            handleChange={handleStatusChange}
                          />
                        </td>
                        <td data-label="Action">
                          <button
                            className=" btn-small greenbg"
                            type="button"
                            onClick={() => {
                              setShowUpdateCMS(true);
                              dispatch(getCMSByIdAction(cms.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            type="button"
                            className=" btn-small yellowbg"
                            onClick={() => {
                              setShowViewCMS(true);
                              dispatch(getCMSByIdAction(cms.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            className=" btn-small redbg"
                            type="submit"
                            onClick={() => {
                              setShowConfirm(true);
                              setDeleteCmsId(cms.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                              alt="icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={getAllCMS.cms?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={getAllCMS.cms?.totalItems}
            noOfData={getAllCMS.cms?.dataItems?.length}
          />
        </div>
      </div>

      <AddCMS
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Add CMS"
        type="add"
      />
      <AddCMS
        isOpen={showUpdateCMS}
        onClose={() => setShowUpdateCMS(false)}
        title="Update CMS"
        setIsOpen={setShowUpdateCMS}
        type="update"
        disabled={false}
      />
      <AddCMS
        isOpen={showViewCMS}
        onClose={() => setShowViewCMS(false)}
        title="View CMS"
        setIsOpen={setShowViewCMS}
        disabled={true}
        type="view"
      />
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteCMSAction(deleteCmsId));
        }}
      />
    </>
  );
};

export default CMS;
