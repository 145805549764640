import { configureStore } from "@reduxjs/toolkit";
import signinSlice from "./slice/auth/signinSlice";
import signoutSlice from "./slice/auth/signoutSlice";
import getAllUsersSlice from "./slice/users/getAllUsersSlice";
import addUserSlice from "./slice/users/addUserSlice";
import forgotPasswordSlice from "./slice/auth/forgotPasswordSlice";
import getUserByIdSlice from "./slice/users/getUserByIdSlice";
import userStatusSlice from "./slice/users/userStatusSlice";
import deleteUserSlice from "./slice/users/deleteUserSlice";
import updateUserSlice from "./slice/users/updateUserSlice";
import changePasswordSlice from "./slice/auth/changePasswordSlice";
import addHospitalSlice from "./slice/hospital/addHospitalSlice";
import deleteHospitalSlice from "./slice/hospital/deleteHospitalSlice";
import getAllHospitalSlice from "./slice/hospital/getAllHospitalSlice";
import getHospitalByIdSlice from "./slice/hospital/getHospitalByIdSlice";
import hospitalStatusSlice from "./slice/hospital/hospitalStatusSlice";
import updateHospitalSlice from "./slice/hospital/updateHospitalSlice";
import searchHospitalSlice from "./slice/hospital/searchHospitalSlice";
import getVaccinesSlice from "./slice/common/getVaccinesSlice";
import addDoctorSlice from "./slice/doctor/addDoctorSlice";
import getDoctorsSlice from "./slice/doctor/getDoctorsSlice";
import getAllVaccinesSlice from "./slice/vaccine/getAllVaccinesSlice";
import addVaccineSlice from "./slice/vaccine/addVaccineSlice";
import updateVaccineSlice from "./slice/vaccine/updateVaccineSlice";
import deleteVaccineSlice from "./slice/vaccine/deleteVaccineSlice";
import VaccineStatusSlice from "./slice/vaccine/VaccineStatusSlice";
import getVaccineByIdSlice from "./slice/vaccine/getVaccineByIdSlice";
import getAllCategorySlice from "./slice/category/getAllCategorySlice";
import categoryStatusSlice from "./slice/category/categoryStatusSlice";
import getCategoryByIdSlice from "./slice/category/getCategoryByIdSlice";
import UpdateCategorySlice from "./slice/category/UpdateCategorySlice";
import getAllActiveCategorySlice from "./slice/category/getAllActiveCategorySlice";
import deletCategorySlice from "./slice/category/deletCategorySlice";
import addBlogSlice from "./slice/blogs/addBlogSlice";
import blogStatusSlice from "./slice/blogs/blogStatusSlice";
import deleteBlogSlice from "./slice/blogs/deleteBlogSlice";
import getAllBlogSlice from "./slice/blogs/getAllBlogSlice";
import getBlogByIdSlice from "./slice/blogs/getBlogByIdSlice";
import updateBlogSlice from "./slice/blogs/updateBlogSlice";
import addCMSSlice from "./slice/cms/addCMSSlice";
import cmsStatusSlice from "./slice/cms/cmsStatusSlice";
import deleteCMSSlice from "./slice/cms/deleteCMSSlice";
import getAllCMSSlice from "./slice/cms/getAllCMSSlice";
import getCMSByIdSlice from "./slice/cms/getCMSByIdSlice";
import updateCMSSlice from "./slice/cms/updateCMSSlice";
import getAllParentCategoryNameSlice from "./slice/category/getAllParentCategoryNameSlice";
import getHospitalClinicSlice from "./slice/common/getHospitalClinicSlice";
import getAllPatientListSlice from "./slice/patient/getAllPatientListSlice";
import getAllAppointmentSlice from "./slice/appointment/getAllAppointmentSlice";
import getTopTenAppointmentSlice from "./slice/dashboard/getTopTenAppointmentSlice";
import searchUserSlice from "./slice/users/searchUserSlice";
import searchDoctorSlice from "./slice/doctor/searchDoctorSlice";
import searchPatientSlice from "./slice/patient/searchPatientSlice";
import addPatientSlice from "./slice/patient/addPatientSlice";
import patientStatusSlice from "./slice/patient/patientStatusSlice";
import getPatientByIdSlice from "./slice/patient/getPatientByIdSlice";
import deletePatientSlice from "./slice/patient/deletePatientSlice";
import getVaccineByAgeSlice from "./slice/vaccine/getVaccineByAgeSlice";
import updatePatientSlice from "./slice/patient/updatePatientSlice";
import getListDoctorSlice from "./slice/doctor/getListDoctorSlice";
import searchAppointmentSlice from "./slice/appointment/searchAppointmentSlice";
import dashboardCountsSlice from "./slice/dashboard/dashboardCountsSlice";
import addCategorySlice from "./slice/category/addCategorySlice";

export const store = configureStore({
  reducer: {
    // Authentication
    loggedinUser: signinSlice,
    signout: signoutSlice,
    forgotPassword: forgotPasswordSlice,
    changePassword: changePasswordSlice,

    // Common
    allVaccines: getVaccinesSlice,
    getHospitalClinic: getHospitalClinicSlice,

    // Users
    users: getAllUsersSlice,
    addUser: addUserSlice,
    updateUser: updateUserSlice,
    userById: getUserByIdSlice,
    userStatus: userStatusSlice,
    deleteUser: deleteUserSlice,
    searchUser: searchUserSlice,

    // Hospital
    addHospital: addHospitalSlice,
    deleteHospital: deleteHospitalSlice,
    getAllHospital: getAllHospitalSlice,
    getHospitalById: getHospitalByIdSlice,
    updateHospital: updateHospitalSlice,
    hospitalStatus: hospitalStatusSlice,
    searchHospital: searchHospitalSlice,

    // Doctor
    addDoctor: addDoctorSlice,
    doctors: getDoctorsSlice,
    searchDoctor: searchDoctorSlice,
    getListDoctor: getListDoctorSlice,
    

    // Vaccines
    vaccines: getAllVaccinesSlice,
    getVaccineById: getVaccineByIdSlice,
    addVaccine: addVaccineSlice,
    updateVaccine: updateVaccineSlice,
    deleteVaccine: deleteVaccineSlice,
    vaccineStatus: VaccineStatusSlice,

    // Category
    addCategory: addCategorySlice,
    categoryStatus: categoryStatusSlice,
    getAllCategory: getAllCategorySlice,
    getCategoryById: getCategoryByIdSlice,
    updateCategory: UpdateCategorySlice,
    deleteCategory: deletCategorySlice,
    getAllActiveCategory: getAllActiveCategorySlice,
    getAllParentCategoryName: getAllParentCategoryNameSlice,

    // Blog
    addBlog: addBlogSlice,
    blogStatus: blogStatusSlice,
    deleteBlog: deleteBlogSlice,
    getAllBlog: getAllBlogSlice,
    getBlogById: getBlogByIdSlice,
    updateBlog: updateBlogSlice,

    // CMS
    addCMS: addCMSSlice,
    cmsStatus: cmsStatusSlice,
    deleteCMS: deleteCMSSlice,
    getAllCMS: getAllCMSSlice,
    getCMSById: getCMSByIdSlice,
    updateCMS: updateCMSSlice,

    // Appointment
    getAllAppointment: getAllAppointmentSlice,
    searchAppointment: searchAppointmentSlice,
    

    // dashboard
    getTopTenAppointment: getTopTenAppointmentSlice,
    dashboardCounts: dashboardCountsSlice,

    // Patient
    getAllPatientList: getAllPatientListSlice,
    searchPatient: searchPatientSlice,
    addPatient: addPatientSlice,
    patientStatus: patientStatusSlice,
    getPatientById: getPatientByIdSlice,
    deletePatient: deletePatientSlice,
    getVaccineByAge: getVaccineByAgeSlice,
    updatePatient: updatePatientSlice,
  },
});
