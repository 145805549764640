import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest } from "../../../utils/utils";

export const getListDoctorAction = createAsyncThunk(
    "getListDoctor",
    async (pageNumber) => {
        return await makeApiRequest(
            `/vac/api/user/allAppointedDoctors`,
            {
                // token: getToken(),
                apiKey: process.env.REACT_APP_API_KEY,
            }
        );
    }
);

const initialState = {
    doctor: [],
    loading: false,
    error: "",
    message: "",
    success: false,
};

const getListDoctorSlice = createSlice({
    name: "getListDoctor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListDoctorAction.pending, (state) => {
                state.loading = true;
                state.error = "";
                state.success = false;
            })
            .addCase(getListDoctorAction.fulfilled, (state, { payload }) => {
                state.doctor = payload.data;
                state.loading = false;
                state.success = true;
                state.error = "";
                state.message = payload.message;
            })
            .addCase(getListDoctorAction.rejected, (state, { error }) => {
                state.loading = false;
                state.error = error.message;
                state.success = false;
                state.message = error.message;
                console.log("Error: ", error.message);
            });
    },
});

export default getListDoctorSlice.reducer;
