import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Confirm from "./Confirm";
import { useDispatch, useSelector } from "react-redux";
import { signoutAction } from "../../redux/slice/auth/signoutSlice";
import EditProfile from "../UI/users/EditProfile";
import { getUserByIdAction } from "../../redux/slice/users/getUserByIdSlice";
import { getCurrentUserLT } from "../../utils/utils";

const DashHeader = ({ setToggleMenu, toggleMenu }) => {
  const [showControls, setShowControls] = useState(false);
  const [updateLoggedUser, setUpdateLoggedUser] = useState({
    user: {
      fname: "",
      lname: "Loading...",
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const user = getCurrentUserLT();
  const dispatch = useDispatch();
  const { signout } = useSelector((state) => state);
  const userById = useSelector((state) => state.userById);
  const modalRef = useRef();
  const navigate = useNavigate();
  const [routeFlag, setRouteFlag] = useState(false);
  const login = useSelector((state) => state.loggedinUser);

  // Showing Profile Options
  useEffect(() => {
    dispatch(getUserByIdAction(user?.id));
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowControls(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (userById.success && getCurrentUserLT().id === userById.user?.id) {
      setUpdateLoggedUser(userById);
    }
  }, [userById.success]);

  useEffect(() => {
    routeFlag && signout.success && navigate("/");
  }, [signout.success]);

  function handleLogout() {
    dispatch(signoutAction());
    setRouteFlag(true);
  }

  return (
    <>
      <EditProfile
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Edit Profile"
      />
      <Confirm isOpen={showConfirm} onClose={() => setShowConfirm(false)} />
      <header className="dashHeader">
        <div className="left">
          <Link to="/dashboard">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/icons-images/logo.png`}
            />
          </Link>
          <button
            className="menu"
            onClick={() => setToggleMenu((state) => !state)}
          >
            <i
              className={
                toggleMenu
                  ? "fa-solid fa-bars padd-icon"
                  : "fa-solid fa-angles-left padd-icon"
              }
            ></i>
          </button>
        </div>

        <div
          className="right"
          onClick={() => setShowControls(!showControls)}
          role="button"
        >
          <span className="username">
            {updateLoggedUser.loading
              ? "Loading..."
              : updateLoggedUser.user?.fname +
                " " +
                updateLoggedUser.user?.lname}
          </span>
          <div className="profilePicture">
            <img
              src={
                updateLoggedUser.user?.profileImage ||
                `${process.env.PUBLIC_URL}/images/photo.png`
              }
              alt=""
              style={{ borderRadius: "50%", width: 50, height: 50 }}
            />
          </div>

          <div className="menu">
            <img
              src={`${process.env.PUBLIC_URL}/icons-images/menuIcon.svg`}
              alt=""
            />
          </div>

          {showControls && (
            <ul className="controls" ref={modalRef} id="modal">
              <li
                className="result"
                onClick={() => navigate("/change-password")}
              >
                <i className="fa fa-cog" aria-hidden="true"></i> Change password
              </li>

              <li
                className="result"
                onClick={() => {
                  setIsOpen(true);
                  dispatch(getUserByIdAction(getCurrentUserLT()?.id));
                }}
              >
                <i className="fa-solid fa-user-pen"></i> Edit Profile
              </li>

              <li className="result" onClick={() => handleLogout()}>
                <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
              </li>
            </ul>
          )}
        </div>
      </header>
    </>
  );
};

export default DashHeader;
