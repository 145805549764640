import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const deleteCMSAction = createAsyncThunk("deleteCMS", async (id) => {
  return await makeApiRequest(`/api/cms/delete?cmsId=${id}`, {
    token: getToken(),
    method: "DELETE",
  });
});

const initialState = {
  cms: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const deleteCMSSlice = createSlice({
  name: "deleteCMS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCMSAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(deleteCMSAction.fulfilled, (state, { payload }) => {
        state.cms = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        notify(payload.message);
        state.message = payload.message;
      })
      .addCase(deleteCMSAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default deleteCMSSlice.reducer;
