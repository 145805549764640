import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import AddUser from "../../components/UI/users/AddUser";
import "../../styles/Patient.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../redux/slice/users/getAllUsersSlice";
import { getUserByIdAction } from "../../redux/slice/users/getUserByIdSlice";
import { userStatusAction } from "../../redux/slice/users/userStatusSlice";
import { deleteUserAction } from "../../redux/slice/users/deleteUserSlice";
import { searchUserAction } from "../../redux/slice/users/searchUserSlice";

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateDoctor, setShowUpdateDoctor] = useState(false);
  const [viewUser, setViewUser] = useState(false);

  const [usersList, setUsersList] = useState([]);

  const [deleteUserId, setDeleteUserId] = useState();

  const [pageNumber, setPageNumber] = useState(0);
  const [searchFilters, setSearchFilters] = useState({
    name: "",
    userType: "",
    status: "",
  });

  const handleInputChange = (fieldName, value) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };

  const dispatch = useDispatch();
  const { users, deleteUser, addUser, searchUser, updateUser } = useSelector(
    (state) => ({
      users: state.users,
      deleteUser: state.deleteUser,
      updateUser: state.updateUser,
      addUser: state.addUser,
      searchUser: state.searchUser,
    })
  );

  useEffect(() => {
    dispatch(getAllUsersAction(pageNumber));
  }, [pageNumber, deleteUser.success, addUser.success, updateUser.success]);

  const startSerialNumber = pageNumber * 20 + 1;

  const handleStatusChange = (value, id) => {
    dispatch(userStatusAction({ data: { status: value }, id }));
  };

  useEffect(() => {
    setUsersList(users?.users);
  }, [users.success]);

  const [message, setMessage] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    // Check if either the name or status fields are not empty
    if (
      searchFilters.name.length >= 3 ||
      searchFilters.status ||
      searchFilters.userType
    ) {
      dispatch(searchUserAction({ ...searchFilters }));
      setMessage("");
    } else {
      setMessage(
        "Please enter/select at least one search field or at least 3 characters"
      );
      setUsersList(users?.users);
    }
  };

  useEffect(() => {
    if (searchUser.success) {
      setUsersList(searchUser?.user);
    }
  }, [searchUser.success]);

  const handleReset = () => {
    // setSearch(false);
    setUsersList(users?.users);
    setSearchFilters({
      name: "",
      status: "",
      userType: "",
    });
    setMessage("");
  };

  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2> </h2>
          </div>

          {/* <form> */}
          <form className="row mar-20" onSubmit={handleSearch}>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name/Email/Phone Number"
                  value={searchFilters.name}
                  onChange={(e) => {
                    handleInputChange("name", e.target.value);
                    !e.target.value && dispatch(getAllUsersAction(pageNumber));
                  }}
                />
              </div>
            </div>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <select
                  className="input-control"
                  value={searchFilters.userType}
                  onChange={(e) =>
                    handleInputChange("userType", e.target.value)
                  }
                >
                  <option>Search by User Type</option>
                  <option value="admin">Admin</option>
                  <option value="support">Support</option>
                  <option value="receptionist">Receptionist</option>
                </select>
              </div>
            </div>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <select
                  className="input-control"
                  value={searchFilters.status}
                  onChange={(e) => handleInputChange("status", e.target.value)}
                >
                  <option>Search by User Status</option>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
            </div>

            <div className=" col-md-3">
              <div className="form-group ">
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>

                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {message && <div className="alert alert-danger">{message}</div>}
          </form>
          {/* </form> */}

          <div className="colBox">
            <div className="mid-head">
              <h2>Users Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add User
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">User Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList?.dataItems?.length === 0 ? (
                    <p className="NoDatafound">No Data found!</p>
                  ) : (
                    usersList?.dataItems?.map((user, index) => {
                      const serialNumber = (startSerialNumber + index)
                        .toString()
                        .padStart(2, "0");

                      return (
                        <tr key={index}>
                          <td data-label="S.No">{serialNumber}</td>
                          <td data-label="Name">
                            {user.fname.length > 20
                              ? user.fname.substring(0, 20) + "..."
                              : user.fname}{" "}
                            {user.lname.length > 20
                              ? user.lname.substring(0, 20) + "..."
                              : user.lname}
                          </td>
                          <td data-label="Email">{user.email}</td>
                          <td data-label="Mobile No.">{user.phoneNumber}</td>
                          <td data-label="User Type">
                            {user.roleType?.charAt(0)?.toUpperCase() +
                              user.roleType?.slice(1)}
                            &nbsp;
                          </td>
                          <td data-label="Status" className="tdGape">
                            <Switch
                              switchValue={user.status}
                              switchId={user.id}
                              handleChange={handleStatusChange}
                            />
                          </td>
                          <td data-label="Action">
                            <button
                              className=" btn-small greenbg"
                              type="button"
                              onClick={() => {
                                setShowUpdateDoctor(true);
                                dispatch(getUserByIdAction(user.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small yellowbg"
                              onClick={() => {
                                setViewUser(true);
                                dispatch(getUserByIdAction(user.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small redbg"
                              type="submit"
                              onClick={() => {
                                setShowConfirm(true);
                                setDeleteUserId(user.id);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                                alt="icon"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={usersList?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={usersList?.totalItems}
            noOfData={usersList?.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}
      <AddUser
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Add User"
        type="add"
      />
      <AddUser
        isOpen={showUpdateDoctor}
        onClose={() => setShowUpdateDoctor(false)}
        title="Update User"
        setIsOpen={setShowUpdateDoctor}
        disabled={false}
        type="update"
      />

      <AddUser
        isOpen={viewUser}
        onClose={() => setViewUser(false)}
        title="View User"
        setIsOpen={setViewUser}
        disabled={true}
        type="view"
      />
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteUserAction(deleteUserId));
        }}
      />
    </>
  );
};

export default Users;
