import React, { useEffect, useState } from "react";
import Success from "../../commonUI/Success";
import { useFormik } from "formik";
import { hospitalSchema } from "../../../utils/schema";
import { showError } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addHospitalAction } from "../../../redux/slice/hospital/addHospitalSlice";
import { updateHospitalAction } from "../../../redux/slice/hospital/updateHospitalSlice";
import Switch from "../../commonUI/Switch";
import { indianStates } from "../../../utils/data";
import Select from "react-select";

const AddHospitalForm = ({ onClose, disable, type }) => {
  const initialValues = {
    hospitalImg: "",
    description: "",
    email: "",
    name: "",
    address: "",
    city: "",
    state: "",
    country: "India",
    longitude: "",
    address: "",
    latitude: "",
    type: "",
    pincode: "",
    status: "0",
  };

  const [success, setSuccess] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [routeFlag, setRouteFlag] = useState(false);

  const dispatch = useDispatch();
  const addHospital = useSelector((state) => state.addHospital);
  const updateHospital = useSelector((state) => state.updateHospital);
  const getHospitalById = useSelector((state) => state.getHospitalById);

  const [hospitalValues, setHospitalValues] = useState(initialValues);

  useEffect(() => {
    if (type == "add") {
      setHospitalValues(initialValues);
    } else {
      setPreviewUrl(getHospitalById.hospital?.hospitalImg);
      setHospitalValues(getHospitalById.hospital);
    }
  }, [getHospitalById.success]);

  const validationSchema = () => {
    if (type === "add") {
      return hospitalSchema;
    } else if (type === "update") {
      return hospitalSchema;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if ((addHospital.success || updateHospital.success) && routeFlag) {
      setSuccess(true);
    }
  }, [addHospital.success, updateHospital.success]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: hospitalValues,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      // Loop through the values object and append each key-value pair to the FormData
      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
      }
      if (type === "add") {
        dispatch(addHospitalAction(formData));
      } else {
        dispatch(updateHospitalAction({ id: values.id, data: formData }));
      }

      setRouteFlag(true);
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                  disabled={disable}
                />
                {showError(errors.name, touched.name)}
              </div>

              <div className=" col-md-6 ">
                <label>Description</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                  value={values?.description}
                  disabled={disable}
                />
              </div>
            </div>
          </div>

          {/* Email Gender */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Type<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.type}
                  disabled={disable}
                >
                  <option value="">Select</option>
                  <option value="1">Clinic</option>
                  <option value="2">Hospital</option>
                </select>
                {showError(errors.type, touched.type)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.address}
                  disabled={disable}
                />{" "}
                {showError(errors.address, touched.address)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6">
                <label>
                  Email<span>*</span>
                </label>
                <input
                  type="email"
                  className="input-control"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  disabled={type === "update" || disable}
                />{" "}
                {showError(errors.email, touched.email)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.city}
                  disabled={disable}
                />{" "}
                {showError(errors.city, touched.city)}
              </div>
            </div>
          </div>

          {/* phoneNumber roleType */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  State<span>*</span>
                </label>
                <Select
                  name="state"
                  isDisabled={type === "view"}
                  onChange={(selectedOptions) => {
                    console.log(selectedOptions);
                    setFieldValue("state", selectedOptions.value);
                  }}
                  options={indianStates.map((s) => ({ label: s, value: s }))}
                  value={{
                    value: values?.state || null,
                    label: values?.state || "Select...",
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "44px",
                    }),
                  }}
                  placeholder={"Select a state"}
                />
                {showError(errors.state, touched.state)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Country<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Country"
                  name="country"
                  value="India"
                  disabled={disable}
                />
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Pin Code<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.pincode}
                  disabled={disable}
                />{" "}
                {showError(errors.pincode, touched.pincode)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Latitude<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Latitude"
                  name="latitude"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.latitude}
                  disabled={disable}
                />{" "}
                {showError(errors.latitude, touched.latitude)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Longitude<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Longitude"
                  name="longitude"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.longitude}
                  disabled={disable}
                />{" "}
                {showError(errors.longitude, touched.longitude)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Choose File<span>*</span>
                </label>
                <input
                  type="file"
                  name="hospitalImg"
                  id="hospitalImg"
                  tabIndex="1"
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    // Verify if the file is a valid image
                    if (file) {
                      setFieldValue("hospitalImg", file);
                      setPreviewUrl(URL.createObjectURL(file));
                    } else {
                      // Handle the case when the selected file is not a valid image
                      setPreviewUrl(null);
                      setFieldValue("hospitalImg", file);
                    }
                  }}
                  onBlur={handleBlur}
                  disabled={disable}
                />{" "}
                {previewUrl && (
                  <div className="img">
                    <div className="squareImgPreview">
                      <img src={previewUrl} alt="profile" width="100" />
                    </div>
                  </div>
                )}
                {showError(errors.hospitalImg, touched.hospitalImg)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Status<span>*</span>
                </label>
                <Switch
                  disabled={disable}
                  switchValue={values?.status}
                  name="status"
                  setFieldValue={setFieldValue}
                />{" "}
                {showError(errors.status, touched.status)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disable && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        setIsOpen={setSuccess}
        onClose={() => setSuccess(false)}
        message={` ${type === "add" ? "Added" : "Updated"} successfully`}
        descMessage={`${values?.type == 2 ? "Hospital" : "Clinic"} has been ${
          type === "add" ? "added" : "updated"
        }  successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddHospitalForm;
