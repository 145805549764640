import * as Yup from "yup";

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SUPPORTED_FORMATS = ["jpg", "jpeg", "png"];
const csvFormat = ["csv"];

const MAX_FILE_SIZE = 2 * 1024 * 1024; //100KB

// Valid formats
const validFileExtensions = {
  image: ["jpg", "png", "jpeg"],
  csvFormat: ["csv"],
  video: ["mp4"],
};

// img validation
const maxSize = (value) => {
  if (!value) {
    return true;
  } else if (typeof value === "string" && value?.includes("http")) {
    return true;
  } else {
    return value && value.size <= MAX_FILE_SIZE;
  }
};

// file format validation
const imageFormat = (value) => {
  if (!value) {
    return true;
  } else if (typeof value === "string" && value?.includes("http")) {
    return true;
  } else {
    return isValidFileType(value && value.name?.toLowerCase(), "image");
  }
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

const email = Yup.string()
  .trim("The Email can't include space(s) at start and end")
  .strict(true)
  .required("Please enter Email")
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,}$/,
    "Email address is not valid"
  );

const phoneNumber = Yup.string()
  .trim()
  .trim("Phone number cannot include start and end spaces")
  .strict(true)
  .matches(phoneRegExp, "Phone number is not valid")
  .min(10, "Phone number must be atleast 10 characters long")
  .max(10, "Phone number can't be greater than 10 digits.")
  .required("Please enter your number.");

export const signInSchema = Yup.object({
  email,
  password: Yup.string()
    .trim("The last name cannot include start and end spaces")
    .strict(true)
    .required("Please enter password")
    .min(3, "Invalid Password"),
});

export const forgotFormSchema = Yup.object({
  email,
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Current password is required"),

  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("New password is required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Confirm password must match")
    .required("Confirm password is required"),
});

// Add Doctor Schema
export const addDoctorSchema = Yup.object({
  fname: Yup.string().required("Please enter first name.").trim(),
  phoneNumber,
  email,
  // address: Yup.string().trim().max(100, "Too long"),
  highestQualification: Yup.string().required(
    "Please enter highest qualifications"
  ),
  longitude: Yup.number()
    .typeError("Longitude must be a number.")
    .required("Please enter longitude."),
  latitude: Yup.number()
    .typeError("Latitude must be a number.")
    .required("Please enter latitude.")
    .test(
      "latitude-lower-than-longitude",
      "Latitude must be less than or equal to Longitude",
      function (value) {
        const latitude = value;
        const longitude = this.parent.longitude;
        return latitude <= longitude;
      }
    ),
  specialist: Yup.string().required("Please enter specialist"),
  noOfExperience: Yup.string().required("Please select experience"),
  dateOfBirth: Yup.date().max(
    new Date(),
    "Date of Birth cannot be in the future"
  ),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
  modeOfPractice: Yup.array().required("Please select mode of practice"),
  pincode: Yup.number("Invalid pincode").typeError(
    "Please enter a valid pincode"
  ),
});

// Update Doctor Schema
export const updateDoctorSchema = Yup.object({
  fname: Yup.string().required("Please enter first name.").trim(),
  phoneNumber,
  email,
  dateOfBirth: Yup.date().max(
    new Date(),
    "Date of Birth cannot be in the future"
  ),
  highestQualification: Yup.string().required(
    "Please enter highest qualifications"
  ),

  longitude: Yup.number()
    .typeError("Longitude must be a number.")
    .required("Please enter longitude."),
  latitude: Yup.number()
    .typeError("Latitude must be a number.")
    .required("Please enter latitude.")
    .test(
      "latitude-lower-than-longitude",
      "Latitude must be less than or equal to Longitude",
      function (value) {
        const latitude = value;
        const longitude = this.parent.longitude;
        return latitude <= longitude;
      }
    ),
  specialist: Yup.string().required("Please enter specialist"),
  noOfExperience: Yup.string().required("Please select experience"),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
  modeOfPractice: Yup.array().required("Please select mode of practice"),
  pincode: Yup.number("Invalid pincode").typeError(
    "Please enter a valid pincode"
  ),
});

// AddUser Schema
export const addUserSchema = Yup.object({
  fname: Yup.string().required("Please enter first name.").trim(),
  role: Yup.string().required("Please select role"),
  gender: Yup.string().required("Please select gender"),
  phoneNumber,
  email,
  dateOfBirth: Yup.date().max(
    new Date(),
    "Date of Birth cannot be in the future"
  ),
  address: Yup.string().trim().max(100, "Too long"),
  password: Yup.string().required("Please enter password"),
  confirmPasword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please enter confirm password"),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
});

// Add User Schema
export const updateUserSchema = Yup.object({
  fname: Yup.string().required("Please enter first name.").trim(),
  role: Yup.string().required("Please select role"),
  gender: Yup.string().required("Please select gender"),
  phoneNumber,
  email,
  address: Yup.string().trim().max(100, "Too long"),
  dateOfBirth: Yup.date().max(
    new Date(),
    "Date of Birth cannot be in the future"
  ),
}).shape({
  profileImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
});

// Add Department Schema
export const addDepartmentSchema = Yup.object().shape({
  departmentName: Yup.string().required("Department Name is required").trim(),
  hod: Yup.string().required("Head of Department is required").trim(),
  availablePatients: Yup.number()
    .required("Number of Available Patients is required")
    .integer("Number of Available Patients must be an integer")
    .positive("Number of Available Patients must be a positive number"),
  availableDoctors: Yup.number()
    .required("Number of Available Doctors is required")
    .integer("Number of Available Doctors must be an integer")
    .positive("Number of Available Doctors must be a positive number"),
  availableNurse: Yup.number()
    .required("Number of Available Nurses is required")
    .integer("Number of Available Nurses must be an integer")
    .positive("Number of Available Nurses must be a positive number"),
  totalBeds: Yup.number()
    .required("Total Beds is required")
    .integer("Total Beds must be an integer")
    .positive("Total Beds must be a positive nuamber"),
});

// Add patient Schema
export const addPatientSchema = Yup.object().shape({
  fname: Yup.string().required("First name is required"),
  email,
  phoneNumber,
  dateOfBirth: Yup.date()
    .max(new Date(), "Date of Birth cannot be in the future")
    .required("Date of birth is required"),
  relationUserID: Yup.string().required("Please select parent"),
  gender: Yup.string().required("Gender is required"),
  bloodGroup: Yup.string().required("Blood Group is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pincode must be a 6-digit numeric value")
    .required("Pincode is required"),
  country: Yup.string().required("Country is required"),
  childHeight: Yup.string()
    .min(1, "Please select height")
    .required("Child Height is required"),
  status: Yup.string().required("Status is required"),
  childWeight: Yup.string()
    .min(1, "Please select weight")
    .required("Child Weight is required"),
});

export const updatePatientSchema = Yup.object().shape({
  fname: Yup.string().required("First name is required"),
  email,
  phoneNumber,
  dateOfBirth: Yup.date()
    .max(new Date(), "Date of Birth cannot be in the future")
    .required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  bloodGroup: Yup.string().required("Blood Group is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pincode must be a 6-digit numeric value")
    .required("Pincode is required"),
  country: Yup.string().required("Country is required"),
  childHeight: Yup.string()
    .min(1, "Please select height")
    .required("Child Height is required"),
  status: Yup.string().required("Status is required"),
  childWeight: Yup.string()
    .min(1, "Please select weight")
    .required("Child Weight is required"),
});

// Add patient Schema
export const editProfileSchema = Yup.object().shape({
  fname: Yup.string().required("Please enter first name").trim(),
  phoneNumber,
  email,
  dateOfBirth: Yup.date().max(
    new Date(),
    "Date of Birth cannot be in the future"
  ),
});
const isNumeric = (value) => !isNaN(value) && typeof value === "number";
export const hospitalSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name").trim(),
  email,
  address: Yup.string().required("Please enter address").trim(),
  city: Yup.string().required("Please enter city").trim(),
  state: Yup.string().required("Please enter state").trim(),
  country: Yup.string().required("Please enter country").trim(),
  longitude: Yup.string()
    .test("is-numeric", "Longitude must be a numeric value", (value) =>
      isNumeric(parseFloat(value))
    )
    .required("Please enter longitude")
    .trim(),
  longitude: Yup.number()
    .typeError("Longitude must be a number.")
    .required("Please enter longitude."),
  latitude: Yup.number()
    .typeError("Latitude must be a number.")
    .required("Please enter latitude.")
    .test(
      "latitude-lower-than-longitude",
      "Latitude must be less than or equal to Longitude",
      function (value) {
        const latitude = value;
        const longitude = this.parent.longitude;
        return latitude <= longitude;
      }
    ),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Pincode must be a 6-digit numeric value")
    .required("Pincode is required"),
  type: Yup.string().required("Please select type").trim(),
  hospitalImg: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize)
    .required("Please select image"),
});

export const vaccineSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name").trim(),
  days: Yup.string().required("Please select no of day"),
  description: Yup.string().required("Please enter description"),
  vaccineType: Yup.string().required("Please select vaccine type"),
  parentVaccineId: Yup.mixed().when("vaccineType", {
    is: "2",
    then: () => Yup.mixed().required("Please select parent vaccine name"),
    otherwise: () => Yup.string().trim(),
  }),
  status: Yup.string().required("Please select status"),
});

const trimString = (str) => (str ? str.trim() : "");

export const blogAddSchema = Yup.object().shape({
  name: Yup.string()
    .transform(trimString) // Use the transform function to trim the string
    .required("Please enter name")
    .matches(/\S/, "Name cannot be just spaces"),
  status: Yup.string()
    .transform(trimString) // Use the transform function to trim the string
    .required("Please select status"),
  metaTitle: Yup.string().transform(trimString),
  metaDescription: Yup.string().transform(trimString),
  metaKeyword: Yup.string().transform(trimString),
  imageBlog: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize)
    .required("Please select an Image"),
});

export const blogUpdateSchema = Yup.object().shape({
  name: Yup.string()
    .transform(trimString) // Use the transform function to trim the string
    .required("Please enter name")
    .matches(/\S/, "Name cannot be just spaces"),
  status: Yup.string()
    .transform(trimString) // Use the transform function to trim the string
    .required("Please select status"),
  metaTitle: Yup.string().transform(trimString),
  metaDescription: Yup.string().transform(trimString),
  metaKeyword: Yup.string().transform(trimString),
  imageBlog: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize)
    .required("Please select an Image"),
  description: Yup.string().test(
    "is-non-empty",
    "Description is required",
    (value) => {
      // Check if the description is not empty or null
      return value !== null && value.trim() !== "";
    }
  ),
});
export const categoryAddSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  status: Yup.string().required("Please select status"),
});
export const cmsAddSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  status: Yup.string().required("Please select status"),
});
export const cmsUpdateSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  status: Yup.string().required("Please select status"),
});

export const categoryUpdateSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  status: Yup.string().required("Please select status"),
});
export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string().required("Please enter category Name"),
  categoryType: Yup.string().required("Please select category Type"),
  status: Yup.string().required("Please select status"),
  categoryParentId: Yup.mixed().when("categoryType", {
    is: "2",
    then: () => Yup.mixed().required("Please select parent category name"),
    otherwise: () => Yup.string().trim(),
  }),
  categoryImage: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
});

export const updateCategorySchema = Yup.object().shape({
  categoryName: Yup.string().required("Please enter category Name"),
  categoryType: Yup.string().required("Please select category Type"),
  status: Yup.string().required("Please select status"),
  categoryParentId: Yup.mixed().when("categoryType", {
    is: "2",
    then: () => Yup.mixed().required("Please select parent category name"),
    otherwise: () => Yup.string().trim(),
  }),
  categoryImage: Yup.mixed()
    .test("is-valid-type", "Not a valid image type", imageFormat)
    .test("is-valid-size", "Max allowed size is 2MB", maxSize),
});

export const addParentSchema = Yup.object().shape({
  relation: Yup.string().required("Relation is required"),
  relationName: Yup.string()
    .min(2, "Name length must be 2 characters")
    .required("Full Name is required"),
  email,
  phoneNumber,
  city: Yup.string()
    .min(2, "City length must be 2 characters")
    .required("City is required"),
  state: Yup.string()
    .min(2, "State length must be atleast 2 characters")
    .required("State is required"),
  pincode: Yup.string()
    .matches(/^[0-9]{6}$/, "Invalid pincode")
    .required("Pincode is required"),
  address: Yup.string()
    .min(2, "Address length must be atleast 2 characters")
    .required("Address is required"),
  status: Yup.string().required("Status is required"),
  agreeTermAndPriv: Yup.string().oneOf(
    ["1"],
    "You must agree to the Terms and Privacy Policy"
  ),
});
