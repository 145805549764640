import React from "react";
import { NavLink } from "react-router-dom";
import NestedMenu from "./NestedMenu";

const LeftBar = ({ toggleMenu }) => {
  return (
    <div className={toggleMenu ? "leftBar partialLeftBar" : "leftBar"}>
      <NavLink to="/dashboard">
        <img
          src={`${process.env.PUBLIC_URL}/icons-images/dashboard.svg`}
          alt=""
        />
        <span>Dashboard</span>
      </NavLink>

      <NavLink to="/appointments">
        <img
          src={`${process.env.PUBLIC_URL}/icons-images/Appointment.svg`}
          alt=""
        />
        <span>Appointments</span>
      </NavLink>

      <NavLink to="/users">
        <img src={`${process.env.PUBLIC_URL}/icons-images/users.svg`} alt="" />
        <span>Users</span>
      </NavLink>
      <NavLink to="/doctor">
        <img src={`${process.env.PUBLIC_URL}/icons-images/doctor.svg`} alt="" />
        <span>Doctor</span>
      </NavLink>

      <NavLink to="/patient">
        <img
          src={`${process.env.PUBLIC_URL}/icons-images/patient-icon.svg`}
          alt=""
        />
        <span>Patient</span>
      </NavLink>

      <NavLink to="/parent">
        <img
          src={`${process.env.PUBLIC_URL}/icons-images/parent-icon.png`}
          alt=""
        />
        <span>Parent</span>
      </NavLink>

      {/* <NavLink to="/landing-page">
        <img src={`${process.env.PUBLIC_URL}/icons-images/users.svg`} alt="" />
        <span>Landing Page</span>
      </NavLink> */}
      <NestedMenu {...blogMenu} />
      <NestedMenu {...settingMenu} />
    </div>
  );
};

export default LeftBar;

const blogMenu = {
  menuName: "Blogs",
  menuIcon: "/icons-images/blog.png",
  menuURL: "/blog",
  submenu: [
    {
      name: "View",
      icon: "/icons-images/view.png",
      url: "/blog",
    },
    {
      name: "Category",
      icon: "/icons-images/category.png",
      url: "/category",
    },
  ],
};

const settingMenu = {
  menuName: "Setting",
  menuIcon: "/icons-images/setting.png",
  menuURL: "/setting",
  submenu: [
    {
      name: "Hospital",
      icon: "/icons-images/hospital.png",
      url: "/setting",
    },
    {
      name: "Vaccine",
      icon: "/icons-images/vaccine.png",
      url: "/vaccine",
    },

    {
      name: "CMS",
      icon: "/icons-images/CMS.png",
      url: "/cms",
    },
  ],
};
