import React, { useState } from "react";
import Success from "../../commonUI/Success";
import Switch from "../../commonUI/Switch";
import { showError } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllParentCategoryNameAction } from "../../../redux/slice/category/getAllParentCategoryNameSlice";
import { useFormik } from "formik";
import { addCategoryAction } from "../../../redux/slice/category/addCategorySlice";
import { updateCategoryAction } from "../../../redux/slice/category/UpdateCategorySlice";
import { addCategorySchema, updateCategorySchema } from "../../../utils/schema";

const AddCategoryForm = ({ onClose, disable, type }) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);

  const initialValues = {
    categoryImage: "",
    categoryName: "",
    categoryType: "",
    languageType: "0",
    categoryParentId: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    status: "0",
  };

  const dispatch = useDispatch();
  const getAllParentCategoryName = useSelector(
    (state) => state.getAllParentCategoryName
  );
  const addCategory = useSelector((state) => state.addCategory);
  const updateCategory = useSelector((state) => state.updateCategory);
  const getCategoryById = useSelector((state) => state.getCategoryById);
  const [categoryValues, setCategoryValues] = useState(initialValues);

  useEffect(() => {
    if (getCategoryById.success) {
      if (type === "add") {
        setPreviewUrl("");
        setCategoryValues(initialValues);
      } else {
        setPreviewUrl(getCategoryById.category?.categoryImg);
        setCategoryValues(getCategoryById.category);
      }
    }
  }, [getCategoryById.success, type]);

  useEffect(() => {
    if (addCategory.success && routeFlag) {
      setSuccess(true);
    } else if (updateCategory.success && routeFlag) {
      setSuccess(true);
    }
  }, [addCategory.success, updateCategory.success]);

  const validationSchema = () => {
    if (type === "add") {
      return addCategorySchema;
    } else if (type === "update") {
      return updateCategorySchema;
    } else {
      return null;
    }
  };

  let validateForm = (values) => {
    let errors = {};

    if (values.categoryType === "2") {
      errors["categoryParentId"] = "Please select categoryParentId";
    }

    return errors;
  };

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: categoryValues,
    // validate:validateForm,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values, "sajh");
      const formData = new FormData();
      // Loop through the values object and append each key-value pair to the FormData
      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
      }

      if (type === "add") {
        dispatch(addCategoryAction(formData));
      } else if (type === "update") {
        dispatch(updateCategoryAction({ id: values.id, data: formData }));
      }
      setRouteFlag(true);
    },
  });

  useEffect(() => {
    dispatch(getAllParentCategoryNameAction(values.languageType));
  }, [values.languageType]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          {/* title type */}
          <div className="Add-form-group">
            <div className="row">
              <div className="col-md-6 mb-2">
                <label>
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Title"
                  name="categoryName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.categoryName}
                  disabled={disable}
                />
                {showError(errors.categoryName, touched.categoryName)}
              </div>

              <div className="col-md-6 mb-2">
                <label>Language</label>
                <select
                  className="input-control"
                  name="languageType"
                  value={values?.languageType}
                  disabled={disable}
                  onChange={handleChange}
                >
                  <option value="0">English</option>
                  <option value="1">Hindi</option>
                </select>
              </div>

              <div className="col-md-6 mb-2">
                <label>
                  Type<span>*</span>
                </label>
                <select
                  name="categoryType"
                  id="categoryType"
                  className="input-control"
                  tabIndex="7"
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "1") {
                      setFieldValue("categoryParentId", "");
                    }
                  }}
                  onBlur={handleBlur}
                  value={values?.categoryType}
                  disabled={disable}
                >
                  <option value="">--Select Type--</option>
                  <option value="1">Parent</option>
                  <option value="2">Child</option>
                </select>
                {showError(errors.categoryType, touched.categoryType)}
              </div>

              {values?.categoryType == "2" && (
                <div className="col-md-6 mb-2">
                  <label>
                    Parent Category Name<span>*</span>
                  </label>
                  <select
                    name="categoryParentId"
                    id="categoryParentId"
                    className="input-control"
                    tabIndex="7"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.categoryParentId}
                    disabled={disable}
                  >
                    <option value="">Select Parent</option>
                    {getAllParentCategoryName?.category.map(
                      (parentCategory) => (
                        <option
                          key={parentCategory.id}
                          value={parentCategory.id}
                        >
                          {parentCategory.parentName}
                        </option>
                      )
                    )}
                  </select>{" "}
                  {showError(errors.categoryParentId, touched.categoryParentId)}
                </div>
              )}

              <div className="col-md-6 mb-2">
                <label>Description</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="description"
                  placeholder="Description"
                  value={values?.description}
                  disabled={disable}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="col-md-6 mb-2">
                <label>Choose File</label>
                <input
                  type="file"
                  name="categoryImage"
                  id="categoryImage"
                  tabIndex="1"
                  disabled={disable}
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    // Verify if the file is a valid image
                    if (file) {
                      setFieldValue("categoryImage", file);
                      setPreviewUrl(URL.createObjectURL(file));
                    } else {
                      // Handle the case when the selected file is not a valid image
                      setPreviewUrl(null);
                      setFieldValue("categoryImage", file);
                    }
                  }}
                  onBlur={handleBlur}
                />
                {previewUrl && (
                  <div className="preview-image">
                    <img
                      src={previewUrl}
                      alt={values.categoryName}
                      width="100"
                    />
                  </div>
                )}
                {showError(errors.categoryImage, touched.categoryImage)}
              </div>

              {/* phoneNumber roleType */}
              <div className="col-md-6 mb-2">
                <label>Meta Title</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaTitle"
                  placeholder="Meta title"
                  value={values?.metaTitle}
                  disabled={disable}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-md-6 mb-2">
                <label>Meta Keyword</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaKeyword"
                  placeholder="Meta keywords"
                  value={values?.metaKeyword}
                  disabled={disable}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="col-md-6 mb-2">
                <label>Meta Description</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaDescription"
                  placeholder="Meta description"
                  value={values?.metaDescription}
                  disabled={disable}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-md-6 mb-2">
                <label>
                  Status<span>*</span>
                </label>
                <Switch
                  disabled={disable}
                  switchValue={values?.status}
                  setFieldValue={setFieldValue}
                />
                {showError(errors.status, touched.status)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disable && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={` ${type === "add" ? "Added" : "Updated"} successfully`}
        descMessage={`Category has been ${
          type === "add" ? "added" : "updated"
        }  successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddCategoryForm;
