import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import AddCategory from "../../components/UI/category/AddCategory";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategoryAction } from "../../redux/slice/category/getAllCategorySlice";
import { deleteCategoryAction } from "../../redux/slice/category/deletCategorySlice";
import { categoryStatusAction } from "../../redux/slice/category/categoryStatusSlice";
import { getCategoryByIdAction } from "../../redux/slice/category/getCategoryByIdSlice";

const Category = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateCategory, setShowUpdateCategory] = useState(false);
  const [showViewCategory, setShowViewCategory] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteCategoryId, setDeleteCategoryId] = useState();

  const dispatch = useDispatch();
  const {
    getAllCategory,
    categoryStatus,
    addCategory,
    updateCategory,
    deleteCategory,
  } = useSelector((state) => ({
    getAllCategory: state.getAllCategory,
    categoryStatus: state.categoryStatus,
    addCategory: state.addCategory,
    updateCategory: state.updateCategory,
    deleteCategory: state.deleteCategory,
  }));

  useEffect(() => {
    dispatch(getAllCategoryAction(pageNumber));
  }, [
    pageNumber,
    categoryStatus.success,
    updateCategory.success,
    deleteCategory.success,
    addCategory.success,
  ]);

  const handleStatusChange = (value, id) => {
    dispatch(categoryStatusAction({ data: { status: value }, id }));
  };

  const startSerialNumber = pageNumber * 20 + 1;
  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2></h2>
          </div>

          <div className="colBox">
            <div className="mid-head">
              <h2>Category Listing</h2>
            </div>
            <div>
              <button
                to="/category/add-category"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Category
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Language</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllCategory.category?.dataItems?.map(
                    (category, index) => {
                      const serialNumber = (startSerialNumber + index)
                        .toString()
                        .padStart(2, "0");
                      return (
                        <tr key={index}>
                          <td data-label="S.No">{serialNumber}</td>
                          <td data-label="Category Name">
                            {category.categoryName?.length > 30
                              ? category.categoryName.slice(0, 30) + "..."
                              : category.categoryName}
                          </td>
                          <td data-label="Type">
                            {" "}
                            {category.languageType === "0"
                              ? "English"
                              : "Hindi"}
                          </td>
                          <td data-label="Type">
                            {category.categoryType == "1" ? "Parent" : "Child"}
                          </td>
                          <td data-label="Status" className="tdGape">
                            <Switch
                              switchValue={category.status}
                              switchId={category.id}
                              handleChange={handleStatusChange}
                            />
                          </td>
                          <td data-label="Action">
                            <button
                              className=" btn-small greenbg"
                              type="button"
                              onClick={() => {
                                setShowUpdateCategory(true);
                                dispatch(getCategoryByIdAction(category?.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              type="button"
                              className=" btn-small yellowbg"
                              onClick={() => {
                                setShowViewCategory(true);
                                dispatch(getCategoryByIdAction(category?.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small redbg"
                              type="submit"
                              onClick={() => {
                                setShowConfirm(true);
                                setDeleteCategoryId(category?.id);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                                alt="icon"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={getAllCategory.category?.totalPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={getAllCategory.category?.totalItems}
            noOfData={getAllCategory.category?.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}
      <AddCategory
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Add Category"
        type="add"
      />
      <AddCategory
        isOpen={showUpdateCategory}
        onClose={() => setShowUpdateCategory(false)}
        title="Update Category"
        setIsOpen={setShowUpdateCategory}
        type="update"
        disabled={false}
      />
      <AddCategory
        isOpen={showViewCategory}
        onClose={() => setShowViewCategory(false)}
        title="View Category"
        setIsOpen={setShowViewCategory}
        type="view"
        disabled={true}
      />
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteCategoryAction(deleteCategoryId));
        }}
      />
    </>
  );
};

export default Category;
