import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest } from "../../../utils/utils";

export const getVaccineByAgeAction = createAsyncThunk(
  "getVaccineByAge",
  async ({age}) => {
    return await makeApiRequest(
      `/vac/api/get-vaccine-byage?days=${age}`,
      {
        token: getToken(),
    apiKey: process.env.REACT_APP_API_KEY,
      }
    );
  }
);

const initialState = {
  vaccines: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getVaccineByAgeSlice = createSlice({
  name: "getVaccineByAge",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVaccineByAgeAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getVaccineByAgeAction.fulfilled, (state, { payload }) => {
        state.vaccines = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getVaccineByAgeAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        console.log("Error: ", error.message);
      });
  },
});

export default getVaccineByAgeSlice.reducer;
