import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/Dashboard.css";
import "./styles/bootstrap.css";
import "./styles/Modal.css";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SignIn from "./pages/auth/SignIn";
import AuthLayout from "./components/Layouts/AuthLayout";
import useWindowSize from "./utils/Hooks/useWindowSize";
import { useEffect, useState } from "react";
import HeaderLayout from "./components/Layouts/HeaderLayout";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import Users from "./pages/Users/Users";
import ChangePassword from "./pages/Users/ChangePassword";
import Dashboard from "./pages/dashboard/Dashboard";
import LandingPage from "./pages/landingPage/LandingPage";
import { ToastContainer } from "react-toastify";
import PrivateRoutes, { PublicRoutes } from "./utils/Routes";
import Blogs from "./pages/blog/Blogs";
import Category from "./pages/category/Category";
import Hospital from "./pages/setting/Hospital";
import Vaccine from "./pages/setting/Vaccine";
import Doctor from "./pages/doctor/Doctor";
import Patient from "./pages/Patient/Patient";
import CMS from "./pages/CMS/CMS";
import Appointments from "./components/commonUI/Appointments";
import Parent from "./pages/parent/Parent";

function App() {
  const windowSize = useWindowSize();
  const [toggleMenu, setToggleMenu] = useState(true);

  useEffect(() => {
    setToggleMenu(windowSize < 1180 && windowSize > 768);
  }, [windowSize]);

  const dashHeaderProps = {
    setToggleMenu,
    toggleMenu,
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route element={<HeaderLayout {...dashHeaderProps} />}>
            <Route path="/landing-page" element={<LandingPage />} />
            <Route element={<DashboardLayout {...dashHeaderProps} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/users" element={<Users />} />
              <Route path="/parent" element={<Parent />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/category" element={<Category />} />
              <Route path="/setting" element={<Hospital />} />
              <Route path="/vaccine" element={<Vaccine />} />
              <Route path="/doctor" element={<Doctor />} />
              <Route path="/patient" element={<Patient />} />
              <Route path="/cms" element={<CMS />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
