import React, { useState } from "react";
import "../../styles/Appointments.css";
import "../../styles/UI.css";
import MyCalendar from "../../components/UI/MyCalendar";
import MakeAppointment from "../../components/UI/appointment/MakeAppointment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllAppointmentAction } from "../../redux/slice/appointment/getAllAppointmentSlice";
import Pagination from "./Pagination";
import { getListDoctorAction } from "../../redux/slice/doctor/getListDoctorSlice";
import { searchAppointmentAction } from "../../redux/slice/appointment/searchAppointmentSlice";
import Loader from "./Loader";

const Appointments = () => {
  const [openMakeAppointment, setOpenMakeAppointment] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [appointment, setAppointmanet] = useState([]);

  const MakeAppointmentProps = {
    isOpen: openMakeAppointment,
    setIsOpen: setOpenMakeAppointment,
  };

  const dispatch = useDispatch();
  const { getAllAppointment } = useSelector((state) => state);
  const { getListDoctor } = useSelector((state) => state);
  const { searchAppointment } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllAppointmentAction(pageNumber));
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getListDoctorAction());
  }, []);

  const appointmentStatus = (appointment) => {
    if (appointment == "1") {
      return "Pending Payment";
    } else if (appointment == "2") {
      return "Payment Failed";
    } else if (appointment == "3") {
      return "Appointment Booked";
    } else if (appointment == "4") {
      return "Close";
    } else if (appointment == "5") {
      return "Cancel";
    } else {
      return "Approved";
    }
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [doctorId, setDoctorId] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      searchAppointmentAction({
        doctorId,
        appointmentDate: formatDate(date),
      })
    );
  };

  function formatDate(selectedDate) {
    if (!selectedDate) return;
    const originalDate = new Date(selectedDate);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSearch = () => {
    dispatch(
      searchAppointmentAction({
        doctorId,
        appointmentDate: selectedDate ? formatDate(selectedDate) : "",
      })
    );
  };

  useEffect(() => {
    setAppointmanet(getAllAppointment?.appointment);
  }, [getAllAppointment.success]);

  // useEffect(() => {
  //   setAppointmanet(searchAppointment?.appointment);
  // }, [searchAppointment.success]);

  useEffect(() => {
    searchAppointment.success &&
      setAppointmanet(searchAppointment?.appointment);
  }, [searchAppointment.success]);

  const handleReset = () => {
    // setSearch(false);
    setAppointmanet(getAllAppointment?.appointment);
    setDoctorId("");
    setSelectedDate(null);
  };

  const [currentDate, setCurrentDate] = useState(new Date());

  // Function to update the current date and time
  const updateCurrentDate = () => {
    setCurrentDate(new Date());
  };

  useEffect(() => {
    const intervalId = setInterval(updateCurrentDate, 1000); // Update every second
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <>
      <MakeAppointment {...MakeAppointmentProps} />
      <div className="Appointments-wrapper row mar-0">
        <div className="col-lg-9 padd-0">
          <div className="Appointments">
            <div className="AppointmentsHeader">
              <div className="left">
                <h2>Appointments</h2>
              </div>
              <div className="right">
                <div className="box-right">
                  <div className="icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/icons-images/appointments/calender-small.svg`}
                      alt="icon"
                    />
                  </div>
                  <div className="content">
                    <h6>{currentDate.toLocaleDateString()}</h6>
                  </div>
                </div>

                <div className="box-right">
                  <div className="icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/icons-images/appointments/alarm-clock.svg`}
                      alt="icon"
                    />
                  </div>
                  <div className="content">
                    <h6>{currentDate.toLocaleTimeString()}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="App-container">
            {/* <form> */}
            <div className="row">
              <div className=" col-md-4">
                <div className="form-group ">
                  <select
                    onChange={(e) => setDoctorId(e.target.value)}
                    value={doctorId}
                    className="input-control"
                  >
                    <option>Select Doctor</option>
                    {getListDoctor?.doctor?.map((doctor) => {
                      return (
                        <option value={doctor.id}>{doctor.doctorName}</option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className=" col-md-3">
                <div className="form-group ">
                  <button
                    className=" btn-md btn-md-blue mar-btn"
                    type="submit"
                    onClick={handleSearch}
                  >
                    Search
                  </button>

                  <button
                    className=" btn-md btn-md-blue mar-btn"
                    type="button"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* <div className=" col-md-2">
                <div className="form-group "></div>
              </div> */}
            </div>
            {/* </form> */}

            {/* <div className="container padd-0 mar-20">
              <div className="top-head">
                <div>
                  <h2>20th, Thursday March 2023</h2>
                </div>
                <div>
                  <button
                    className=" add-btn"
                    type="button"
                    onClick={() => setOpenMakeAppointment(true)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/icons-images/appointments/plus.png`}
                      alt="icon"
                    />
                  </button>
                </div>
              </div>

              <div className="row mar-20">
                <div className=" col-md-7">
                  <div className="doctor-left">
                    <div>
                      <div className="name sky">RSK</div>
                      <div className="name blue">SK</div>
                      <div className="name green">SK</div>
                      <div className="name orange">SK</div>
                      <div className="name pink">SK</div>
                    </div>
                    <h3>05 Appointments Today</h3>
                  </div>
                </div>

                <div className=" col-md-5">
                  <div className="doctor-right">
                    <div className="cal">
                      <ul>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="fa fa-bars"></i> Day
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-bars"></i> Week
                          </a>
                        </li>
                        <li>
                          <a href="#" className="selected">
                            <i className="fa fa-bars"></i> Month
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <hr />
            {getAllAppointment.loading || searchAppointment.loading ? (
              <Loader />
            ) : !appointment?.dataItems?.length ? (
              <div className="nodata">No data found</div>
            ) : (
              appointment?.dataItems?.map((appointment) => {
                return (
                  <div className="Doctor-Appointment">
                    <div className="row">
                      <div className="col-lg-2 box-width">
                        <div className="time">
                          {appointment.appointmentTime}
                        </div>
                      </div>
                      <div className="col-lg-10 box-width-right">
                        <div className="box">
                          <div className="box-left">
                            <div className="name sky">
                              <img
                                src={
                                  appointment.imageUrl ||
                                  "./images/placeholderimage.svg"
                                }
                                alt="icon"
                              />
                            </div>
                            <div className="content">
                              <h3>
                                {appointment.doctorName}
                                <font> ({appointment.doctorId})</font>
                              </h3>
                              <p>
                                Address : {appointment.hospitalAddress || "NA"}
                              </p>
                            </div>
                          </div>

                          <div className="box-left">
                            <div className="boder"></div>

                            <div className="content containt-mar">
                              <h3>Patient Name - {appointment.patientName}</h3>
                              <p>
                                Appointed Date : {appointment.appointmentDate}
                              </p>
                              <p>Time : {appointment.appointmentTime}</p>
                              <p>
                                Payment Method :{" "}
                                {appointment.paymentMethod == "1"
                                  ? "COD"
                                  : "Online"}
                              </p>
                              <p>
                                Vaccine Name :{" "}
                                {appointment.vaccineName.join(", ")}
                              </p>
                              <p>
                                Vaccine Price : ₹{appointment.vaccinePriceRange}
                              </p>
                              <p>
                                Appointed Book Date :
                                {appointment.createdAt
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </p>
                              <p>
                                Appointed Address :{" "}
                                {appointment.hospitalAddress}
                              </p>
                            </div>
                          </div>

                          <div className="containt-mar">
                            <div className="TextWrap">
                              <b>Status:</b>{" "}
                              <span className="Pending">
                                {appointmentStatus(appointment.status)}
                              </span>{" "}
                            </div>
                            <div className="center mt-2 scan-img">
                              <center>
                                {" "}
                                <img
                                  src={appointment.qrCodeImagrUrl}
                                  alt="icon"
                                />
                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <Pagination
            totalPages={appointment?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={appointment?.totalItems}
            noOfData={appointment?.dataItems?.length}
          />
        </div>
        <div className="col-lg-3 padd-0">
          <div className="Appointments-right">
            <div className="calender">
              <MyCalendar
                handleDateChange={handleDateChange}
                selectedDate={selectedDate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointments;
