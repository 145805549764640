import React, { useEffect, useState } from "react";
import useWindowSize from "../../utils/Hooks/useWindowSize";
import Modal from "../../components/commonUI/Modal";
import { useFormik } from "formik";
import { getToken, makeApiRequest, showError } from "../../utils/utils";
import Success from "../../components/commonUI/Success";
import { addParentSchema } from "../../utils/schema";
import { indianStates } from "../../utils/data";
import Select from "react-select";

const AddParent = ({
  isOpen,
  setIsOpen,
  title,
  disabled,
  type,
  onClose,
  setRenderFlag,
  renderFlag,
  user,
  fetchParents,
}) => {
  // For Responsive modal
  const windowSize = useWindowSize();
  let left = windowSize > 768 ? "calc(50% - 400px)" : "2.5%";
  let width = windowSize > 768 ? "800px" : "95%";

  // components code
  const [success, setSuccess] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);
  const token = getToken();

  const initialValues = {
    relation: "",
    relationName: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    role: ["patient"],
    agreeTermAndPriv: "1",
    status: "1",
  };

  const [userValues, setUserValues] = useState(initialValues);
  useEffect(() => {
    if (type === "view" || type === "update") {
      setUserValues({ ...user, relationName: user.parentName });
    } else {
      setUserValues(initialValues);
    }
  }, [user]);

  useEffect(() => {
    if (
      (renderFlag.addUser || renderFlag.updateUser || renderFlag.deleteUser) &&
      routeFlag
    ) {
      setSuccess(true);
      fetchParents();
    }
  }, [
    renderFlag.addUser,
    renderFlag.updateUser,
    renderFlag.deleteUser,
    routeFlag,
  ]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: userValues,
    validationSchema: addParentSchema,
    enableReinitialize: true,
    onSubmit: async (data) => {
      if (type === "add") {
        let result = await makeApiRequest("/vac/api/add-parent-by-admin", {
          method: "POST",
          data,
          token,
        });

        result.status &&
          setRenderFlag((state) => ({ ...state, addUser: true }));
      } else if (type === "update") {
        let result = await makeApiRequest(
          `/vac/api/update-parent-by-admin?parentId=${values.id}`,
          {
            method: "POST",
            data,
            token,
          }
        );
        result.status &&
          setRenderFlag((state) => ({ ...state, updateUser: true }));
      }
      setRouteFlag(true);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      style={{
        width,
        top: "15vh",
        height: "auto",
        background: "white",
        left,
        padding: "1rem",
        overflow: "auto",
      }}
    >
      <div className="top">
        <div className="heading">
          <h3>{title || "User Form"}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          <div className="Add-form-group">
            <div className="row">{/* Relation */}</div>
          </div>

          {/* fname relationName */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Full Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Full Name"
                  name="relationName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.relationName}
                  disabled={disabled}
                />
                {showError(errors.relationName, touched.relationName)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Relation<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="relation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.relation}
                  disabled={disabled}
                >
                  <option value="">Select Relation</option>
                  <option value="Mother">Mother</option>
                  <option value="Father">Father</option>
                  <option value="Others">Others</option>
                </select>
                {showError(errors.relation, touched.relation)}
              </div>
            </div>
          </div>

          {/* Email PhoneNumber */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Email Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  disabled={type !== "add"}
                />
                {showError(errors.email, touched.email)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.phoneNumber}
                  disabled={type !== "add"}
                />
                {showError(errors.phoneNumber, touched.phoneNumber)}
              </div>
            </div>
          </div>

          {/* pincode Home Address */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.address}
                  disabled={disabled}
                />
                {showError(errors.address, touched.address)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Pin Code<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.pincode}
                  disabled={disabled}
                />
                {showError(errors.pincode, touched.pincode)}
              </div>
            </div>
          </div>

          {/* City State */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.city}
                  disabled={disabled}
                />
                {showError(errors.city, touched.city)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  State<span>*</span>
                </label>
                <Select
                  name="state"
                  isDisabled={type === "view"}
                  onChange={(selectedOptions) => {
                    setFieldValue("state", selectedOptions.value);
                  }}
                  options={indianStates.map((s) => ({ label: s, value: s }))}
                  value={{
                    value: values?.state || null,
                    label: values?.state || "Select...",
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "44px",
                    }),
                  }}
                  placeholder={"Select a state"}
                />
                {showError(errors.state, touched.state)}
              </div>
            </div>
          </div>

          {/* City State */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6">
                <input
                  type="checkbox"
                  className="mr-1"
                  name="agreeTermAndPriv"
                  onChange={(e) =>
                    setFieldValue(
                      "agreeTermAndPriv",
                      e.target.checked ? "1" : "0"
                    )
                  }
                  onBlur={handleBlur}
                  checked={values.agreeTermAndPriv === "1"}
                  value={values?.agreeTermAndPriv}
                  disabled={disabled}
                />
                <label>
                  Agree to our terms & Condtions<span>*</span>
                </label>
                {showError(errors.agreeTermAndPriv, touched.agreeTermAndPriv)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disabled && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>

      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={
          type === "add" ? "Added successfully!" : "Updated Successfully"
        }
        descMessage={
          type === "add"
            ? "Parent has been added successfully!"
            : "Parent updated successfully"
        }
        closePreviousModal={onClose}
      />
    </Modal>
  );
};

export default AddParent;
