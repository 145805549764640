import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Success from "../../commonUI/Success";
import {
  convertDateFormat,
  getCurrentUserLT,
  reverseDateFormat,
  showError,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Switch from "../../commonUI/Switch";
import { editProfileSchema } from "../../../utils/schema";
import { updateUserAction } from "../../../redux/slice/users/updateUserSlice";
import { getUserByIdAction } from "../../../redux/slice/users/getUserByIdSlice";

const EditProfileForm = ({ onClose, type }) => {
  const fileInputRef = useRef();
  const userById = useSelector((state) => state.userById);
  const [previewUrl, setPreviewUrl] = useState(
    userById?.user.profileImage || ""
  );
  const [success, setSuccess] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);
  const user = getCurrentUserLT();
  const updateUser = useSelector((state) => state.updateUser);
  const dispatch = useDispatch();

  useEffect(() => {
    userById.success && setPreviewUrl(userById?.user.profileImage);
  }, [userById.success]);

  useEffect(() => {
    if (routeFlag && updateUser.success) {
      onClose();
      dispatch(getUserByIdAction(values.id));
    }
  }, [updateUser.success]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...userById.user,
      dateOfBirth: (userById.user?.dateOfBirth).split("/").reverse().join("-"),
    },
    validationSchema: editProfileSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      const newValues = {
        fname: values?.fname,
        lname: values?.lname,
        dateOfBirth: convertDateFormat(values?.dateOfBirth),
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        profileImg: values?.profileImage,
        gender: values?.gender,
        address: values?.address,
        bloodGroup: values?.bloodGroup,
        landmark: values?.landmark,
        city: values?.city,
        state: values?.state,
        pincode: values?.pincode,
        country: values?.country,
        status: values?.status,
        "role[]": values?.role,
      };

      for (const [key, value] of Object.entries(newValues)) {
        formData.append(key, value);
      }
      dispatch(updateUserAction({ id: values.id, data: formData }));
      setRouteFlag(true);
    },
  });

  console.log(errors);

  const handleFileChange = (event) => {
    // Pass the selected file to the parent component using the onChange callback
    const file = event.target.files[0];
    setFieldValue("profileImage", file);

    // Read and set the preview URL for image files
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl("./icons-images/avtar.svg");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className="ModalAvtar"
          onClick={() => fileInputRef.current.click()}
        >
          <img src={previewUrl || "./icons-images/avtar.svg"} alt="icon" />
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  First Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="First Name"
                  name="fname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.fname}
                />
                {showError(errors.fname, touched.fname)}
              </div>

              <div className=" col-md-6 ">
                <label>Last Name</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Last Name"
                  name="lname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lname}
                />
                {showError(errors.lname, touched.lname)}
              </div>
            </div>
          </div>

          {/* Email Gender */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Email Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="saurabhkainth@gmail.com"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  disabled={type !== "add"}
                />{" "}
                {showError(errors.email, touched.email)}
              </div>
              <div className=" col-md-6 ">
                <label>Date of Birth</label>
                <input
                  type="date"
                  className="input-control"
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.dateOfBirth}
                />{" "}
                {showError(errors.dateOfBirth, touched.dateOfBirth)}
              </div>
            </div>
          </div>

          {/* phoneNumber roleType */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Gender<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="gender"
                  onChange={handleChange}
                  value={values?.gender}
                >
                  <option>Select</option>
                  <option value="0">Male</option>
                  <option value="1">Female</option>
                  <option value="2">Other</option>
                </select>{" "}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="+99 99999999999"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.phoneNumber}
                  disabled={type !== "add"}
                />
                {showError(errors.phoneNumber, touched.phoneNumber)}
              </div>
            </div>
          </div>

          {/* City State */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Land Mark</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Land Mark"
                  name="landmark"
                  onChange={handleChange}
                  value={values?.landmark}
                />{" "}
              </div>
              <div className=" col-md-6 ">
                <label>City</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  value={values?.city}
                />{" "}
              </div>
            </div>
          </div>

          {/* Country Pincode */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>State</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="State"
                  name="state"
                  onChange={handleChange}
                  value={values?.state}
                />{" "}
              </div>
              <div className=" col-md-6 ">
                <label>Country</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Country"
                  name="country"
                  onChange={handleChange}
                  value={values?.country}
                />{" "}
              </div>
            </div>
          </div>

          {/* password Pincode */}

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Pin Code</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleChange}
                  value={values?.pincode}
                />
              </div>
              {/* <div className="col-md-6 ">
                <label>Password</label>
                <span>*</span>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.password}
                />{" "}
                {showError(errors.password, touched.password)}
              </div> */}
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary-big big-btn-padd"
              // onClick={() => {
              //   setSuccess(true);
              // }}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={"Added successfully!"}
        descMessage={"User has been added successfully!"}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default EditProfileForm;
