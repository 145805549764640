import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Success from "../../commonUI/Success";
import {
  convertDateFormat,
  reverseDateFormat,
  showError,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addUserAction } from "../../../redux/slice/users/addUserSlice";
import { updateUserAction } from "../../../redux/slice/users/updateUserSlice";
import { addUserSchema, updateUserSchema } from "../../../utils/schema";
import Loader from "../../commonUI/Loader";
import { indianStates } from "../../../utils/data";
import Select from "react-select";

const initialValues = {
  profileImg: "",
  fname: "",
  lname: "",
  email: "",
  gender: "",
  phoneNumber: "",
  role: "",
  dateOfBirth: "",
  city: "",
  state: "",
  country: "India",
  pincode: "",
  address: "",
  password: "",
  confirmPasword: "",
  status: "1",
  agreeTermsAndPrivacypolicy: "1",
  // hospitalId: "Yatharth-HOS-197",
};

const AddUserForm = ({ onClose, disable, type }) => {
  const fileInputRef = useRef();
  const [previewUrl, setPreviewUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);

  const dispatch = useDispatch();
  const addUser = useSelector((state) => state.addUser);
  const userById = useSelector((state) => state.userById);
  const updateUser = useSelector((state) => state.updateUser);

  const [userValues, setUserValues] = useState(initialValues);

  useEffect(() => {
    if (userById.success) {
      if (type === "add") {
        setUserValues(initialValues);
      } else {
        setPreviewUrl(userById.user?.profileImage);
        setUserValues({
          ...userById.user,
          gender: userById.user.gender,
          dateOfBirth: reverseDateFormat(userById.user?.dateOfBirth),
        });
      }
    }
  }, [userById.success]);

  useEffect(() => {
    if ((addUser.success || updateUser.success) && routeFlag) {
      setSuccess(true);
    }
  }, [addUser.success, updateUser.success]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: userValues,
    validationSchema: type === "add" ? addUserSchema : updateUserSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (key === "dateOfBirth") {
          formData.append(key, convertDateFormat(value));
        } else if (key === "role") {
          formData.append("role[]", value);
        } else {
          formData.append(key, value);
        }
      }

      if (type === "add") {
        dispatch(addUserAction(formData));
      } else if (type === "update") {
        dispatch(updateUserAction({ id: values.id, data: formData }));
      }
      setRouteFlag(true);
    },
  });

  const handleFileChange = (event) => {
    // Pass the selected file to the parent component using the onChange callback
    const file = event.target.files[0];
    setFieldValue("profileImg", file);

    // Read and set the preview URL for image files
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl("./icons-images/avtar.svg");
    }
  };

  return (
    <>
      {userById.loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <div
            className="ModalAvtar"
            onClick={() => fileInputRef.current.click()}
          >
            <img src={previewUrl || "./icons-images/avtar.svg"} alt="icon" />
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            name="profileImg"
            disabled={disable}
            onBlur={() => setFieldTouched("profileImg")}
          />
          {errors.profileImg && touched.profileImg && (
            <div className="error-msg text-center mt-2">
              {errors.profileImg}
            </div>
          )}
          <div className="mar-30">
            {/* fname lname */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>
                    First Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="First Name"
                    name="fname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.fname}
                    disabled={disable}
                  />
                  {showError(errors.fname, touched.fname)}
                </div>

                <div className=" col-md-6 ">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Last Name"
                    name="lname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lname}
                    disabled={disable}
                  />
                  {showError(errors.lname, touched.lname)}
                </div>
              </div>
            </div>

            {/* Email Gender */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>
                    Email Address<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Email Address"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.email}
                    disabled={type !== "add"}
                  />{" "}
                  {showError(errors.email, touched.email)}
                </div>
                <div className=" col-md-6 ">
                  <label>
                    User Type<span>*</span>
                  </label>
                  <select
                    name="role"
                    className="input-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    disabled={type === "update" || disable}
                  >
                    <option>-- Please Select User Type --</option>
                    <option value="support">Support</option>
                    <option value="receptionist">Reception</option>
                    <option value="admin">Admin</option>
                  </select>
                  {showError(errors.role, touched.role)}
                </div>
              </div>
            </div>

            {/* Mode of patientce */}

            {/* phoneNumber gender */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>
                    Gender
                    <span>*</span>
                  </label>
                  <select
                    className="input-control"
                    name="gender"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.gender}
                    disabled={disable}
                  >
                    <option value="" selected>
                      Please Select Gender
                    </option>
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                    <option value="2">Other</option>
                  </select>
                  {showError(errors.gender, touched.gender)}
                </div>
                <div className=" col-md-6 ">
                  <label>
                    Phone Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.phoneNumber}
                    disabled={type !== "add"}
                  />
                  {showError(errors.phoneNumber, touched.phoneNumber)}
                </div>
              </div>
            </div>

            {/* dateOfBirth Home Address */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    className="input-control"
                    placeholder="Date of Birth"
                    name="dateOfBirth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.dateOfBirth}
                    disabled={disable}
                  />
                  {showError(errors.dateOfBirth, touched.dateOfBirth)}
                </div>
                <div className=" col-md-6 ">
                  <label>Address</label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address}
                    disabled={disable}
                  />
                  {showError(errors.address, touched.address)}
                </div>
              </div>
            </div>

            {/* City State */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>City</label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="City"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.city}
                    disabled={disable}
                  />
                  {showError(errors.city, touched.city)}
                </div>
                <div className=" col-md-6 ">
                  <label>State</label>
                  <Select
                    name="state"
                    isDisabled={type === "view"}
                    onChange={(selectedOptions) => {
                      setFieldValue("state", selectedOptions.value);
                    }}
                    options={indianStates.map((s) => ({ label: s, value: s }))}
                    value={{
                      value: values?.state || null,
                      label: values?.state || "Select...",
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: "44px",
                      }),
                    }}
                    placeholder={"Select a state"}
                  />
                  {showError(errors.state, touched.state)}
                </div>
              </div>
            </div>

            {/* Country Pincode */}
            <div className="Add-form-group">
              <div className="row">
                <div className=" col-md-6 ">
                  <label>Country</label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Country"
                    name="country"
                    value="India"
                    disabled={disable}
                  />
                  {showError(errors.country, touched.country)}
                </div>
                <div className=" col-md-6 ">
                  <label>Pin Code</label>
                  <input
                    type="text"
                    className="input-control"
                    placeholder="Pin Code"
                    name="pincode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.pincode}
                    disabled={disable}
                  />
                  {showError(errors.pincode, touched.pincode)}
                </div>
              </div>
            </div>

            {/* password Pincode */}
            {!disable && type === "add" && (
              <div className="Add-form-group">
                <div className="row">
                  <div className="col-md-6 ">
                    <label>
                      Password<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-control"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                      disabled={disable}
                    />
                    {showError(errors.password, touched.password)}
                  </div>
                  <div className=" col-md-6 ">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-control"
                      placeholder="Confirm pasword"
                      name="confirmPasword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.confirmPasword}
                      disabled={disable}
                    />{" "}
                    {showError(errors.confirmPasword, touched.confirmPasword)}
                  </div>
                </div>
              </div>
            )}

            {/* submit */}
            <div className="form-group aling-right">
              <button
                type="button"
                className="btn btn-outline-primary big-btn-padd"
                onClick={onClose}
              >
                Cancel
              </button>
              {!disable && (
                <button
                  type="submit"
                  className="btn btn-primary-big big-btn-padd"
                  // onClick={() => {
                  //   setSuccess(true);
                  // }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      )}
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={
          type === "add" ? "Added successfully!" : "Updated Successfully"
        }
        descMessage={
          type === "add"
            ? "User has been added successfully!"
            : "User updated successfully"
        }
        closePreviousModal={onClose}
      />
    </>
  );
};
export default AddUserForm;
