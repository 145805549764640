import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import AddPatient from "../../components/UI/patient/AddPatient";
import { useDispatch, useSelector } from "react-redux";
import { getVaccinesAction } from "../../redux/slice/common/getVaccinesSlice";
import { getAllPatientListAction } from "../../redux/slice/patient/getAllPatientListSlice";
import { patientStatusAction } from "../../redux/slice/patient/patientStatusSlice";
import { getPatientByIdAction } from "../../redux/slice/patient/getPatientByIdSlice";
import { deletePatientAction } from "../../redux/slice/patient/deletePatientSlice";
import { searchPatientAction } from "../../redux/slice/patient/searchPatientSlice";

const Patient = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateDoctor, setShowUpdatePatient] = useState(false);
  const [showViewDoctor, setShowViewPatient] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [patientId, setPatientId] = useState();
  const [patientList, setPatientList] = useState({ dataItems: [] });
  const [message, setMessage] = useState("");
  const [searchFilters, setSearchFilters] = useState({
    name: "",
    status: "",
  });

  const handleInputChange = (fieldName, value) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };

  const dispatch = useDispatch();
  const {
    getAllPatientList,
    searchPatient,
    updatePatient,
    deletePatient,
    addPatient,
  } = useSelector((state) => ({
    getAllPatientList: state.getAllPatientList,
    searchPatient: state.searchPatient,
    updatePatient: state.updatePatient,
    addPatient: state.addPatient,
    deletePatient: state.deletePatient,
  }));

  useEffect(() => {
    dispatch(getVaccinesAction());
  }, []);

  useEffect(() => {
    dispatch(getAllPatientListAction(pageNumber));
  }, [
    pageNumber,
    deletePatient.success,
    addPatient.success,
    updatePatient.success,
  ]);

  useEffect(() => {
    if (searchPatient.success && (searchFilters.name || searchFilters.status)) {
      setPatientList(searchPatient?.patients);
    } else if (getAllPatientList.success) {
      setPatientList(getAllPatientList?.patients);
    }
  }, [getAllPatientList.success, searchPatient.success]);

  const handleSearch = (e) => {
    e.preventDefault();
    // Check if either the name or status fields are not empty
    if (searchFilters.name.length >= 3 || searchFilters.status) {
      dispatch(searchPatientAction({ ...searchFilters }));
      setMessage("");
    } else {
      setMessage(
        "Please enter/select at least one search field or at least 3 characters"
      );
      setPatientList(getAllPatientList?.patients);
    }
  };

  const handleReset = () => {
    // setSearch(false);
    setPatientList(getAllPatientList?.patients);
    setSearchFilters({
      name: "",
      status: "",
    });
    setMessage("");
  };

  const handleStatusChange = (value, id) => {
    dispatch(patientStatusAction({ data: { status: value }, id }));
  };

  const startSerialNumber = pageNumber * 20 + 1;

  const genders = ["Male", "Female", "Other"];

  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2> </h2>
          </div>

          {/* search */}
          {/* <form> */}
          <form className="row mar-20" onSubmit={handleSearch}>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name/Email/Phone Number"
                  value={searchFilters.name}
                  onChange={(e) => {
                    handleInputChange("name", e.target.value);
                    !e.target.value &&
                      dispatch(getAllPatientListAction(pageNumber));
                  }}
                />
              </div>
            </div>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <select
                  className="input-control"
                  value={searchFilters.status}
                  onChange={(e) => handleInputChange("status", e.target.value)}
                >
                  <option>Search by Patient Status</option>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
            </div>

            <div className=" col-md-3">
              <div className="form-group ">
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>

                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {message && <div className="alert alert-danger">{message}</div>}
          </form>
          {/* </form> */}

          <div className="colBox ">
            <div className="mid-head">
              <h2>Patient Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Patient
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Parent Name</th>
                    <th scope="col">User ID</th>
                    <th scope="col">Mobile Number</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.dataItems?.length === 0 ? (
                    <p className="NoDatafound">No Data found!</p>
                  ) : (
                    patientList.dataItems?.map((patient, index) => {
                      return (
                        <tr key={index}>
                          <td data-label="S.No">
                            {(startSerialNumber + index)
                              .toString()
                              .padStart(2, "0")}
                          </td>
                          <td data-label="Patient Name">
                            {patient.fname + " " + patient.lname || ""}
                          </td>
                          <td data-label="Parent Name">
                            {patient.relationName || "NA"}
                          </td>
                          <td data-label="User ID">{patient.UUID}</td>
                          <td data-label="Mobile Number">
                            {patient.phoneNumber}
                          </td>
                          <td data-label="Gender">
                            {genders[Number(patient.gender)]}
                          </td>

                          <td data-label="Status" className="tdGape">
                            <Switch
                              switchValue={patient.status}
                              switchId={patient.id}
                              handleChange={handleStatusChange}
                            />
                          </td>
                          <td data-label="Action">
                            <button
                              className=" btn-small greenbg"
                              type="button"
                              onClick={() => {
                                dispatch(getPatientByIdAction(patient.id));
                                setShowUpdatePatient(true);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              type="button"
                              className=" btn-small yellowbg"
                              onClick={() => {
                                dispatch(getPatientByIdAction(patient.id));
                                setShowViewPatient(true);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small redbg"
                              type="submit"
                              onClick={() => {
                                setShowConfirm(true);
                                setPatientId(patient.id);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                                alt="icon"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={patientList?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={patientList?.totalItems}
            noOfData={patientList?.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}
      {isOpen && (
        <AddPatient
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsOpen={setIsOpen}
          title="Add Patient"
          type="add"
        />
      )}
      {showUpdateDoctor && (
        <AddPatient
          isOpen={showUpdateDoctor}
          onClose={() => setShowUpdatePatient(false)}
          title="Update Patient"
          setIsOpen={setShowUpdatePatient}
          disabled={false}
          type="update"
        />
      )}
      {showViewDoctor && (
        <AddPatient
          isOpen={showViewDoctor}
          onClose={() => setShowViewPatient(false)}
          title="View Patient"
          setIsOpen={setShowViewPatient}
          disabled={true}
          type="view"
        />
      )}
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deletePatientAction(patientId));
        }}
      />
    </>
  );
};

export default Patient;
