import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const NestedMenu = ({ menuName, menuIcon, menuURL, submenu }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  return (
    <div className="menu">
      <NavLink to={menuURL} onClick={() => setShowSubmenu(!showSubmenu)}>
        <img src={`${process.env.PUBLIC_URL}${menuIcon}`} alt="" />
        <span>{menuName}</span>
        <i
          className={
            showSubmenu
              ? "fa-solid fa-sort-down dropDownArrow"
              : "fa-solid fa-caret-right dropDownArrow"
          }
        ></i>
      </NavLink>
      <div className="submenu">
        {showSubmenu
          ? submenu.map((submenu, index) => {
              return (
                <NavLink to={submenu.url}>
                  <img
                    src={`${process.env.PUBLIC_URL}${submenu.icon}`}
                    alt={submenu.name}
                  />
                  <span>{submenu.name}</span>
                </NavLink>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default NestedMenu;
