import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const getBlogByIdAction = createAsyncThunk("getBlogById", async (id) => {
  return await makeApiRequest(`/vac/api/blog/get-single-blog?blogId=${id}`, {
    token: getToken(),
  });
});

const initialState = {
  blog: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getBlogByIdSlice = createSlice({
  name: "getBlogById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogByIdAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getBlogByIdAction.fulfilled, (state, { payload }) => {
        state.blog = payload.blogData;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getBlogByIdAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getBlogByIdSlice.reducer;
