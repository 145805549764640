import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function MyEditor({
  value,
  handleBlur,
  disabled,
  name,
  setFieldValue,
}) {
  // [{ font: ["Roboto"] }],
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    // "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    // "image",
    // "video",
    "formula",
    "table",
  ];

  const calculateEditorHeight = (lines) => {
    // Adjust these values based on your font size and line height
    const fontSize = 14; // Font size in pixels
    const lineHeight = 1.5; // Line height multiplier (e.g., 1.5 for 150% line height)

    const lineHeightPx = fontSize * lineHeight;
    const minHeight = lineHeightPx * lines;
    return `${minHeight}px`;
  };

  const editorStyle = {
    minHeight: calculateEditorHeight(5),
    fontFamily: "Roboto",
  };
  return (
    <ReactQuill
      theme="snow"
      className={disabled ? "disable" : ""}
      value={value}
      onBlur={() => {
        handleBlur && handleBlur(name, true);
      }}
      style={editorStyle}
      onChange={(content) =>
        setFieldValue && setFieldValue(name || "description", content)
      }
      readOnly={disabled}
      name={name || "description"}
      modules={modules}
      formats={formats}
      placeholder="Start writing..."
    />
  );
}

MyEditor.defaultProps = {
  name: "description", // Set a default value for the name prop
};
