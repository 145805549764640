import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const categoryStatusAction = createAsyncThunk(
  "categoryStatus",
  async ({ id, data }) => {
    return await makeApiRequest(
      `/vac/api/category/updateCategoryStatus?categoryId=${id}`,
      {
        token: getToken(),
        method: "PATCH",
        data,
      }
    );
  }
);

const initialState = {
  category: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const categoryStatusSlice = createSlice({
  name: "categoryStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoryStatusAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(categoryStatusAction.fulfilled, (state, { payload }) => {
        state.category = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
        notify(payload.message);
      })
      .addCase(categoryStatusAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default categoryStatusSlice.reducer;
