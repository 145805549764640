import React, { useState } from "react";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import Modal from "../../commonUI/Modal";
import { useFormik } from "formik";
import { showError } from "../../../utils/utils";
import Success from "../../commonUI/Success";

const FollowUpDetails = ({ isOpen, setIsOpen, title, onClose }) => {
  const windowSize = useWindowSize();
  const [success, setSuccess] = useState(false);

  let left = windowSize > 768 ? "calc(50% - 400px)" : "2.5%";
  let width = windowSize > 768 ? "800px" : "95%";

  const initialValues = {
    fullName: "",
    gender: "",
    dob: "",
    disease: "",
    phoneNo: "",
    department: "",
    tokenNo: "",
    status: "",
    prescription: "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit: (values) => console.log(values),
    });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      style={{
        width,
        top: "10vh",
        height: "auto",
        maxHeight: "80vh",
        background: "white",
        left,
        padding: "1rem",
        overflow: "auto",
      }}
    >
      <div className="top">
        <div className="heading">
          <h3>{title || "User Form"}</h3>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6">
                <label>
                  Full Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Full Name"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                />
                {showError(errors.fullName, touched.fullName)}
              </div>

              <div className=" col-md-6">
                <label>
                  Gender<span>*</span>
                </label>
                <select
                  type="text"
                  className="input-control"
                  placeholder="Full Name"
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {showError(errors.gender, touched.gender)}
              </div>

              <div className=" col-md-6">
                <label>
                  Date of Birth<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Date of Birth"
                  name="dob"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dob}
                />
                {showError(errors.dob, touched.dob)}
              </div>

              <div className=" col-md-6">
                <label>
                  Disease/Symptoms<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Disease/Symptoms"
                  name="disease"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.disease}
                />
                {showError(errors.disease, touched.disease)}
              </div>

              <div className=" col-md-6">
                <label>
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Phone Number"
                  name="phoneNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNo}
                />
                {showError(errors.phoneNo, touched.phoneNo)}
              </div>

              <div className=" col-md-6">
                <label>
                  Department<span>*</span>
                </label>
                <select
                  type="text"
                  className="input-control"
                  name="department"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.department}
                >
                  <option>Select Department</option>
                  <option value="cardiology">Cardiology</option>
                </select>
                {showError(errors.department, touched.department)}
              </div>

              <div className=" col-md-6">
                <label>
                  Token Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Token Number"
                  name="tokenNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tokenNo}
                />
                {showError(errors.tokenNo, touched.tokenNo)}
              </div>

              <div className=" col-md-6">
                <label>
                  Status<span>*</span>
                </label>
                <select
                  type="text"
                  className="input-control"
                  name="status"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option>Select Status</option>
                  <option value="waiting">Waiting</option>
                  <option value="close">Close</option>
                </select>
                {showError(errors.status, touched.status)}
              </div>

              <div className=" col-md-6">
                <label>
                  Prescription<span>*</span>
                </label>
                <textarea
                  className="input-control"
                  placeholder="Token Number"
                  name="prescription"
                  rows="5"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.prescription}
                />
                {showError(errors.prescription, touched.prescription)}
              </div>
            </div>
          </div>

          <div className="form-group mt-3">
            <button
              type="button"
              className="btn btn-outline-primary mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary-big"
              onClick={() => {
                setSuccess(true);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={"Added successfully!"}
        descMessage={"Doctor has been added successfully!"}
        closePreviousModal={onClose}
      />
    </Modal>
  );
};

export default FollowUpDetails;
