import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const addVaccineAction = createAsyncThunk("addVaccine", async (data) => {
  return await makeApiRequest(`/vac/api/create-vaccine`, {
    token: getToken(),
    method: "POST",
    apiKey: process.env.REACT_APP_API_KEY,
    data,
  });
});

const initialState = {
  vaccine: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const addVaccineSlice = createSlice({
  name: "addVaccine",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addVaccineAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(addVaccineAction.fulfilled, (state, { payload }) => {
        state.vaccine = payload;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(addVaccineAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default addVaccineSlice.reducer;
