import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Success from "../../commonUI/Success";
import { addPatientSchema, updatePatientSchema } from "../../../utils/schema";
import Switch from "../../commonUI/Switch";
import {
  convertDateFormat,
  getToken,
  makeApiRequest,
  reverseDateFormat,
  showError,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addPatientAction } from "../../../redux/slice/patient/addPatientSlice";
import { getVaccineByAgeAction } from "../../../redux/slice/vaccine/getVaccineByAgeSlice";
import { updatePatientAction } from "../../../redux/slice/patient/updatePatientSlice";
import Select from "react-select";
import { getAllPatientListAction } from "../../../redux/slice/patient/getAllPatientListSlice";
import { indianStates } from "../../../utils/data";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  phoneNumber: "",
  dateOfBirth: "",
  gender: "",
  bloodGroup: "",
  address: "",
  landmark: "",
  city: "",
  state: "",
  pincode: "",
  country: "India",
  childHeight: "0",
  vaccine: null,
  status: "0",
  childWeight: "0",
  role: ["patient"],
  relationUserID: "",
};

const AddPatientForm = ({ onClose, disable, type }) => {
  const [patientValue, setPatientValue] = useState([]);
  const [success, setSuccess] = useState(false);
  const [closeFlag, setCloseFlag] = useState(false);

  const dispatch = useDispatch();
  const { getVaccineByAge } = useSelector((state) => state);
  const { updatePatient } = useSelector((state) => state);
  const { addPatient } = useSelector((state) => state);
  const [parents, setParents] = useState([]);

  const [selectedVaccines, setSelectedVaccines] = useState([]);

  const { getPatientById } = useSelector((state) => state);

  useEffect(() => {
    if ((updatePatient.success || addPatient.success) && closeFlag) {
      setSuccess(true);
    }
  }, [updatePatient.success, addPatient.success]);

  // Fetching parents for dropdown
  useEffect(() => {
    dispatch(getAllPatientListAction());
  }, []);

  // Getting parents list
  const fetchParents = async () => {
    try {
      const result = await makeApiRequest(`/vac/api/all-parent-user-list`, {
        method: "GET",
        token: getToken(),
      });
      setParents(result.data);
    } catch (error) {
      setParents([]);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchParents();
  }, []);

  // Settting values for view and update modal
  useEffect(() => {
    if (type == "add") {
      setPatientValue(initialValues);
    } else if (getPatientById.success) {
      let val = {
        ...getPatientById.patient,
        dateOfBirth: reverseDateFormat(
          getPatientById.patient?.dateOfBirth?.split("/")?.reverse().join("/")
        ),
        vaccine: JSON.parse(getPatientById?.patient?.vaccine),
        parentName: getPatientById?.patient?.relationName,
      };
      if ("vaccine" in initialValues) {
        setFieldValue("vaccine", val.vaccine);
      }
      setPatientValue(val);

      // Initialize selected vaccines with the preselected IDs
      setSelectedVaccines(val.vaccine);
    }
  }, [getPatientById.success]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: patientValue,
    validationSchema: type === "add" ? addPatientSchema : updatePatientSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const newValues = {
        ...values,
        childWeight: String(values.childWeight),
        dateOfBirth: convertDateFormat(values.dateOfBirth),
        // vaccine: JSON.stringify(values.vaccine)
        vaccine: JSON.stringify(selectedVaccines),
      };
      if (type === "add") {
        dispatch(addPatientAction(newValues));
      } else {
        dispatch(updatePatientAction({ id: values.id, data: newValues }));
      }
      setCloseFlag(true);
    },
  });

  function dateToDays(a) {
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    return Math.floor(
      (new Date() - new Date(a).getTime()) / millisecondsInADay
    );
  }

  useEffect(() => {
    // You can only dispatch the action if childAge is a valid number
    if (values.dateOfBirth) {
      dispatch(getVaccineByAgeAction({ age: dateToDays(values.dateOfBirth) }));
    }
  }, [values.dateOfBirth]);

  const handleVaccineCheckboxChange = (vaccineId) => {
    // Step 2
    setSelectedVaccines((prevSelectedVaccines) => {
      if (prevSelectedVaccines.includes(vaccineId)) {
        return prevSelectedVaccines.filter((id) => id !== vaccineId);
      } else {
        return [...prevSelectedVaccines, vaccineId];
      }
    });
  };

  // formatting age from days
  function formatChildAge(age) {
    age = parseInt(age);

    if (age === 0) {
      return `${age} day`;
    } else if (age >= 1 && age <= 41) {
      return `${age} days`;
    } else if (age >= 42 && age <= 180) {
      return `${Math.floor(age / 7)} weeks`;
    } else if (age >= 181 && age < 1459) {
      const months = Math.floor(age / 30);
      return months !== 48 ? `${months} months` : "4 years";
    } else if (age >= 1460 && age <= 6569) {
      return `${Math.floor(age / 365)} years`;
    } else if (age === 6570) {
      return "18+ Years";
    }
    return "Invalid Age";
  }

  // Replace with the actual child's age
  const formattedAge = formatChildAge(values.childAge);

  const options = parents?.map((h) => ({
    value: h.id,
    label: h.parentName,
  }));

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          <div className="Add-form-group">
            <label>
              Parent<span>*</span>
            </label>
            <Select
              name="relationUserID"
              onBlur={() => setFieldTouched("relationUserID")}
              value={
                options.filter(
                  (p) => Number(p.value) === Number(values.relationUserID)
                ) || null
              }
              onChange={(selectedOption) => {
                setFieldValue("relationUserID", selectedOption?.value || "");
              }}
              options={options}
              isDisabled={disable}
            />
            {showError(errors.relationUserID, touched.relationUserID)}
          </div>

          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  First Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="First Name "
                  name="fname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fname}
                  disabled={disable}
                />{" "}
                {showError(errors.fname, touched.fname)}
              </div>

              <div className=" col-md-6 ">
                <label>Last Name</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Last Name"
                  name="lname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lname}
                  disabled={disable}
                />{" "}
              </div>
            </div>
          </div>

          {/* PhoneNumber DateofBirth */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  disabled={disable || type === "update"}
                  // disabled={type !== "add"}
                />
                {showError(errors.phoneNumber, touched.phoneNumber)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Date of Birth<span>*</span>
                </label>
                <input
                  type="date"
                  className="input-control"
                  placeholder=""
                  name="dateOfBirth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateOfBirth}
                  disabled={disable}
                />
                {showError(errors.dateOfBirth, touched.dateOfBirth)}
              </div>
            </div>
          </div>

          {/* Email Gender */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Email Address<span>*</span>
                </label>
                <input
                  type="email"
                  className="input-control"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={disable || type === "update"}
                  // disabled={type !== "add"}
                />{" "}
                {showError(errors.email, touched.email)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Gender<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  disabled={disable}
                >
                  <option>-- Please Select Gender --</option>
                  <option value="0">Male</option>
                  <option value="1">Female</option>
                  <option value="2">Other</option>
                </select>{" "}
                {showError(errors.gender, touched.gender)}
              </div>
            </div>
          </div>

          {/* BloodGroup Address */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Blood Group<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="bloodGroup"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bloodGroup}
                  disabled={disable}
                >
                  <option>-- Please Select Blood Group --</option>
                  <option value="1">A+</option>
                  <option value="2">A-</option>
                  <option value="3">B+</option>
                  <option value="4">B-</option>
                  <option value="5">AB+</option>
                  <option value="6">AB-</option>
                  <option value="7">O+</option>
                  <option value="8">O-</option>
                </select>
                {/* <input
                  type="text"
                  className="input-control"
                  placeholder="Blood Group"
                  name="bloodGroup"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bloodGroup}
                />{" "} */}
                {showError(errors.bloodGroup, touched.bloodGroup)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  disabled={disable}
                />{" "}
                {showError(errors.address, touched.address)}
              </div>
            </div>
          </div>

          {/* landMark City */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Land Mark</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Land Mark"
                  name="landmark"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.landmark}
                  disabled={disable}
                />{" "}
                {/* {showError(errors.landmark, touched.landmark)} */}
              </div>

              <div className=" col-md-6 ">
                <label>
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  disabled={disable}
                />{" "}
                {showError(errors.city, touched.city)}
              </div>
            </div>
          </div>

          {/* State Pincode */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  State <span>*</span>
                </label>
                <Select
                  name="state"
                  isDisabled={type === "view"}
                  onChange={(selectedOptions) => {
                    setFieldValue("state", selectedOptions.value);
                  }}
                  options={indianStates.map((s) => ({ label: s, value: s }))}
                  value={{
                    value: values?.state || null,
                    label: values?.state || "Select...",
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "44px",
                    }),
                  }}
                  onBlur={() => setFieldTouched("state")}
                  // placeholder={"Select a state"}
                />
                {showError(errors.state, touched.state)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Country<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Country"
                  name="country"
                  value="India"
                  disabled={disable}
                />
                {showError(errors.country, touched.country)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Pin Code<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pincode}
                  disabled={disable}
                />
                {showError(errors.pincode, touched.pincode)}
              </div>
            </div>
          </div>

          {/* Weight Height */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Weight(Kg) <span>*</span>
                </label>
                <p className="RangeSlide">{values.childWeight}</p>
                <input
                  type="range"
                  className="input-control rangeHeight"
                  placeholder=""
                  name="childWeight"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.childWeight}
                  min="0"
                  max="25"
                  // step={10}
                  disabled={disable}
                />
                {showError(errors.childWeight, touched.childWeight)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Height(cm) <span>*</span>
                </label>
                <p className="RangeSlide">{values.childHeight}</p>
                <input
                  type="range"
                  className="input-control rangeHeight"
                  placeholder=""
                  name="childHeight"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.childHeight}
                  min="0"
                  max="131"
                  disabled={disable}
                />
                {showError(errors.childHeight, touched.childHeight)}
              </div>
            </div>
          </div>

          {/* Vaccines */}
          <div className="Add-form-group">
            <div className="row1">
              <div className="col-md-121 ">
                <label>Vaccines</label>
              </div>

              <div className="row">
                {getVaccineByAge?.vaccines?.map((vaccine, i) => {
                  return (
                    <div className="col-md-4 mt-2" key={i}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => handleVaccineCheckboxChange(vaccine.id)} // Step 2
                        checked={selectedVaccines.includes(vaccine.id)} // Step 2
                        disabled={disable}
                      />
                      <label className="optionLabal">{vaccine.name}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="Add-form-group">
            <div className=" col-md-61">
              <label>Status</label>
              <span>*</span>
              <Switch
                disabled={disable}
                switchValue={values?.status}
                setFieldValue={setFieldValue}
              />
              {showError(errors.status, touched.status)}
            </div>
          </div>

          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {type !== "view" && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => {
          setSuccess(false);
          dispatch(getAllPatientListAction(0));
        }}
        message={` ${type === "add" ? "Added" : "Updated"} successfully!`}
        descMessage={`Patient has been ${
          type === "add" ? "added" : "updated"
        } successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddPatientForm;
