import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const getDoctorsAction = createAsyncThunk(
  "getDoctors",
  async (pageNumber) => {
    return await makeApiRequest(`/vac/api/user/allDoctors?page=${pageNumber}`, {
      token: getToken(),
    });
  }
);

const initialState = {
  doctors: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getDoctorsSlice = createSlice({
  name: "getDoctors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorsAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getDoctorsAction.fulfilled, (state, { payload }) => {
        state.doctors = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getDoctorsAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getDoctorsSlice.reducer;
