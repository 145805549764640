import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/WPMS.css";
import FollowUpDetails from "../../components/UI/wpms/FollowUpDetails";

const LandingPage = () => {
  const [showFollowUp, setShowFollowUp] = useState(false);
  return (
    <>
      <FollowUpDetails
        setIsOpen={setShowFollowUp}
        isOpen={showFollowUp}
        title="Follow Up Details"
        onClose={() => setShowFollowUp(false)}
      />
      <div className="doctor-wrapper pb-5">
        <div className="container container-padd">
          <div className="mid-head mt-2">
            <div className="Patient-heading mar-20">
              {/* <div>
                <Link to="/appointed-doctor" className=" btn-md btn-md-blue">
                  <img src="/icons-images/back-icon.svg" alt="avtar" />
                  Back
                </Link>
              </div> */}
              <div>
                <h2>
                  <b>Next Token Number</b>
                </h2>
              </div>
            </div>
          </div>
          <div className="row  pb-2">
            <div
              className="col-lg-4 mt-4"
              onClick={() => {
                setShowFollowUp(true);
              }}
            >
              <div className="DetailsoCard GreenBdr">
                <h2 className="TextColorGreen">01</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Saurabh Kainth</h3>
                <h4 className="TextColorBlack TextBgGreen">Status: IN</h4>
              </div>
            </div>

            <div
              className="col-lg-4 mt-4"
              onClick={() => {
                setShowFollowUp(true);
              }}
            >
              <div className="DetailsoCard YellowBdr">
                <h2 className="TextColorYellow">02</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Vipul Chaudhary</h3>
                <h4 className="TextColorBlack TextBgYellow ">Status: Ready</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard YellowBdr">
                <h2 className="TextColorYellow">03</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Pravez</h3>
                <h4 className="TextColorBlack TextBgYellow ">Status: Ready</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">04</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Asif Imam</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Waiting</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">05</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Vipul Chaudhary</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Ready</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">06</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Saurabh Kainth</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Waiting</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">07</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Vipul Chaudhary</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Waiting</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">08</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Vipul Chaudhary</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Waiting</h4>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="DetailsoCard blueBdr">
                <h2 className="TextColorBlue">09</h2>
                <p className="TextColorBlack">Token Number</p>
                <h3 className="TextColorBlack">Vipul Chaudhary</h3>
                <h4 className="TextColorWhite TextBgBlue">Status: Waiting</h4>
              </div>
            </div>
          </div>
          <div className="mid-head mar-20">
            <h2>Walkin Patient Management System</h2>
          </div>
          <div className="mid-table ">
            <table className="table MobileTable" cellSpacing="2" cellPadding="0" border="0">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Token Number</th>
                  <th scope="col">Appointment no.</th>
                  <th scope="col">Department</th>
                  <th scope="col">Department</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label='S.No'>01</td>
                  <td data-label='Patient Name'>Saurabh Kainth</td>
                  <td data-label='Token Number'>01</td>
                  <td data-label='Appointment no.'>1029304940</td>
                  <td data-label='Department'>Orthopedics</td>
                  <td data-label='Department'>Dr. Ashok Aggarwal</td>
                  <td data-label='Status' className="TdtextColorGreen">IN</td>
                </tr>
                <tr>
                  <td data-label='S.No'>02</td>
                  <td data-label='Patient Name'>Saurabh Kainth</td>
                  <td data-label='Token Number'>01</td>
                  <td data-label='Appointment no.'>1029304940</td>
                  <td data-label='Department'>Orthopedics</td>
                  <td data-label='Department'>Dr. Ashok Aggarwal</td>
                  <td data-label='Status' className="TdtextColorGreen">IN</td>
                </tr>

                <tr>
                  <td data-label='S.No'>03</td>
                  <td data-label='Patient Name'>Saurabh Kainth</td>
                  <td data-label='Token Number'>01</td>
                  <td data-label='Appointment no.'>1029304940</td>
                  <td data-label='Department'>Orthopedics</td>
                  <td data-label='Department'>Dr. Ashok Aggarwal</td>
                  <td data-label='Status' className="TdtextColorYellow">Ready</td>
                </tr>
                


                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
