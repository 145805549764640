import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest } from "../../../utils/utils";

export const updateHospitalAction = createAsyncThunk(
  "updateHospital",
  async ({ id, data }) => {
    return await makeApiRequest(`/vac/api/update-hospital?hospitalId=${id}`, {
      token: getToken(),
      data,
      method: "POST",
    });
  }
);

const initialState = {
  hospital: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const updateHospitalSlice = createSlice({
  name: "updateHospital",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHospitalAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(updateHospitalAction.fulfilled, (state, { payload }) => {
        state.hospital = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(updateHospitalAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        console.log("Error: ", error.message);
      });
  },
});

export default updateHospitalSlice.reducer;
