import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import AddBlog from "../../components/UI/blog/AddBlog";
import AddDoctor from "../../components/UI/doctor/AddDoctor";
import { useDispatch, useSelector } from "react-redux";
import { getVaccinesAction } from "../../redux/slice/common/getVaccinesSlice";
import { getUserByIdAction } from "../../redux/slice/users/getUserByIdSlice";
import { getDoctorsAction } from "../../redux/slice/doctor/getDoctorsSlice";
import { convertDateFormat } from "../../utils/utils";
import { userStatusAction } from "../../redux/slice/users/userStatusSlice";
import { deleteUserAction } from "../../redux/slice/users/deleteUserSlice";
import { searchDoctorAction } from "../../redux/slice/doctor/searchDoctorSlice";

const Doctor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateDoctor, setShowUpdateDoctor] = useState(false);
  const [showViewDoctor, setShowViewDoctor] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [doctor, setDoctor] = useState([]);
  const [message, setMessage] = useState("");

  const doctors = useSelector((state) => state.doctors);
  const { searchDoctor } = useSelector((state) => state);
  const deleteUser = useSelector((s) => s.deleteUser);
  const updateUser = useSelector((s) => s.updateUser);
  const addDoctor = useSelector((s) => s.addDoctor);

  const [searchFilters, setSearchFilters] = useState({
    name: "",
    status: "",
  });

  const handleInputChange = (fieldName, value) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };

  // Getting Vaccines
  useEffect(() => {
    dispatch(getVaccinesAction());
  }, []);

  // Getting & Updating Doctors list on Changes
  useEffect(() => {
    dispatch(getDoctorsAction(pageNumber));
  }, [
    deleteUser.success,
    addDoctor.success,
    updateUser.success,
    deleteUser.success,
    pageNumber,
  ]);

  const handleStatusChange = (value, id) => {
    dispatch(userStatusAction({ data: { status: value }, id }));

    setDoctor((doctorList) => {
      return {
        ...doctorList,
        dataItems: doctorList.dataItems.map((doctor) => {
          if (doctor.id === id) {
            return { ...doctor, status: value };
          }
          return doctor;
        }),
      };
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Check if either the name or status fields are not empty
    if (searchFilters.name.length >= 3 || searchFilters.status) {
      dispatch(searchDoctorAction({ ...searchFilters }));
      setMessage("");
    } else {
      setMessage(
        "Please enter/select at least one search field or at least 3 characters"
      );
      setDoctor(doctors.doctors);
    }
  };

  useEffect(() => {
    if (searchDoctor.success && (searchFilters.name || searchFilters.status)) {
      setDoctor(searchDoctor?.doctor);
    } else {
      setDoctor(doctors.doctors);
    }
  }, [searchDoctor.success, doctors.success]);

  const handleReset = () => {
    // setSearch(false);
    setDoctor(doctors.doctors);
    setSearchFilters({
      name: "",
      status: "",
    });
    setMessage("");
  };
  const startSerialNumber = pageNumber * 20 + 1;

  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2> </h2>
          </div>

          {/* <form> */}
          <form className="row mar-20" onSubmit={handleSearch}>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name/Email/Phone Number"
                  value={searchFilters.name}
                  onChange={(e) => {
                    handleInputChange("name", e.target.value);
                    !e.target.value && dispatch(getDoctorsAction());
                  }}
                />
              </div>
            </div>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <select
                  className="input-control"
                  value={searchFilters.status}
                  onChange={(e) => handleInputChange("status", e.target.value)}
                >
                  <option>Search by User Status</option>
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
            </div>

            <div className=" col-md-3">
              <div className="form-group ">
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {message && <div className="alert alert-danger">{message}</div>}
          </form>
          {/* </form> */}

          <div className="colBox ">
            <div className="mid-head">
              <h2>Doctor Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Doctor
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Hospital Name</th>
                    <th scope="col">Specialist</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {doctor?.dataItems?.length === 0 ? (
                    <p className="NoDatafound">No Data found!</p>
                  ) : (
                    doctor?.dataItems?.map((d, i) => (
                      <tr>
                        <td data-label="S.No">
                          {(startSerialNumber + i).toString().padStart(2, "0")}
                        </td>
                        <td data-label="Full Name">
                          {d.fname + " " + d.lname}
                        </td>
                        <td data-label="Email">{d.email || "NA"}</td>
                        <td data-label="Phone Number">
                          {d.phoneNumber || "NA"}
                        </td>
                        <td data-label="Hospital Name">
                          {(d.hospitalName !== "" &&
                            d.hospitalName?.map((v, i) => {
                              if (i === d.hospitalName?.length - 1) {
                                return <span>{v} </span>;
                              } else {
                                return <span>{v}, </span>;
                              }
                            })) ||
                            "NA"}
                        </td>
                        <td data-label="Specialist">{d.specialist || "NA"}</td>
                        <td data-label="Created At">
                          {convertDateFormat(d.createdAt)}
                        </td>

                        <td data-label="Status" className="tdGape">
                          <Switch
                            switchValue={d.status}
                            switchId={d.id}
                            handleChange={handleStatusChange}
                          />
                        </td>
                        <td data-label="Action">
                          <button
                            className="btn-small greenbg"
                            type="button"
                            onClick={() => {
                              setShowUpdateDoctor(true);
                              dispatch(getUserByIdAction(d.id));
                              setId(d.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            type="button"
                            className=" btn-small yellowbg"
                            onClick={() => {
                              setShowViewDoctor(true);
                              dispatch(getUserByIdAction(d.id));
                              setId(d.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            className=" btn-small redbg"
                            type="button"
                            onClick={() => {
                              setShowConfirm(true);
                              setId(d.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                              alt="icon"
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={doctor?.totalPage}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={doctor?.totalItems}
            noOfData={doctor?.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}

      {isOpen && (
        <AddDoctor
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsOpen={setIsOpen}
          title="Add Doctor"
          type="add"
        />
      )}
      {showUpdateDoctor && (
        <AddDoctor
          isOpen={showUpdateDoctor}
          onClose={() => setShowUpdateDoctor(false)}
          title="Update Doctor"
          setIsOpen={setShowUpdateDoctor}
          type="update"
          id={id}
        />
      )}
      {showViewDoctor && (
        <AddDoctor
          isOpen={showViewDoctor}
          onClose={() => setShowViewDoctor(false)}
          title="View Doctor"
          setIsOpen={setShowViewDoctor}
          type="view"
          id={id}
          disabled={true}
        />
      )}
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteUserAction(id));
        }}
      />
    </>
  );
};

export default Doctor;
