import React, { useEffect, useState } from "react";
import Success from "../../commonUI/Success";
import Switch from "../../commonUI/Switch";
import Select from "react-select";
import TextEditor from "../../commonUI/TextEditot";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addBlogAction } from "../../../redux/slice/blogs/addBlogSlice";
import { updateBlogAction } from "../../../redux/slice/blogs/updateBlogSlice";
import { showError } from "../../../utils/utils";
import { blogAddSchema, blogUpdateSchema } from "../../../utils/schema";
import * as Yup from "yup";
import { getAllActiveCategoryAction } from "../../../redux/slice/category/getAllActiveCategorySlice";

const AddBlogForm = ({ onClose, disable, type }) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [routeFlag, setRouteFlag] = useState(false);

  const initialValues = {
    imageBlog: "",
    name: "",
    description: "",
    languageType: "0",
    categoryId: null,
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    status: "0",
  };

  const dispatch = useDispatch();
  const addBlog = useSelector((state) => state.addBlog);
  const blogById = useSelector((state) => state.getBlogById);
  const getAllActiveCategory = useSelector(
    (state) => state.getAllActiveCategory
  );
  const updateBlog = useSelector((state) => state.updateBlog);

  const [blogValues, setBlogValues] = useState(initialValues);

  useEffect(() => {
    if (blogById.success) {
      if (type === "add") {
        setPreviewUrl("");
        setBlogValues(initialValues);
      } else {
        setPreviewUrl(blogById.blog?.imageBlog);
        setBlogValues(blogById.blog);
      }
    }
  }, [blogById.success, type]);

  // const validationSchema = () => {
  //   if (type === "add") {
  //     return blogAddSchema;
  //   } else if (type === "update") {
  //     return blogAddSchema;
  //   } else {
  //     return null;
  //   }
  // };

  useEffect(() => {
    if (addBlog.success && routeFlag) {
      setSuccess(true);
    } else if (updateBlog.success && routeFlag) {
      setSuccess(true);
    }
  }, [addBlog.success, updateBlog.success]);

  let validateForm = (values) => {
    let errors = {};

    if (values.description === "" || values.description == "<p><br></p>") {
      errors["description"] = "Please enter a description";
    }

    return errors;
  };
  const validationSchema = Yup.object().shape({
    categoryId: Yup.array()
      .min(1, "Please select at least one category")
      .of(
        Yup.string().test("isValidCategoryId", ({ value }) => {
          const availableCategoryIds = categoryOptions.map(
            (option) => option.value
          );

          return availableCategoryIds.includes(value)
            ? true
            : "Selected category is not valid. Please choose a valid category.";
        })
      )
      .required("Please select category"),
  });

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: blogValues,
    validate: validateForm,
    validationSchema:
      type === "add"
        ? blogAddSchema.concat(validationSchema)
        : blogUpdateSchema.concat(validationSchema),
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      const newValues = {
        ...values,
        name: values.name?.trim(),
        metaTitle: values.metaTitle?.trim(),
        metaDescription: values.metaDescription?.trim(),
        metaKeyword: values.metaKeyword?.trim(),
      };
      // Loop through the values object and append each key-value pair to the FormData
      for (const [key, value] of Object.entries(newValues)) {
        if (key === "categoryId") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
      if (type === "add") {
        dispatch(addBlogAction(formData));
      } else if (type === "update") {
        dispatch(updateBlogAction({ id: values.id, data: formData }));
      }
      setRouteFlag(true);
    },
  });

  const categoryOptions = getAllActiveCategory?.category?.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));

  useEffect(() => {
    dispatch(getAllActiveCategoryAction(values.languageType));
  }, [values.languageType]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                  disabled={disable}
                />
                {showError(errors.name, touched.name)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Category<span>*</span>{" "}
                </label>

                <Select
                  name="categoryId"
                  isDisabled={disable}
                  onChange={(selectedOptions) => {
                    setFieldValue(
                      "categoryId",
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                  options={categoryOptions}
                  isMulti
                  value={
                    values?.categoryId
                      ? categoryOptions.filter((option) =>
                          Array.isArray(values.categoryId)
                            ? values.categoryId.includes(option.value)
                            : values.categoryId === option.value
                        )
                      : []
                  }
                />
                {showError(errors.categoryId, touched.categoryId)}
              </div>
            </div>
          </div>

          {/* Email Gender */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Description<span>*</span>
                </label>
                <TextEditor
                  value={values?.description}
                  setFieldValue={setFieldValue}
                  disabled={disable}
                  handleBlur={() => setFieldTouched("description")}
                />
                {showError(errors.description, touched.description)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Language</label>
                <select
                  className="input-control"
                  name="languageType"
                  value={values?.languageType}
                  disabled={disable}
                  onChange={handleChange}
                >
                  <option value="0">English</option>
                  <option value="1">हिंदी</option>
                </select>
              </div>
              <div className=" col-md-6 ">
                <label>Meta Title</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaTitle"
                  placeholder="Meta title"
                  value={values?.metaTitle}
                  disabled={disable}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {showError(errors.metaTitle, touched.metaTitle)}
              </div>
            </div>
          </div>

          {/* phoneNumber roleType */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Meta Keywords</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaKeyword"
                  placeholder="Meta keywords"
                  value={values?.metaKeyword}
                  disabled={disable}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
                {showError(errors.metaKeyword, touched.metaKeyword)}
              </div>
              <div className=" col-md-6 ">
                <label>Meta Description</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaDescription"
                  placeholder="Meta description"
                  value={values?.metaDescription}
                  disabled={disable}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></textarea>
                {showError(errors.metaDescription, touched.metaDescription)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-3 ">
                <label>
                  Choose File<span>*</span>
                </label>
                <input
                  type="file"
                  name="imageBlog"
                  id="imageBlog"
                  tabIndex="1"
                  disabled={disable}
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    // Verify if the file is a valid image
                    if (file) {
                      setFieldValue("imageBlog", file);
                      setPreviewUrl(URL.createObjectURL(file));
                    } else {
                      // Handle the case when the selected file is not a valid image
                      setPreviewUrl(null);
                      setFieldValue("imageBlog", file);
                    }
                  }}
                  onBlur={handleBlur}
                />{" "}
                {showError(errors.imageBlog, touched.imageBlog)}
              </div>
              <div className=" col-md-3 ">
                {previewUrl && (
                  <div className="img">
                    <div className="squareImgPreview">
                      <img src={previewUrl} alt="profile" width="100" />
                    </div>
                  </div>
                )}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Status<span>*</span>
                </label>

                <Switch
                  disabled={disable}
                  switchValue={values?.status}
                  setFieldValue={setFieldValue}
                />
                {showError(errors.status, touched.status)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disable && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={` ${type === "add" ? "Added" : "Updated"} successfully`}
        descMessage={`Blog has been ${
          type === "add" ? "added" : "updated"
        }  successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddBlogForm;
