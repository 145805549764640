import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const searchPatientAction = createAsyncThunk(
  "searchPatient",
  async ({ name, status }) => {
    return await makeApiRequest(
      `/vac/api/user/search-by-patientList?searchValue=${name}&status=${status}`,
      {
        token: getToken(),
        apiKey: process.env.REACT_APP_API_KEY,
      }
    );
  }
);

const initialState = {
  patients: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const searchPatientSlice = createSlice({
  name: "searchPatient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchPatientAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(searchPatientAction.fulfilled, (state, { payload }) => {
        state.patients = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(searchPatientAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default searchPatientSlice.reducer;
