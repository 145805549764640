import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const updateBlogAction = createAsyncThunk(
  "updateBlog",
  async ({ id, data }) => {
    return await makeApiRequest(`/vac/api/blog/update-blog?blogId=${id}`, {
      token: getToken(),
      data,
      method: "POST",
    });
  }
);
{
  /* <Select
                  name="categoryId"
                  onChange={(selectedOptions) => {
                    setFieldValue("categoryId", selectedOptions?.map(option => option.value));
                  }}
                  options={categoryOptions}
                  isMulti
                  value={categoryOptions?.filter((option) => option.value==values?.categoryId)}
                /> */
}
const initialState = {
  blog: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const updateBlogSlice = createSlice({
  name: "updateBlog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBlogAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(updateBlogAction.fulfilled, (state, { payload }) => {
        state.blog = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(updateBlogAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default updateBlogSlice.reducer;
