import React from "react";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import Modal from "../../commonUI/Modal";
import AddHospitalForm from "./AddHospitalForm";

const AddHospital = ({ isOpen, setIsOpen, title, disabled, type }) => {
    const windowSize = useWindowSize();

    let left = windowSize > 768 ? "calc(50% - 400px)" : "2.5%";
    let width = windowSize > 768 ? "800px" : "95%";
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            style={{
                width,
                top: "10vh",
                height: "70vh",
                background: "white",
                left,
                padding: "1rem",
                overflow: "auto",
            }}
        >
            <div className="top">
                <div className="heading">
                    <h3>{title || "User Form"}</h3>
                </div>
            </div>
            <AddHospitalForm
                type={type}
                disable={disabled}
                onClose={() => setIsOpen(false)}
            />
        </Modal>
    )
}

export default AddHospital;