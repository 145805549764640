import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const getAllParentCategoryNameAction = createAsyncThunk(
  "getAllParentCategoryName",
  async (languageType) => {
    return await makeApiRequest(
      `/vac/api/category/getAllParentCategoryName?languageType=${languageType}`,
      {
        token: getToken(),
      }
    );
  }
);

const initialState = {
  category: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getAllParentCategoryNameSlice = createSlice({
  name: "getAllParentCategoryName",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllParentCategoryNameAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(
        getAllParentCategoryNameAction.fulfilled,
        (state, { payload }) => {
          state.category = payload.data;
          state.loading = false;
          state.success = true;
          state.error = "";
          state.message = payload.message;
        }
      )
      .addCase(getAllParentCategoryNameAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getAllParentCategoryNameSlice.reducer;
