import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import {
  areAllErrorsCleared,
  convertDateFormat,
  reverseDateFormat,
  showError,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addDoctorAction } from "../../../redux/slice/doctor/addDoctorSlice";
import { updateUserAction } from "../../../redux/slice/users/updateUserSlice";
import { addDoctorSchema, updateDoctorSchema } from "../../../utils/schema";
import { getHospitalClinicAction } from "../../../redux/slice/common/getHospitalClinicSlice";
import VaccineNamePriceRange from "./VaccineNamePriceRange";
import { getDoctorsAction } from "../../../redux/slice/doctor/getDoctorsSlice";
import Success from "../../commonUI/Success";
import { indianStates } from "../../../utils/data";

const options = [
  { value: "individual", label: "Individual" },
  { value: "clinic", label: "Clinic" },
  { value: "hospital", label: "Hospital" },
];

const weekDaysOption = [
  { value: "monday", label: "Monday", startTime: "", endTime: "" },
  { value: "tuesday", label: "Tuesday", startTime: "", endTime: "" },
  { value: "wednesday", label: "Wednesday", startTime: "", endTime: "" },
  { value: "thursday", label: "Thursday", startTime: "", endTime: "" },
  { value: "friday", label: "Friday", startTime: "", endTime: "" },
  { value: "saturday", label: "Saturday", startTime: "", endTime: "" },
  { value: "sunday", label: "Sunday", startTime: "", endTime: "" },
];
const initValues = {
  modeOfPractice: [],
  individualDays: [],
  clinicDays: [],
  hospitalDays: [],
  hospitalId: "",
  fname: "",
  lname: "",
  profileImg: "",
  dateOfBirth: "",
  email: "",
  phoneNumber: "",
  gender: "",
  address: "",
  country: "India",
  state: "",
  city: "",
  pincode: "",
  "role[]": "doctor",
  landmark: "",
  longitude: "",
  latitude: "",
  workingDayAndTime: null,
  vaccineNamePriceRange: [],
  specialist: "",
  noOfExperience: "",
  highestQualification: "",
  universityName: "",
  hospitalId: [],
};
//  Component
const AddDoctorForm = ({ onClose, disable, type, id }) => {
  const fileInputRef = useRef();
  const [workingDayAndTime, setWorkingDayAndTime] = useState({
    individual: {},
    clinic: {},
    hospital: {},
  });
  const [vaccineError, setVaccineError] = useState("");
  const [customError, setCustomError] = useState({});
  const [success, setSuccess] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [timeRangeError, settimeRangeError] = useState({});
  const [vaccinesSelected, setVaccinesSelected] = useState([]);
  const [initialValues, setInitialValues] = useState(initValues);
  const [closeFlag, setCloseFlag] = useState(false);
  const [modeOfPracticeChanged, setModeOfPracticeChanged] = useState(false);

  const dispatch = useDispatch();
  const userById = useSelector((state) => state.userById);
  const updateUser = useSelector((s) => s.updateUser);
  const addDoctor = useSelector((s) => s.addDoctor);
  const getHospitalClinic = useSelector((s) => s.getHospitalClinic);

  useEffect(() => {
    if ((updateUser.success || addDoctor.success) && closeFlag) {
      // onClose();
      setSuccess(true);
    }
  }, [updateUser.success, addDoctor.success]);

  // Setting Initialvalues
  useEffect(() => {
    if (userById.success && type !== "add") {
      let initValues = {
        ...userById.user,
        modeOfPractice: userById.user.red_practice_details[0]?.modeOfPractice,
        dateOfBirth: reverseDateFormat(
          userById.user?.dateOfBirth?.split("/")?.reverse().join("/")
        ),

        workingDayAndTime:
          userById.user.red_practice_details[0]?.workingDayAndTime,

        vaccineNamePriceRange:
          userById.user.red_practice_details[0]?.vaccineNamePriceRange || "[]",
        specialist: userById.user.red_practice_details[0]?.specialist,
        longitude: userById.user.red_practice_details[0]?.longitude,
        latitude: userById.user.red_practice_details[0]?.latitude,
        noOfExperience: userById.user.red_practice_details[0]?.noOfExperience,

        highestQualification:
          userById.user.red_practice_details[0]?.highestQualification,

        universityName:
          userById.user.red_practice_details[0]?.universityName || "",

        individualDays: Object.keys(
          userById.user.red_practice_details[0]?.workingDayAndTime
            ?.individual || {}
        )?.map((v) => ({ label: v, value: v })),
        clinicDays: Object.keys(
          userById.user.red_practice_details[0]?.workingDayAndTime?.clinic || {}
        )?.map((v) => ({ label: v, value: v })),

        // hospitalDays
        hospitalDays: Object.keys(
          userById.user.red_practice_details[0]?.workingDayAndTime?.hospital ||
            {}
        )?.map((v) => ({ label: v, value: v })),

        hospitalId: JSON.parse(userById.user?.hospitalId || "[]")?.map(
          (v, i) => ({
            value: v,
            label: userById.user?.hospitalName[i],
          })
        ),
      };
      const hospitals = getHospitalClinic.hospitalClinics?.map((h) => h.id);
      setFieldValue(
        "hospitalId",
        values.hospitalId.filter((v) => hospitals.includes(v))
      );

      setWorkingDayAndTime(
        userById.user.red_practice_details[0]?.workingDayAndTime
      );

      try {
        setVaccinesSelected(
          JSON.parse(
            userById.user.red_practice_details[0]?.vaccineNamePriceRange || "[]"
          )
        );
      } catch (error) {
        console.log(error.message);
      }
      setPreviewUrl(userById.user.profileImage);
      setInitialValues(initValues);
    }
  }, [userById.success]);

  // handling DayTime
  function handleworkingDayAndTime(modeOfPractice, day, timeType, value) {
    setWorkingDayAndTime((state) => ({
      ...state,
      [modeOfPractice]: {
        ...state?.[modeOfPractice],
        [day]: {
          ...state?.[modeOfPractice]?.[day],
          [timeType]: value,
        },
      },
    }));
  }

  // Validating time range
  function hasValidationErrors(validationErrors) {
    for (const organization in validationErrors) {
      if (Object.keys(validationErrors[organization]).length > 0) {
        return false; // If there are errors for any organization, return false
      }
    }
    return true; // No errors found for any organization
  }

  // Image Input Handle
  const handleFileChange = (event) => {
    // Pass the selected file to the parent component using the onChange callback
    const file = event.target.files[0];
    setFieldValue("profileImg", file);

    // Read and set the preview URL for image files
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl("./icons-images/avtar.svg");
    }
  };

  const validateForm = (values) => {
    const errors = {};

    const isIndividual = values.modeOfPractice?.some(
      (v) => v.value === "individual"
    );
    const isClinic = values.modeOfPractice?.some((v) => v.value === "clinic");
    const isHospital = values.modeOfPractice?.some(
      (v) => v.value === "hospital"
    );

    // Check if modeOfPractice is empty
    if (!isIndividual && !isClinic && !isHospital) {
      errors.modeOfPractice = "Please select at least one mode of practice";
    }

    // Validate the "clinicDays" field
    if (isClinic && values.clinicDays?.length === 0) {
      errors.clinic = "Clinic Days is/are required";
    } else {
      delete errors.clinic; // Clear the error
    }

    // Validate the "individualDays" field
    if (isIndividual && values.individualDays?.length === 0) {
      errors.individual = "Individual Days is/are required";
    } else {
      delete errors.individual; // Clear the error
    }

    if (isHospital && values.hospitalDays?.length === 0) {
      errors.hospital = "Hospital Days is/are required";
    }

    if ((isClinic || isHospital) && values.hospitalId?.length === 0) {
      errors["hospitalId"] = "Please select hospital/Clinic";
    }

    return errors;
  };

  // Handling form
  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    values,
    touched,
    errors,
    setFieldTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validate: validateForm,
    validationSchema: type === "add" ? addDoctorSchema : updateDoctorSchema,
    onSubmit: (values) => {
      const newValues = {
        // ...values,
        fname: values.fname,
        lname: values.lname,
        email: values.email,
        profileImg: values.profileImg,
        dateOfBirth: values.dateOfBirth,
        phoneNumber: values.phoneNumber,
        gender: values.gender,
        address: values.address,
        country: values.country,
        state: values.state,
        city: values.city,
        pincode: values.pincode,
        landmark: values.landmark,
        longitude: values.longitude,
        latitude: values.latitude,
        specialist: values.specialist,
        noOfExperience: values.noOfExperience,
        highestQualification: values.highestQualification,
        universityName: values.universityName,
        "role[]": "doctor",

        modeOfPractice: values.modeOfPractice,
        workingDayAndTime: workingDayAndTime,
        vaccineNamePriceRange: values.vaccineNamePriceRange,
        hospitalId: values.hospitalId,
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(newValues)) {
        if (key === "vaccineNamePriceRange") {
          formData.append(key, JSON.stringify(value));
        } else if (key === "modeOfPractice") {
          formData.append(key, JSON.stringify(value));
        } else if (key === "workingDayAndTime") {
          formData.append(key, JSON.stringify(value));
        } else if (key === "dateOfBirth") {
          formData.append(key, convertDateFormat(value));
        } else if (key === "hospitalId") {
          formData.append(key, JSON.stringify(value?.map((i) => i.value)));
        } else {
          formData.append(key, value);
        }
      }

      if (
        areAllErrorsCleared(customError) &&
        areAllErrorsCleared(vaccineError) &&
        hasValidationErrors(timeRangeError)
      ) {
        // sending values accordingly
        if (type === "add") {
          dispatch(addDoctorAction(formData));
        } else if (type === "update") {
          dispatch(updateUserAction({ id, data: formData }));
        }
        setCloseFlag(true);
      } else return;
    },
  });

  useEffect(() => {
    // Clone the current customError object to avoid mutating it directly
    const newCustomError = { ...customError };
    // Validate the "vaccineNamePriceRange" field
    let vaccineNamePriceRange;
    try {
      vaccineNamePriceRange = JSON.parse(values.vaccineNamePriceRange);
    } catch (error) {
      vaccineNamePriceRange = [];
    }

    vaccineNamePriceRange?.forEach((parent) => {
      if (parent.isParentSelected && !parent.children) {
        if (!parent.min || isNaN(parent.min)) {
          newCustomError[parent.id] =
            "Min Price is required, must be a valid number";
        } else if (!parent.max || isNaN(parent.max)) {
          newCustomError[parent.id] =
            "Max Price is required, must be a valid number";
        } else if (Number(parent.min) >= Number(parent.max)) {
          newCustomError[parent.id] = "Min Price must be less than Max Price";
        } else {
          newCustomError[parent.id] = null; // Clear the error
        }
      }
      parent.children?.forEach((child) => {
        if (child.isChildSelected) {
          if (!child.min || isNaN(child.min)) {
            newCustomError[child.id] =
              "Min Price is required, must be a valid number";
          } else if (!child.max || isNaN(child.max)) {
            newCustomError[child.id] =
              "Max Price is required, must be a valid number";
          } else if (Number(child.min) >= Number(child.max)) {
            newCustomError[child.id] = "Min Price must be less than Max Price";
          } else {
            newCustomError[child.id] = null; // Clear the error
          }
        } else {
          if (newCustomError.hasOwnProperty(child.id)) {
            delete newCustomError[child.id];
          }
        }
      });
    });

    const errorKeys = Object.keys(newCustomError);

    // Iterate over the error keys
    errorKeys.forEach((key) => {
      // Check if the key exists in values.vaccineNamePriceRange
      if (
        values.vaccineNamePriceRange &&
        !values.vaccineNamePriceRange.includes(key)
      ) {
        // If not present, remove the key from the newCustomError object
        delete newCustomError[key];
      }
    });

    values.vaccineNamePriceRange?.length && setVaccineError("");
    // Update the customError object
    setCustomError(newCustomError);
  }, [values.vaccineNamePriceRange, userById.success]);

  const vaccineNamePriceRangeProps = {
    disable,
    setVaccinesSelected,
    vaccinesSelected,
    setFieldValue,
  };

  useEffect(() => {
    // Define a function to validate the time range for a single day
    function validateTimeRange(startTime, endTime) {
      // Check if both startTime and endTime are provided
      if (!startTime || !endTime) {
        return true; // Skip validation if either is missing
      }

      const startTimeParts = startTime.split(":");
      const endTimeParts = endTime.split(":");

      const startHour = parseInt(startTimeParts[0]);
      const startMinute = parseInt(startTimeParts[1]);

      const endHour = parseInt(endTimeParts[0]);
      const endMinute = parseInt(endTimeParts[1]);

      // Compare the start and end times
      if (
        startHour > endHour ||
        (startHour === endHour && startMinute >= endMinute)
      ) {
        return false; // Invalid time range
      }
      return true; // Valid time range
    }

    // Validate start and end times for individual, clinic, and hospital
    const organizations = ["individual", "clinic", "hospital"];

    const validationErrors = {};

    organizations.forEach((organization) => {
      const selectedDays = Object.keys(workingDayAndTime[organization]);

      selectedDays.forEach((day) => {
        const { startTime, endTime } = workingDayAndTime[organization][day];
        if (!validateTimeRange(startTime, endTime)) {
          if (!validationErrors[organization]) {
            validationErrors[organization] = {};
          }
          validationErrors[organization][day] =
            "Start time must be before end time";
        } else {
          // Remove the error property for this day if validation passes
          if (
            validationErrors[organization] &&
            validationErrors[organization][day]
          ) {
            delete validationErrors[organization][day];

            // If there are no more errors for this organization, you can remove it too
            if (Object.keys(validationErrors[organization]).length === 0) {
              delete validationErrors[organization];
            }
          }
        }
      });
    });
    settimeRangeError(validationErrors);
  }, [workingDayAndTime]);

  useEffect(() => {
    if (modeOfPracticeChanged) {
      const hospitals = getHospitalClinic.hospitalClinics?.map((h) => h.id);
      setFieldValue(
        "hospitalId",
        values.hospitalId.filter((v) => hospitals.includes(v))
      );
    }
  }, [values.modeOfPractice]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="ModalAvtar">
          <img
            src={previewUrl || "./icons-images/avtar.svg"}
            alt="icon"
            onClick={() => fileInputRef.current.click()}
          />
        </div>
        {errors.profileImg && touched.profileImg && (
          <div className="error-msg text-center mt-2">{errors.profileImg}</div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          disabled={disable}
        />
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  First Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="First Name"
                  name="fname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.fname}
                  disabled={disable}
                />
                {showError(errors.fname, touched.fname)}
              </div>

              <div className=" col-md-6 ">
                <label>Last Name</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Last Name"
                  name="lname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lname}
                  disabled={disable}
                />
                {showError(errors.lname, touched.lname)}
              </div>
            </div>
          </div>

          {/* Email PhoneNumber */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Email Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  disabled={type !== "add"}
                />{" "}
                {showError(errors.email, touched.email)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.phoneNumber}
                  disabled={type !== "add"}
                />{" "}
                {showError(errors.phoneNumber, touched.phoneNumber)}
              </div>
            </div>
          </div>

          {/* modeOfPractice */}
          <div className="Add-form-group">
            <label>
              Mode of Practice<span>*</span>
            </label>
            <Select
              name="modeOfPractice"
              value={values.modeOfPractice}
              onChange={(val) => {
                setModeOfPracticeChanged(true);
                setFieldValue("modeOfPractice", val);
                if (
                  val?.some((v) => v.value === "clinic") &&
                  val?.some((v) => v.value === "hospital")
                ) {
                  dispatch(getHospitalClinicAction("all"));
                } else if (val?.some((v) => v.value === "clinic")) {
                  dispatch(getHospitalClinicAction("1"));
                } else if (val?.some((v) => v.value === "hospital")) {
                  dispatch(getHospitalClinicAction("2"));
                }
              }}
              onBlur={() => setFieldTouched("modeOfPractice")}
              options={options}
              isMulti
              isDisabled={disable}
            />
            {touched.modeOfPractice &&
              showError(
                errors.modeOfPractice,
                values?.modeOfPractice?.length === 0
              )}
          </div>

          {/* individualDays */}
          {values.modeOfPractice?.some((v) => v.value === "individual") && (
            <div className="Add-form-group">
              <label>
                Individual Days<span>*</span>
              </label>
              <Select
                name="individualDays"
                value={values.individualDays}
                onChange={(selectedOption) => {
                  setFieldValue("individualDays", selectedOption);
                }}
                onBlur={() => setFieldTouched("individualDays")}
                options={weekDaysOption}
                isMulti
                isDisabled={disable}
              />
              {showError(errors.individual, touched.individualDays)}

              {values.individualDays?.map((day, index) => (
                <div key={index}>
                  <h3 className="H3Head">Individual - {day.label}</h3>
                  <div className="row">
                    <div className="col-4">
                      <label className="Doclabel">Start Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "individual",
                            day.value,
                            "startTime",
                            e.target.value
                          )
                        }
                        value={
                          workingDayAndTime?.individual[day.value]?.startTime
                        }
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`individual.${day.value}.startTime`}
                      />
                      <div className="error-msg">
                        {timeRangeError?.individual?.[day.value]}
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="Doclabel">End Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "individual",
                            day.value,
                            "endTime",
                            e.target.value
                          )
                        }
                        value={
                          workingDayAndTime?.individual[day.value]?.endTime
                        }
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`individual-${day.value}-endTime`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* ClinicDays */}
          {values.modeOfPractice?.some((v) => v.value === "clinic") && (
            <div className="Add-form-group">
              <label>
                Clinic Days<span>*</span>
              </label>
              <Select
                name="clinicDays"
                value={values.clinicDays}
                onChange={(selectedOption) => {
                  setFieldValue("clinicDays", selectedOption);
                }}
                onBlur={() => setFieldTouched("clinicDays")}
                options={weekDaysOption}
                isMulti
                isDisabled={disable}
              />
              {showError(errors.clinic, touched.clinicDays)}
              {values.clinicDays?.map((day, index) => (
                <div key={index}>
                  <h3 className="H3Head">Clinic - {day.label}</h3>

                  <div className="row">
                    <div className="col-4">
                      <label className="Doclabel">Start Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "clinic",
                            day.value,
                            "startTime",
                            e.target.value
                          )
                        }
                        value={workingDayAndTime?.clinic[day.value]?.startTime}
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`clinic-${day.value}-startTime`}
                      />
                      <div className="error-msg">
                        {timeRangeError?.clinic?.[day.value]}
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="Doclabel">End Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "clinic",
                            day.value,
                            "endTime",
                            e.target.value
                          )
                        }
                        value={workingDayAndTime?.clinic[day.value]?.endTime}
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`clinic-${day.value}-endTime`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* hospitalDays */}
          {values.modeOfPractice?.some((v) => v.value === "hospital") && (
            <div className="Add-form-group">
              <label>
                Hospital Days<span>*</span>
              </label>
              <Select
                name="hospitalDays"
                value={values.hospitalDays}
                onChange={(selectedOption) => {
                  setFieldValue("hospitalDays", selectedOption);
                }}
                onBlur={() => setFieldTouched("hospitalDays")}
                options={weekDaysOption}
                isMulti
                isDisabled={disable}
                disabled={disable}
              />
              {showError(errors.hospital, touched.hospitalDays)}
              {values.hospitalDays?.map((day, index) => (
                <div key={index}>
                  <h3 className="H3Head">Hospital - {day.label}</h3>
                  <div className="row">
                    <div className="col-4">
                      <label className="Doclabel">Start Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "hospital",
                            day.value,
                            "startTime",
                            e.target.value
                          )
                        }
                        value={
                          workingDayAndTime?.hospital[day.value]?.startTime
                        }
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`hospital.${day.value}.startTime`}
                      />
                      <div className="error-msg">
                        {timeRangeError?.hospital?.[day.value]}
                      </div>
                    </div>
                    <div className="col-4">
                      <label className="Doclabel">End Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleworkingDayAndTime(
                            "hospital",
                            day.value,
                            "endTime",
                            e.target.value
                          )
                        }
                        value={workingDayAndTime?.hospital[day.value]?.endTime}
                        disabled={disable}
                        onBlur={handleBlur}
                        name={`hospital.${day.value}.endTime`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* HospitalId */}
          {(values.modeOfPractice?.some((v) => v.value === "clinic") ||
            values.modeOfPractice?.some((v) => v.value === "hospital")) && (
            <div className="Add-form-group">
              <label>
                Hospital/Clinic<span>*</span>
              </label>
              <Select
                name="hospitalId"
                value={values.hospitalId}
                onChange={(val) => {
                  setFieldValue("hospitalId", val);
                }}
                onBlur={() => setFieldTouched("hospitalId")}
                options={getHospitalClinic.hospitalClinics?.map((h) => ({
                  value: h.id,
                  label: h.name,
                }))}
                isMulti
                isDisabled={disable}
              />{" "}
              {showError(errors.hospitalId, touched.hospitalId)}
            </div>
          )}

          {/* Specialist and Experience */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Specialist<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Specialist"
                  name="specialist"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.specialist}
                  disabled={disable}
                />
                {showError(errors.specialist, touched.specialist)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Number of Experience<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="noOfExperience"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.noOfExperience}
                  disabled={disable}
                >
                  <option>Select</option>
                  {(function expDropdown() {
                    let array = [];
                    for (let i = 1; i <= 20; i++) {
                      array.push(i);
                    }
                    return array;
                  })().map((i) => (
                    <option value={i}>{i} year</option>
                  ))}
                </select>
                {showError(errors.noOfExperience, touched.noOfExperience)}
              </div>
            </div>
          </div>

          {/* Education and Highest Qualification */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>University Name</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Education"
                  name="universityName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.universityName}
                  disabled={disable}
                />

                {showError(errors.universityName, touched.universityName)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Highest Qualification<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Highest Qualification"
                  name="highestQualification"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.highestQualification}
                  disabled={disable}
                />
                {showError(
                  errors.highestQualification,
                  touched.highestQualification
                )}
              </div>
            </div>
          </div>

          {/* gender DateofBirth */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Gender</label>
                <select
                  className="input-control"
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                  disabled={disable}
                >
                  <option>Select</option>
                  <option value="0">Male</option>
                  <option value="1">Female</option>
                  <option value="2">Other</option>
                </select>
              </div>

              <div className=" col-md-6 ">
                <label>Date of Birth</label>
                <input
                  type="date"
                  className="input-control"
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.dateOfBirth}
                  disabled={disable}
                />
                {showError(errors.dateOfBirth, touched.dateOfBirth)}
              </div>
            </div>
          </div>

          {/* dateOfBirth Home Address */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>Address</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.address}
                  disabled={disable}
                />
                {showError(errors.address, touched.address)}
              </div>
            </div>
          </div>

          {/* City State */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>City</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.city}
                  disabled={disable}
                />
                {showError(errors.city, touched.city)}
              </div>

              <div className=" col-md-6 ">
                <label>State</label>
                <Select
                  name="state"
                  isDisabled={type === "view"}
                  onChange={(selectedOptions) => {
                    setFieldValue("state", selectedOptions.value);
                  }}
                  options={indianStates.map((s) => ({ label: s, value: s }))}
                  value={{
                    value: values?.state || null,
                    label: values?.state || "Select...",
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "44px",
                    }),
                  }}
                />
                {showError(errors.state, touched.state)}
              </div>
            </div>
          </div>

          {/* Country Pincode */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Country</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Country"
                  name="country"
                  value="India"
                  disabled={true}
                />
              </div>

              <div className=" col-md-6 ">
                <label>Pin Code</label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.pincode}
                  disabled={disable}
                />{" "}
                {showError(errors.pincode, touched.pincode)}
              </div>
            </div>
          </div>

          {/* Longitude */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Latitude<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Latitude"
                  name="latitude"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.latitude}
                  disabled={disable}
                />
                {showError(errors.latitude, touched.latitude)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Longitude<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Longitude"
                  name="longitude"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.longitude}
                  disabled={disable}
                />
                {showError(errors.longitude, touched.longitude)}
              </div>
            </div>
          </div>

          {/* Vaccines */}

          <VaccineNamePriceRange
            {...vaccineNamePriceRangeProps}
            errors={customError}
            vaccineError={vaccineError}
          />

          {/* {showError(errors.vaccineNamePriceRange, touched.vaccineNamePriceRange)} */}

          {/* Similar code for Clinic and Hospital Time Slots */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disable && (
              <button
                type="submit"
                onClick={() => {
                  try {
                    var myArray = JSON.parse(values.vaccineNamePriceRange);
                  } catch (error) {
                    console.log(error.message);
                  }
                  if (Array.isArray(myArray) && myArray.length === 0) {
                    setVaccineError("Please select atleast one vaccine");
                  }
                }}
                className="btn btn-primary-big big-btn-padd"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => {
          setSuccess(false);
          dispatch(getDoctorsAction(0));
        }}
        message={` ${type === "add" ? "Added" : "Updated"} successfully!`}
        descMessage={`Doctor has been ${
          type === "add" ? "added" : "updated"
        } successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddDoctorForm;
