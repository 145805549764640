import React from "react";
import Modal from "../../commonUI/Modal";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import MyCalendar from "../MyCalendar";

const MakeAppointment = (props) => {
  const windowSize = useWindowSize();

  let left = windowSize > 1024 ? "calc(50% - 30%)" : "2.5%";
  let width = windowSize > 1024 ? "60%" : "100%";
  return (
    <Modal
      {...props}
      style={{
        width,
        top: "7vh",
        height: "85vh",
        background: "white",
        left,
        padding: "1rem",
        overflow: "auto",
      }}
    >
      <div className="container container-padd">
        <div className="mid-head mar-20">
          <h2 className="text-center">Recordified Department </h2>
        </div>

        <form>
          <div className="row mar-20">
            <div className=" col-md-4">
              <div className="form-group ">
                <select className="input-control">
                  <option>Search Doctor Name</option>
                </select>
              </div>
            </div>

            <div className=" col-md-4 ">
              <div className="form-group ">
                <select className="input-control">
                  <option>Select Patient Name</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row mar-20">
            <div className=" col-md-6 ">
              <div className="form-group ">
                <div className="mid-head ">
                  <h2 className="blacktext">Select Date</h2>
                </div>
                <div className="col-md-10 card p-3">
                  <MyCalendar />
                </div>
              </div>
            </div>

            <div className=" col-md-6 ">
              <div className="form-group ">
                <div className="mid-head mar-30">
                  <h2 className="blacktext">Select Time</h2>
                  <div className="buttons mt-3">
                    <button className=" btn-time active" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                    <button className=" btn-time" type="button">
                      9:00 am
                    </button>
                  </div>
                </div>

                <div className="notitifiation">
                  <div className="text">
                    <img
                      src={`${process.env.PUBLIC_URL}/icons-images/notification-icon.svg`}
                      alt="icon"
                    />
                    All Times are in IST Time ( India)
                  </div>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/icons-images/arrow-note.svg`}
                      alt="icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mar-20 align-center ">
              {" "}
              <button className=" btn-time Appiontment-width" type="button">
                Create Appoinment
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MakeAppointment;
