import React from "react";
import Modal from "../../commonUI/Modal";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import AddPatientForm from "./AddPatientForm";

const AddPatient = ({
  isOpen,
  setIsOpen,
  title,
  disabled,
  type,
  fetchPatients,
}) => {
  const windowSize = useWindowSize();

  let left = windowSize > 768 ? "calc(50% - 400px)" : "2.5%";
  let width = windowSize > 768 ? "800px" : "95%";
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      style={{
        width,
        top: "10vh",
        height: "70vh",
        background: "white",
        left,
        padding: "1rem",
        overflow: "auto",
      }}
    >
      <div className="top">
        <div className="heading">
          <h3>{title || "Form"}</h3>
        </div>
      </div>

      <AddPatientForm
        disable={disabled}
        type={type}
        onClose={() => setIsOpen(false)}
        fetchPatients={fetchPatients}
      />
    </Modal>
  );
};

export default AddPatient;
