import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const getCMSByIdAction = createAsyncThunk("getCMSById", async (id) => {
  return await makeApiRequest(`/api/cms/viewById?cmsId=${id}`, {
    token: getToken(),
  });
});

const initialState = {
  cms: {},
  loading: false,
  error: "",
  message: "",
  success: false,
};

const getCMSByIdSlice = createSlice({
  name: "getCMSById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCMSByIdAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(getCMSByIdAction.fulfilled, (state, { payload }) => {
        state.cms = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(getCMSByIdAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default getCMSByIdSlice.reducer;
