import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, makeApiRequest, notify } from "../../../utils/utils";

export const searchAppointmentAction = createAsyncThunk(
  "searchAppointment",
  async ({ appointmentDate, doctorId }) => {
    return await makeApiRequest(
      `/vac/api/get-appointment-by-date-and-doctorId?appointmentDate=${appointmentDate}&doctorId=${doctorId}`,
      {
        token: getToken(),
        apiKey: process.env.REACT_APP_API_KEY,
      }
    );
  }
);

const initialState = {
  appointment: [],
  loading: false,
  error: "",
  message: "",
  success: false,
};

const searchAppointmentSlice = createSlice({
  name: "searchAppointment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchAppointmentAction.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.success = false;
      })
      .addCase(searchAppointmentAction.fulfilled, (state, { payload }) => {
        state.appointment = payload.data;
        state.loading = false;
        state.success = true;
        state.error = "";
        state.message = payload.message;
      })
      .addCase(searchAppointmentAction.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
        state.success = false;
        state.message = error.message;
        notify(error.message, "error");
        console.log("Error: ", error.message);
      });
  },
});

export default searchAppointmentSlice.reducer;
