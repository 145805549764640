import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdAction } from "../../redux/slice/users/getUserByIdSlice";
import { userStatusAction } from "../../redux/slice/users/userStatusSlice";
import { deleteUserAction } from "../../redux/slice/users/deleteUserSlice";
import { searchUserAction } from "../../redux/slice/users/searchUserSlice";
import AddParent from "./AddParent";
import {
  convertToCSV,
  getToken,
  makeApiRequest,
  notify,
} from "../../utils/utils";

const Parent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [renderFlag, setRenderFlag] = useState({
    addUser: false,
    updateUser: false,
  });
  const [showUpdateDoctor, setShowUpdateDoctor] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [parentsList, setParentsList] = useState({ dataItems: [] });
  const [deleteUserId, setDeleteUserId] = useState();
  const [clickedUser, setClickedUser] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const { deleteUser, searchUser } = useSelector((state) => ({
    deleteUser: state.deleteUser,
    searchUser: state.searchUser,
  }));

  // Getting parents list
  const fetchParents = async () => {
    const result = await makeApiRequest(
      `/vac/api/all-parent-user-list-admin?page=${pageNumber || 0}`,
      {
        method: "GET",
        token: getToken(),
      }
    );
    setParentsList(result.data);
  };

  useEffect(() => {
    fetchParents();
  }, [
    pageNumber,
    renderFlag.addUser,
    renderFlag.updateUser,
    deleteUser.success,
  ]);

  const startSerialNumber = pageNumber * 20 + 1;

  // Changing status of user
  const handleStatusChange = (value, id) => {
    dispatch(userStatusAction({ data: { status: value }, id }));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    // Check if either the name or status fields are not empty
    if (searchKey.length >= 3) {
      // dispatch(searchUserAction({ ...searchFilters }));
      try {
        const result = await makeApiRequest(
          `/vac/api/search-patient-for-admin?searchValue=${searchKey}`,
          { method: "GET", token: getToken() }
        );
        setParentsList(result.data);
      } catch (error) {
        console.log(error.message);
        notify(error.message, "error");
      }

      setMessage("");
    } else {
      setMessage("Please enter at least 3 characters");
    }
  };

  useEffect(() => {
    if (searchUser.success) {
      setParentsList(searchUser?.user);
    }
  }, [searchUser.success]);

  const handleReset = () => {
    // setSearch(false);
    setSearchKey("");
    setMessage("");
    fetchParents();
  };

  // Getting User by id
  async function getUserById(id, type) {
    const result = await makeApiRequest(
      `/vac/api/get-parent-user-list-by-id-admin?parentId=${id}`,
      { token: getToken() }
    );
    setClickedUser(result.data);
    if (type === "update" && result.status) {
      setShowUpdateDoctor(true);
    } else if (type === "view" && result.status) {
      setViewUser(true);
    }
  }

  async function handleCSV() {
    if (searchKey?.length < 3) {
      return "";
    }
    const result = await makeApiRequest(
      `/vac/api/search-patient-for-admin-csv-file?searchValue=${searchKey}`,
      {
        method: "GET",
        token: getToken(),
      }
    );

    if (searchKey && result.data && result.data?.length > 0) {
      const csvContent = convertToCSV(
        result.data.map((data) => ({
          ...data,
          status: data.status === "1" ? "Active" : "Inactive",
          agreeTermAndPriv:
            data.agreeTermAndPriv === "1" ? "Agreed" : "Disagreed",
        })),
        [
          "Id",
          "Parent Name",
          "Relation",
          "Phone Number",
          "Email",
          "Address",
          "City",
          "State",
          "Pin code",
          "Agreed Terms & Condtions",
          "status",
        ]
      );
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "Parents-List.csv";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2></h2>
          </div>

          {/* search */}
          {/* <form> */}
          <form className="row mar-20" onSubmit={handleSearch}>
            <div className=" col-md-2 input-width">
              <div className="form-group ">
                <input
                  type="text"
                  className="input-control"
                  placeholder="Name/Email/Phone Number"
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                    !e.target.value && fetchParents();
                  }}
                />
              </div>
            </div>

            <div className=" col-md-6">
              <div className="form-group ">
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>

                <button
                  to="/doctors/add-doctor"
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleCSV}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                    alt="icon"
                  />
                  CSV
                </button>

                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {message && <div className="alert alert-danger">{message}</div>}
          </form>
          {/* </form> */}
          <div className="colBox">
            <div className="mid-head">
              <h2>Parent Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Parent
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile No.</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {parentsList?.dataItems?.length === 0 ? (
                    <p className="NoDatafound">No Data found!</p>
                  ) : (
                    parentsList?.dataItems?.map((user, index) => {
                      const serialNumber = (startSerialNumber + index)
                        .toString()
                        .padStart(2, "0");

                      return (
                        <tr key={index}>
                          <td data-label="S.No">{serialNumber}</td>
                          <td data-label="Name">
                            {user.parentName?.length > 20
                              ? user.parentName.substring(0, 20) + "..."
                              : user.parentName || "NA"}
                          </td>
                          <td data-label="Email">{user.email}</td>
                          <td data-label="Mobile No.">{user.phoneNumber}</td>
                          <td data-label="Status" className="tdGape">
                            <Switch
                              switchValue={user.status}
                              switchId={user.id}
                              handleChange={handleStatusChange}
                            />
                          </td>
                          <td data-label="Action">
                            <button
                              className=" btn-small greenbg"
                              type="button"
                              onClick={() => {
                                getUserById(user.id, "update");
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small yellowbg"
                              onClick={() => {
                                setViewUser(true);
                                getUserById(user.id, "view");
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small redbg"
                              type="submit"
                              onClick={() => {
                                setShowConfirm(true);
                                setDeleteUserId(user.id);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                                alt="icon"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          }
          {parentsList?.totalPage > 0 && (
            <Pagination
              totalPages={parentsList?.totalPage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalItems={parentsList?.totalItems}
              noOfData={parentsList?.dataItems?.length}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <AddParent
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsOpen={setIsOpen}
          title="Add Parent"
          setRenderFlag={setRenderFlag}
          renderFlag={renderFlag}
          fetchParents={fetchParents}
          type="add"
        />
      )}

      {showUpdateDoctor && (
        <AddParent
          isOpen={showUpdateDoctor}
          onClose={() => setShowUpdateDoctor(false)}
          title="Update Parent"
          setIsOpen={setShowUpdateDoctor}
          disabled={false}
          setRenderFlag={setRenderFlag}
          renderFlag={renderFlag}
          type="update"
          user={clickedUser}
          fetchParents={fetchParents}
        />
      )}

      {viewUser && (
        <AddParent
          isOpen={viewUser}
          onClose={() => setViewUser(false)}
          title="View Parent"
          setIsOpen={setViewUser}
          disabled={true}
          setRenderFlag={setRenderFlag}
          renderFlag={renderFlag}
          type="view"
          user={clickedUser}
        />
      )}

      {showConfirm && (
        <Confirm
          isOpen={showConfirm}
          onClose={() => setShowConfirm(false)}
          handleConfirm={() => {
            dispatch(deleteUserAction(deleteUserId));
          }}
        />
      )}
    </>
  );
};

export default Parent;
