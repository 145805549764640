import React from "react";
import { useSelector } from "react-redux";
import { organizeVaccines } from "../../../utils/utils";
import { useState } from "react";
import { useEffect } from "react";

const VaccineNamePriceRange = ({
  disable,
  setVaccinesSelected,
  vaccinesSelected,
  setFieldValue,
  vaccineError,
  errors,
}) => {
  const allVaccines = useSelector((s) => s.allVaccines);
  const [newVaccines, setNewVaccines] = useState([]);

  useEffect(() => {
    const newVaccines = organizeVaccines(allVaccines.vaccines).map(
      (parent) => ({
        ...parent,
        isParentSelected: false,
        subCategory: parent?.children?.map((child) => ({
          ...child,
          isChildSelected: false,
        })),
      })
    );
    setNewVaccines(newVaccines);
  }, [allVaccines.success]);

  // handling Vaccine
  function handleVaccinesSelected(id, value, minMax, isParent, parentId) {
    if (isParent) {
      setVaccinesSelected((state) =>
        state.map((v) => {
          if (v.id == id) {
            return { ...v, [minMax]: value };
          } else {
            return v;
          }
        })
      );
    } else {
      setVaccinesSelected((state) =>
        state.map((v) => {
          if (v.id == parentId) {
            return {
              ...v,
              subCategory: v?.subCategory?.map((vchild) => {
                if (vchild.id == id) {
                  return { ...vchild, [minMax]: value };
                } else {
                  return vchild;
                }
              }),
            };
          } else {
            return v;
          }
        })
      );
    }
  }

  // Restructuring selected category data to send to db
  useEffect(() => {
    const newvaccinesSelected = vaccinesSelected
      ?.filter(
        (parent) =>
          parent.isParentSelected === true ||
          parent?.subCategory?.find((child) => child.isChildSelected == true)
      )
      ?.map((parent) => {
        return {
          ...parent,
          subCategory: parent.subCategory?.filter(
            (child) => child.isChildSelected == true
          ),
        };
      })
      ?.map((parent) => {
        return {
          ...parent,
          parentCategoryId: parent.id,
          children: parent?.subCategory?.map((child) => {
            return { ...child, childCategoryId: child.id };
          }),
        };
      });
    // setVaccinesSelected(newvaccinesSelected);
    setFieldValue &&
      setFieldValue(
        "vaccineNamePriceRange",
        JSON.stringify(newvaccinesSelected)
      );
  }, [vaccinesSelected]);

  // Taking inputs from parent Click
  const handleParentClick = (value, state, parentId) => {
    const parsedValue = JSON.parse(value);
    setVaccinesSelected((lastState) => {
      if (state) {
        if (!lastState?.find((cat) => cat.id == parsedValue.id)) {
          return [
            ...lastState,
            {
              ...parsedValue,
              isParentSelected: true,
              subCategory: parsedValue?.subCategory?.map((child) => ({
                ...child,
                isChildSelected: true,
              })),
            },
          ];
        } else {
          return lastState?.map((cat) => {
            if (cat.id == parsedValue.id) {
              return {
                ...parsedValue,
                isParentSelected: true,
                subCategory: parsedValue?.subCategory?.map((child) => ({
                  ...child,
                  isChildSelected: true,
                })),
              };
            } else {
              return cat;
            }
          });
        }
      } else {
        // Uncheck the parent, clear min and max values for child components
        return lastState.map((cat) => {
          if (cat.id == parsedValue.id) {
            return {
              ...cat,
              isParentSelected: false,
              min: "", // Clear min value
              max: "", // Clear max value
              subCategory: cat.subCategory?.map((child) => ({
                ...child,
                isChildSelected: false,
                min: "", // Clear min value
                max: "", // Clear max value
              })),
            };
          }
          return cat;
        });
      }
    });
  };

  // Taking inputs from child click
  const handleChildClick = (value, state, parentId, childId) => {
    const parsedValue = JSON.parse(value);
    console.log(state, "child clicked");
    setVaccinesSelected((lastState) => {
      if (state) {
        if (!lastState?.find((cat) => cat.id == parsedValue.id)) {
          return [
            ...lastState,
            {
              ...parsedValue,
              subCategory: [
                {
                  ...parsedValue?.subCategory[0],
                  isChildSelected: true,
                },
              ],
            },
          ];
        } else {
          return lastState?.map((cat) => {
            if (cat.id == parsedValue.id) {
              if (cat.subCategory.find((child) => child.id == childId)) {
                return {
                  ...parsedValue,
                  subCategory: cat.subCategory.map((child) => {
                    if (child.id == childId) {
                      return { ...child, isChildSelected: true };
                    } else {
                      return child;
                    }
                  }),
                };
              } else {
                return {
                  ...parsedValue,
                  subCategory: [
                    ...cat.subCategory,
                    { ...parsedValue.subCategory[0], isChildSelected: true },
                  ],
                };
              }
            } else {
              return cat;
            }
          });
        }
      } else {
        // Uncheck the child, clear its min and max values
        return lastState.map((cat) => {
          if (cat.id == parsedValue.id) {
            return {
              ...cat,
              subCategory: cat.subCategory?.map((child) => {
                if (child.id == childId) {
                  return {
                    ...child,
                    isChildSelected: false,
                    min: "", // Clear min value
                    max: "", // Clear max value
                  };
                } else {
                  return child;
                }
              }),
            };
          }
          return cat;
        });
      }
    });
  };

  return (
    <div className="Add-form-group">
      <div className="row">
        <div className="col-md-12 ">
          <label>
            Vaccines<span>*</span>
            <div className="error-msg">{vaccineError}</div>
          </label>
        </div>
        {/* repeat */}
        {newVaccines?.map((parent, index) => {
          return (
            <div
              className="col-md-12 mb-3 mt-1 "
              style={{ background: "whitesmoke" }}
              key={index}
            >
              <div className="row">
                <div className="col-md-12 pt-1" style={{ background: "#eee" }}>
                  <div className="col-md-12 mb-1 mt-1">
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <input
                          type="checkbox"
                          className="checkbox "
                          tabIndex="3"
                          checked={
                            vaccinesSelected?.find(
                              (cat) => cat?.id == parent?.id
                            )?.isParentSelected == true
                          }
                          value={JSON.stringify(parent)}
                          name={parent.id}
                          id={parent.id}
                          onChange={(e) =>
                            handleParentClick(
                              e.target.value,
                              e.target.checked,
                              parent.id
                            )
                          }
                          disabled={disable || false}
                        />
                        <label className="optionLabal">{parent.name}</label>
                      </div>
                      {!parent.subCategory && (
                        <>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="input-control  mb-2"
                              placeholder="Min Price"
                              name="Min Price"
                              onChange={(e) =>
                                handleVaccinesSelected(
                                  parent.id,
                                  e.target.value,
                                  "min",
                                  true
                                )
                              }
                              // value={vaccinesSelected?.[parent.name]?.min}
                              value={
                                vaccinesSelected?.find(
                                  (cat) => cat.id == parent.id
                                )?.min
                              }
                              disabled={
                                !vaccinesSelected.find(
                                  (parent) => parent.isParentSelected === true
                                ) || disable
                              }
                            />
                            <div className="error-msg">{errors[parent.id]}</div>
                          </div>

                          <div className="col-md-4 ">
                            <input
                              type="text"
                              className="input-control  mb-2"
                              placeholder="Max Price"
                              name="Max Price"
                              onChange={(e) =>
                                handleVaccinesSelected(
                                  parent.id,
                                  e.target.value,
                                  "max",
                                  true
                                )
                              }
                              value={
                                vaccinesSelected?.find(
                                  (cat) => cat.id == parent.id
                                )?.max
                              }
                              disabled={
                                !vaccinesSelected.find(
                                  (parent) => parent.isParentSelected === true
                                ) || disable
                              }
                            />{" "}
                            {/* <div className="error-msg">{errors[parent.id]}</div> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {parent?.subCategory?.map((child, i) => {
                return (
                  <div className="col-md-12 mb-1 mt-1 " key={i}>
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <input
                          type="checkbox"
                          // name="rememberPassword"
                          className="checkbox "
                          tabIndex="3"
                          value={JSON.stringify({
                            ...parent,
                            subCategory: [child],
                          })}
                          name={child.id}
                          checked={
                            vaccinesSelected
                              ?.find((cat) => cat.id == parent.id)
                              ?.subCategory?.find((cat) => cat.id == child.id)
                              ?.isChildSelected
                          }
                          onChange={(e) =>
                            handleChildClick(
                              e.target.value,
                              e.target.checked,
                              parent.id,
                              child.id
                            )
                          }
                          disabled={disable || false}
                        />
                        <label className="optionLabal">{child.name}</label>
                      </div>
                      <div className="col-md-4 ">
                        <input
                          type="text"
                          className="input-control  mb-2"
                          placeholder="Min Price"
                          name="Min Price"
                          onChange={(e) =>
                            handleVaccinesSelected(
                              child.id,
                              e.target.value,
                              "min",
                              false,
                              parent.id
                            )
                          }
                          value={
                            vaccinesSelected
                              ?.find((cat) => cat.id == parent.id)
                              ?.subCategory?.find((cat) => cat.id == child.id)
                              ?.min
                          }
                          disabled={
                            !vaccinesSelected
                              ?.find((cat) => cat.id == parent.id)
                              ?.subCategory?.find((cat) => cat.id == child.id)
                              ?.isChildSelected ||
                            disable ||
                            false
                          }
                        />{" "}
                      </div>
                      {/* <div className="error-msg">{errors[child.id]}</div> */}
                      <div className="col-md-4 ">
                        <input
                          type="text"
                          className="input-control  mb-2"
                          placeholder="Max Price"
                          name="Max Price"
                          onChange={(e) =>
                            handleVaccinesSelected(
                              child.id,
                              e.target.value,
                              "max",
                              false,
                              parent.id
                            )
                          }
                          value={
                            vaccinesSelected
                              ?.find((cat) => cat.id == parent.id)
                              ?.subCategory?.find((cat) => cat.id == child.id)
                              ?.max
                          }
                          disabled={
                            !vaccinesSelected
                              ?.find((cat) => cat.id == parent.id)
                              ?.subCategory?.find((cat) => cat.id == child.id)
                              ?.isChildSelected ||
                            disable ||
                            false
                          }
                        />
                      </div>
                      <div className="error-msg">{errors[child.id]}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VaccineNamePriceRange;

// Taking inputs from child click
// const handleChildClick = (value, state, parentId, childId) => {
//   const parsedValue = JSON.parse(value);
//   console.log(state, "child clicked");
//   setVaccinesSelected((lastState) => {
//     if (state) {
//       if (!lastState?.find((cat) => cat.id == parsedValue.id)) {
//         return [
//           ...lastState,
//           {
//             ...parsedValue,
//             subCategory: [
//               {
//                 ...parsedValue?.subCategory[0],
//                 isChildSelected: true,
//               },
//             ],
//           },
//         ];
//       } else {
//         return lastState?.map((cat) => {
//           if (cat.id == parsedValue.id) {
//             if (cat.subCategory.find((child) => child.id == childId)) {
//               return {
//                 ...parsedValue,
//                 subCategory: cat.subCategory.map((child) => {
//                   if (child.id == childId) {
//                     return { ...child, isChildSelected: true };
//                   } else {
//                     return child;
//                   }
//                 }),
//               };
//             } else {
//               return {
//                 ...parsedValue,
//                 subCategory: [
//                   ...cat.subCategory,
//                   { ...parsedValue.subCategory[0], isChildSelected: true },
//                 ],
//               };
//             }
//           } else {
//             return cat;
//           }
//         });
//       }
//     } else {
//       return lastState.map((cat) => {
//         if (cat.id == parsedValue.id) {
//           return {
//             ...cat,
//             isParentSelected: false,
//             subCategory: cat.subCategory?.map((child) => {
//               if (child.id == childId) {
//                 return { ...child, isChildSelected: false };
//               } else {
//                 return child;
//               }
//             }),
//           };
//         }
//         return cat;
//       });
//     }
//   });
// };

// Taking inputs from parent Click
// const handleParentClick = (value, state) => {
//   const parsedValue = JSON.parse(value);
//   setVaccinesSelected((lastState) => {
//     if (state) {
//       if (!lastState?.find((cat) => cat.id == parsedValue.id)) {
//         return [
//           ...lastState,
//           {
//             ...parsedValue,
//             isParentSelected: true,
//             subCategory: parsedValue?.subCategory?.map((child) => ({
//               ...child,
//               isChildSelected: true,
//             })),
//           },
//         ];
//       } else {
//         return lastState?.map((cat) => {
//           if (cat.id == parsedValue.id) {
//             return {
//               ...parsedValue,
//               isParentSelected: true,
//               subCategory: parsedValue?.subCategory?.map((child) => ({
//                 ...child,
//                 isChildSelected: true,
//               })),
//             };
//           } else {
//             return cat;
//           }
//         });
//       }
//     } else {
//       return lastState.map((cat) => {
//         if (cat.id == parsedValue.id) {
//           return {
//             ...cat,
//             isParentSelected: false,
//             subCategory: cat.subCategory?.map((child) => ({
//               ...child,
//               isChildSelected: false,
//             })),
//           };
//         }
//         return cat;
//       });
//     }
//   });
// };
