import React, { useEffect, useState } from "react";
import Pagination from "../../components/commonUI/Pagination";
import Switch from "../../components/commonUI/Switch";
import Confirm from "../../components/commonUI/Confirm";
import AddHospital from "../../components/UI/hospital/AddHospital";
import { useDispatch, useSelector } from "react-redux";
import { getAllHospitalAction } from "../../redux/slice/hospital/getAllHospitalSlice";
import { getHospitalByIdAction } from "../../redux/slice/hospital/getHospitalByIdSlice";
import { deleteHospitalAction } from "../../redux/slice/hospital/deleteHospitalSlice";
import { searchHospitalAction } from "../../redux/slice/hospital/searchHospitalSlice";
import { hospitalStatusAction } from "../../redux/slice/hospital/hospitalStatusSlice";

const Hospital = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateHospital, setShowUpdateHospital] = useState(false);
  const [showViewHospital, setShowViewHospital] = useState(false);
  const [deleteHospitalId, setDeleteHospitalId] = useState();
  const [message, setMessage] = useState("");
  // const [hospital, setHospitalsList] = useState([]);
  const [hospitalsList, setHospitalsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch = useDispatch();

  const {
    getAllHospital,
    deleteHospital,
    addHospital,
    updateHospital,
    searchHospital,
    hospitalStatus,
  } = useSelector((state) => ({
    getAllHospital: state.getAllHospital,
    deleteHospital: state.deleteHospital,
    addHospital: state.addHospital,
    updateHospital: state.updateHospital,
    searchHospital: state.searchHospital,
    hospitalStatus: state.hospitalStatus,
  }));

  const [searchFilters, setSearchFilters] = useState({
    hospitalName: "",
  });

  const handleInputChange = (fieldName, value) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    dispatch(getAllHospitalAction(pageNumber));
  }, [
    pageNumber,
    deleteHospital.success,
    addHospital.success,
    updateHospital.success,
    // hospitalStatus.success,
  ]);

  useEffect(() => {
    if (searchHospital.success && searchFilters.hospitalName) {
      setHospitalsList(searchHospital?.hospital);
    } else {
      setHospitalsList(getAllHospital?.hospital?.dataItems);
    }
  }, [getAllHospital.success, searchHospital.success]);

  const startSerialNumber = pageNumber * 20 + 1;

  const handleStatusChange = (value, id) => {
    // Dispatch the status change action to update the hospital status in the Redux store
    dispatch(hospitalStatusAction({ data: { status: value }, id }));

    // Update the hospitalsList state to reflect the status change
    setHospitalsList((prevHospitalsList) => {
      return prevHospitalsList.map((hospital) => {
        if (hospital.id === id) {
          return { ...hospital, status: value };
        }
        return hospital;
      });
    });
  };

  // const handleSearch = () => {
  //   setSearch(true);
  //   dispatch(searchHospitalAction({ ...searchFilters }));
  // };

  const handleSearch = (e) => {
    e.preventDefault();
    // Check if either the name or status fields are not empty
    if (searchFilters.hospitalName.length >= 3) {
      dispatch(searchHospitalAction({ ...searchFilters }));
      setMessage("");
    } else {
      setMessage("Please enter at at least 3 character of hospital name");
      setHospitalsList(getAllHospital?.hospital?.dataItems);
    }
  };

  const handleReset = () => {
    // setSearch(false);
    setHospitalsList(getAllHospital?.hospital?.dataItems);
    setSearchFilters({
      hospitalName: "",
    });
    setMessage("");
  };

  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2></h2>
          </div>
          {/* <form> */}
          <form className="row mar-20" onSubmit={handleSearch}>
            <div className=" col-md-2  input-width">
              <div className="form-group ">
                <input
                  type="text"
                  className="input-control"
                  placeholder="Hospital Name"
                  value={searchFilters.hospitalName}
                  onChange={(e) => {
                    handleInputChange("hospitalName", e.target.value);
                    !e.target.value &&
                      dispatch(getAllHospitalAction(pageNumber));
                  }}
                />
              </div>
            </div>
            <div className=" col-md-5">
              <div className="form-group ">
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>
                <button
                  className=" btn-md btn-md-blue mar-btn"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {message && <div className="alert alert-danger">{message}</div>}
          </form>
          {/* </form> */}

          <div className="colBox">
            <div className="mid-head">
              <h2>Hospitals Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Hospital
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Image</th>
                    <th scope="col">Type</th>
                    <th scope="col">Address</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {hospitalsList?.length === 0 ? (
                    <div className="nodata">No data found</div>
                  ) : (
                    hospitalsList?.map((h, index) => {
                      const serialNumber = (startSerialNumber + index)
                        .toString()
                        .padStart(2, "0");
                      return (
                        <tr>
                          <td data-label="S.No">{serialNumber}</td>
                          <td data-label="Name">{h.name}</td>
                          <td data-label="Image" align="center">
                            <img src={h?.hospitalImg} />
                          </td>
                          <td data-label="Type">
                            {h.type == "1" ? "Clinic" : "Hospital"}
                          </td>
                          <td data-label="Address">
                            <strong>City</strong> - {h.city} <br />
                            <strong>State</strong> - {h.state} <br />
                            <strong>Country</strong> - {h.country} <br />
                            <strong>Latitude</strong> - {h.latitude} <br />
                            <strong>Longitude</strong> - {h.longitude} <br />
                            <strong>Pin Code</strong> - {h.pincode} <br />
                          </td>
                          <td data-label="Status" className="tdGape">
                            <Switch
                              switchValue={h.status}
                              switchId={h.id}
                              handleChange={handleStatusChange}
                            />
                          </td>
                          <td data-label="Action">
                            <button
                              className="btn-small greenbg"
                              type="button"
                              onClick={() => {
                                setShowUpdateHospital(true);
                                dispatch(getHospitalByIdAction(h.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              type="button"
                              className=" btn-small yellowbg"
                              onClick={() => {
                                setShowViewHospital(true);
                                dispatch(getHospitalByIdAction(h.id));
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                                alt="icon"
                              />
                            </button>
                            <button
                              className=" btn-small redbg"
                              type="submit"
                              onClick={() => {
                                setShowConfirm(true);
                                setDeleteHospitalId(h.id);
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                                alt="icon"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={getAllHospital.hospital?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={getAllHospital.hospital?.totalItems}
            noOfData={getAllHospital.hospital.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}
      <AddHospital
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Add Hospital"
        type="add"
      />
      <AddHospital
        isOpen={showUpdateHospital}
        onClose={() => setShowUpdateHospital(false)}
        title="Update Hospital"
        setIsOpen={setShowUpdateHospital}
        disabled={false}
        type="update"
      />
      <AddHospital
        isOpen={showViewHospital}
        onClose={() => setShowViewHospital(false)}
        title="View Hospital"
        setIsOpen={setShowViewHospital}
        disabled={true}
        type="view"
      />
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteHospitalAction(deleteHospitalId));
        }}
      />
    </>
  );
};

export default Hospital;
