import React, { useState } from "react";
import useWindowSize from "../../../utils/Hooks/useWindowSize";
import Modal from "../../commonUI/Modal";
import Success from "../../commonUI/Success";
import { useFormik } from "formik";
import { vaccineSchema } from "../../../utils/schema";
import { showError } from "../../../utils/utils";
import Switch from "../../commonUI/Switch";
import { useDispatch, useSelector } from "react-redux";
import { addVaccineAction } from "../../../redux/slice/vaccine/addVaccineSlice";
import { updateVaccineAction } from "../../../redux/slice/vaccine/updateVaccineSlice";
import { useEffect } from "react";

const initValues = {
  name: "",
  description: "",
  days: "",
  vaccineType: "",
  parentVaccineId: "",
  status: "1",
};

const VaccineForm = ({ isOpen, setIsOpen, title, disabled, type }) => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [closeFlag, setCloseFlag] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);

  //   Getting values from store
  const addVaccine = useSelector((s) => s.addVaccine);
  const { vaccines } = useSelector((s) => s.allVaccines);
  const updateVaccine = useSelector((s) => s.updateVaccine);
  const getVaccineById = useSelector((s) => s.getVaccineById);

  //   Setting initialValues
  useEffect(() => {
    if (type === "add") {
      setInitialValues(initValues);
    } else if (
      (type === "view" || type === "update") &&
      getVaccineById.success
    ) {
      setInitialValues(getVaccineById.vaccine);
    }
  }, [getVaccineById.success]);

  //   Closing Modal
  useEffect(() => {
    if ((addVaccine.success || updateVaccine.success) && closeFlag) {
      setSuccess(true);
      setCloseFlag(false);
    }
  }, [addVaccine.success, updateVaccine.success]);

  //   handling Form
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: vaccineSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (type === "add") {
        dispatch(addVaccineAction(values));
        action.resetForm();
      } else if (type === "update") {
        dispatch(updateVaccineAction({ id: values.id, data: values }));
      }
      setCloseFlag(true);
    },
  });

  let left = windowSize > 768 ? "calc(50% - 400px)" : "2.5%";
  let width = windowSize > 768 ? "800px" : "95%";
  const modalStyle = {
    width,
    top: "10vh",
    height: "auto",
    background: "white",
    left,
    padding: "1rem",
    overflow: "auto",
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} style={modalStyle}>
      <div className="top">
        <div className="heading">
          <h3>{title || "Vaccine Form"}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Vaccine name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                  disabled={disabled}
                />
                {showError(errors.name, touched.name)}
              </div>

              <div className=" col-md-6 ">
                <label>
                  Description<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="description"
                  name="description"
                  onChange={handleChange}
                  value={values?.description}
                  disabled={disabled}
                />
                {showError(errors.description, touched.description)}
              </div>
            </div>
          </div>

          {/* NumberOfDay vaccineType */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>
                  No of Day<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="days"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.days}
                  disabled={disabled}
                >
                  <option>Select</option>
                  <option value="0-6 Weeks">0-6 Week</option>
                  <option value="0-10 Weeks">0-10 Week</option>
                  <option value="0-14 Weeks">0-14 Week</option>
                  <option value="0-6 Months">0-6 Months</option>
                  <option value="0-12 Months">0-12 Months</option>
                  <option value="0-15 Months">0-15 Months</option>
                  <option value="0-18 Months">0-18 Months</option>
                  <option value="0-6 Years">0-6 Years</option>
                  <option value="0-14 Years">0-14 Years</option>
                  <option value="0-18 Years">0-18 Years</option>
                </select>
                {showError(errors.days, touched.days)}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Vaccine Type<span>*</span>
                </label>
                <select
                  className="input-control"
                  name="vaccineType"
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "1") {
                      setFieldValue("parentVaccineId", "");
                    }
                  }}
                  onBlur={handleBlur}
                  value={values?.vaccineType}
                  disabled={disabled}
                >
                  <option>Select</option>
                  <option value="1">Parent</option>
                  <option value="2">Child</option>
                </select>{" "}
                {showError(errors.vaccineType, touched.vaccineType)}
              </div>
            </div>
          </div>

          {/* phoneNumber roleType */}
          <div className="Add-form-group">
            <div className="row">
              {values.vaccineType === "2" && (
                <div className=" col-md-6 ">
                  <label>
                    Parent Vaccine Name<span>*</span>
                  </label>
                  <select
                    className="input-control"
                    name="parentVaccineId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.parentVaccineId}
                    disabled={disabled}
                  >
                    <option value="">Select</option>
                    {vaccines
                      .filter((v) => v.vaccineType === "1")
                      ?.map((v, i) => {
                        return <option value={v.id}>{v.name}</option>;
                      })}
                  </select>
                  {showError(errors.parentVaccineId, touched.parentVaccineId)}
                </div>
              )}

              <div className=" col-md-6 ">
                <label>
                  Status<span>*</span>
                </label>
                <Switch switchValue={values.status} disabled={disabled} />
                {showError(errors.status, touched.status)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
            {!disabled && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={`Vaccine ${type === "add" ? "added" : "updated"} successfully`}
        descMessage={`Vaccine has been ${
          type === "add" ? "added" : "updated"
        }  successfully!`}
        closePreviousModal={() => setIsOpen(false)}
      />
    </Modal>
  );
};

export default VaccineForm;
