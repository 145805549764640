import React, { useEffect, useState } from "react";
import Confirm from "../../components/commonUI/Confirm";
import Switch from "../../components/commonUI/Switch";
import Pagination from "../../components/commonUI/Pagination";
import "../../styles/Patient.css";
import AddBlog from "../../components/UI/blog/AddBlog";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogAction } from "../../redux/slice/blogs/getAllBlogSlice";
import { blogStatusAction } from "../../redux/slice/blogs/blogStatusSlice";
import { getBlogByIdAction } from "../../redux/slice/blogs/getBlogByIdSlice";
import { deleteBlogAction } from "../../redux/slice/blogs/deleteBlogSlice";
import { truncateHTML, truncateString } from "../../utils/utils";

const Blogs = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateBlog, setShowUpdateBlog] = useState(false);
  const [showViewBlog, setShowViewBlog] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [deleteBlogId, setDeleteBlogId] = useState();

  const dispatch = useDispatch();
  const { getAllBlog, blogStatus, deleteBlog, addBlog, updateBlog } =
    useSelector((state) => ({
      getAllBlog: state.getAllBlog,
      deleteBlog: state.deleteBlog,
      addBlog: state.addBlog,
      blogStatus: state.blogStatus,
      updateBlog: state.updateBlog,
    }));

  useEffect(() => {
    dispatch(getAllBlogAction(pageNumber));
  }, [
    pageNumber,
    blogStatus.success,
    deleteBlog.success,
    addBlog.success,
    updateBlog.success,
  ]);

  const handleStatusChange = (value, id) => {
    dispatch(blogStatusAction({ data: { status: value }, id }));
  };

  const startSerialNumber = pageNumber * 20 + 1;
  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            <h2></h2>
          </div>

          <div className="colBox">
            <div className="mid-head">
              <h2>Blogs Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Blog
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Description</th>
                    <th scope="col">Language</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllBlog.blog?.dataItems?.map((blog, index) => {
                    const serialNumber = (startSerialNumber + index)
                      .toString()
                      .padStart(2, "0");
                    return (
                      <tr key={index}>
                        <td data-label="S.No">{serialNumber}</td>
                        <td data-label="Title" className="BText">
                          {truncateString(blog.name, 50)}
                        </td>
                        <td
                          data-label="Description"
                          className="align-middle BText"
                          dangerouslySetInnerHTML={{
                            __html: truncateHTML(blog.description, 50),
                          }}
                        ></td>
                        <td>
                          {blog.languageType === "0" ? "English" : "हिंदी"}
                        </td>
                        <td data-label="Status" className="tdGape">
                          <Switch
                            switchValue={blog.status}
                            switchId={blog.id}
                            handleChange={handleStatusChange}
                          />
                        </td>
                        <td data-label="Action">
                          <button
                            className=" btn-small greenbg"
                            type="button"
                            onClick={() => {
                              setShowUpdateBlog(true);
                              dispatch(getBlogByIdAction(blog.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            type="button"
                            className=" btn-small yellowbg"
                            onClick={() => {
                              setShowViewBlog(true);
                              dispatch(getBlogByIdAction(blog.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            className=" btn-small redbg"
                            type="submit"
                            onClick={() => {
                              setShowConfirm(true);
                              setDeleteBlogId(blog.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                              alt="icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            totalPages={getAllBlog.blog?.totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalItems={getAllBlog.blog?.totalItems}
            noOfData={getAllBlog.blog?.dataItems?.length}
          />
        </div>
      </div>
      {/* <Outlet /> */}
      <AddBlog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        title="Add Blog"
        type="add"
      />
      <AddBlog
        isOpen={showUpdateBlog}
        onClose={() => setShowUpdateBlog(false)}
        title="Update Blog"
        setIsOpen={setShowUpdateBlog}
        type="update"
        disabled={false}
      />
      <AddBlog
        isOpen={showViewBlog}
        onClose={() => setShowViewBlog(false)}
        title="View Blog"
        setIsOpen={setShowViewBlog}
        disabled={true}
        type="view"
      />
      <Confirm
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        handleConfirm={() => {
          dispatch(deleteBlogAction(deleteBlogId));
        }}
      />
    </>
  );
};

export default Blogs;
