import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTopTenAppointmentAction } from "../../redux/slice/dashboard/getTopTenAppointmentSlice";
import Loader from "../../components/commonUI/Loader";
import { appointmentStatus, appointmentStatusColors } from "../../utils/data";
import { dashboardCountsAction } from "../../redux/slice/dashboard/dashboardCountsSlice";

const Dashboard = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useDispatch();
  const { getTopTenAppointment } = useSelector((state) => state);
  const counts = useSelector((s) => s.dashboardCounts);

  useEffect(() => {
    if (document.fullscreenElement === null && fullScreen) {
      document.body.requestFullscreen();
    } else if (document.fullscreenElement !== null && !fullScreen) {
      document.exitFullscreen();
    }
  }, [fullScreen]);

  useEffect(() => {
    dispatch(getTopTenAppointmentAction());
    dispatch(dashboardCountsAction());
  }, []);

  function formatAddress(address) {
    const parts = [];
    if (address?.address) {
      parts.push(address.address);
    }

    if (address?.landmark) {
      parts.push(address.landmark);
    }

    if (address?.city) {
      parts.push(address.city);
    }

    if (address?.state) {
      parts.push(address.state);
    }

    if (address?.country && address?.pincode) {
      parts.push(`${address.country} - ${address.pincode}`);
    }

    // If no valid parts are found, return "NA"
    if (parts.length === 0) {
      return "NA";
    }

    // Join the valid parts with a comma and space
    return parts.join(", ");
  }
  return (
    <>
      <div className="dashboard">
        <div className="dashboardHeader">
          <div className="left">
            <h2>
              Welcome To Teekakaran
              {/* <br />
              <span>Administration</span> */}
            </h2>
          </div>
        </div>
        <div className="container container-padd">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <Link to="/patient">
                    <div className="boxDashbord">
                      <div className="box-left">
                        <div className="icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons-images/dashboard/TotalPatients.svg`}
                            alt="icon"
                          />
                        </div>
                        <div className="content">
                          <h6>Total Patients</h6>
                          <span>
                            {counts.counts?.totalNoOfPatients || "00"}
                          </span>
                        </div>
                      </div>
                      <div className="box-right">
                        <img
                          src={`${process.env.PUBLIC_URL}/icons-images/dashboard/Arrow.svg`}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link to="/doctor">
                    <div className="boxDashbord">
                      <div className="box-left">
                        <div className="icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons-images/dashboard/TotalDoctors.svg`}
                            alt="icon"
                          />
                        </div>
                        <div className="content">
                          <h6>Total Doctors</h6>
                          <span>{counts.counts?.totalNoOfDoctor || "00"}</span>
                        </div>
                      </div>
                      <div className="box-right">
                        <img
                          src={`${process.env.PUBLIC_URL}/icons-images/dashboard/Arrow.svg`}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-6">
                  <Link to="/appointments">
                    <div className="boxDashbord">
                      <div className="box-left">
                        <div className="icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons-images/dashboard/RescheduledAppointments.svg`}
                            alt="icon"
                          />
                        </div>
                        <div className="content">
                          <h6>Today Appointment</h6>
                          <span>
                            {counts.counts?.todayNoOfAppointment || "00"}
                          </span>
                        </div>
                      </div>
                      <div className="box-right">
                        <img
                          src={`${process.env.PUBLIC_URL}/icons-images/dashboard/Arrow.svg`}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-6">
                  <Link to="/appointments">
                    <div className="boxDashbord">
                      <div className="box-left">
                        <div className="icon">
                          <img
                            src={`${process.env.PUBLIC_URL}/icons-images/dashboard/TotalAppoinments.svg`}
                            alt="icon"
                          />
                        </div>
                        <div className="content">
                          <h6>Upcoming Appointment</h6>
                          <span>
                            {counts.counts?.upcomingAppointment || "00"}
                          </span>
                        </div>
                      </div>
                      <div className="box-right">
                        <img
                          src={`${process.env.PUBLIC_URL}/icons-images/dashboard/Arrow.svg`}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-head">
            <h2>Top 10 Appointments</h2>
          </div>
          <div className="mid-table MobileTable">
            <table className="table" cellSpacing="2" cellPadding="0" border="0">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Appointment Date</th>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Booked Vaccine</th>
                  <th scope="col">Appointed Doctor</th>
                  <th scope="col">Appointed Center</th>
                  <th scope="col">Appointed Time</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {getTopTenAppointment.loading ? (
                  <Loader />
                ) : getTopTenAppointment.appointment?.length === 0 ? (
                  <p className="NoDatafound">No Data found!</p>
                ) : (
                  getTopTenAppointment.appointment?.map((a, i) => {
                    return (
                      <tr>
                        <td data-label="S.No">{i + 1}</td>
                        <td data-label="Appointment Date">
                          {a?.appointmentDate || "NA"}
                        </td>
                        <td data-label="Patient Name">
                          {a?.patientName || "NA"}
                        </td>
                        <td
                          data-label="Booked Vaccine"
                          style={{ whiteSpace: "normal" }}
                        >
                          {a?.bookedVaccineName?.join(", ") || "NA"}
                        </td>
                        <td data-label="Appointed Doctor">
                          {a?.doctorName || "NA"}
                        </td>
                        <td
                          data-label="Appointed Center"
                          style={{ whiteSpace: "normal" }}
                        >
                          {/* {a?.address?.length > 25
                            ? a?.address.substring(0, 25) + "..."
                            : a?.address || "NA"} */}
                          {formatAddress(a)}
                        </td>
                        <td data-label="Appointed Time">
                          {a?.appointmentTime || "NA"}
                        </td>
                        <td
                          data-label="Status"
                          style={{
                            color: appointmentStatusColors[Number(a?.status)],
                            whiteSpace: "normal",
                          }}
                        >
                          {appointmentStatus[Number(a?.status)]}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
