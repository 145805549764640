import { useFormik } from "formik";
import React, { useState } from "react";
import { showError } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction } from "../../redux/slice/auth/changePasswordSlice";
import { changePasswordSchema } from "../../utils/schema";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routeFlag, setRouteFlag] = useState(false);
  const changePassword = useSelector((state) => state.changePassword);

  useEffect(() => {
    changePassword.success && routeFlag && navigate("/dashboard");
  }, [changePassword.success, routeFlag]);

  const { handleChange, handleBlur, values, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: changePasswordSchema,
      onSubmit: (values, action) => {
        console.log(values);
        dispatch(changePasswordAction(values));
        action.resetForm();
        setRouteFlag(true);
      },
    });
  return (
    <div className="doctor-wrapper" style={{ background: "white" }}>
      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit} className="col-lg-8">
            <div className="mar-30 cardhead mt-5 ml-4">
              <div className="heading">
                <h2>Change Password</h2>
              </div>
              <div className="Add-form-group ">
                <div className="row mt-3">
                  <div className="col-md-12 mt-2">
                    <label>
                      Current Password<span>*</span>
                    </label>
                    <input
                      type="password"
                      className="input-control"
                      placeholder="Current Password"
                      name="oldPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oldPassword}
                    />
                    {showError(errors.oldPassword, touched.oldPassword)}
                  </div>

                  <div className="col-md-6 mt-2">
                    <label>
                      New Password<span>*</span>
                    </label>
                    <input
                      type="password"
                      className="input-control"
                      placeholder="New Password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                    />
                    {showError(errors.newPassword, touched.newPassword)}
                  </div>

                  <div className=" col-md-6 mt-2">
                    <label>
                      Confirm New Password<span>*</span>
                    </label>
                    <input
                      type="password"
                      className="input-control"
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                    />
                    {showError(errors.confirmPassword, touched.confirmPassword)}
                  </div>
                </div>
              </div>

              {/* repeet */}
              <div className="form-group aling-right">
                <button
                  type="button"
                  className="btn btn-outline-primary big-btn-padd"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-big big-btn-padd"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
