import React, { useState } from "react";
import Pagination from "../../components/commonUI/Pagination";
import Switch from "../../components/commonUI/Switch";
import Confirm from "../../components/commonUI/Confirm";
import VaccineForm from "../../components/UI/vaccine/VaccineForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vaccineStatusAction } from "../../redux/slice/vaccine/VaccineStatusSlice";
import { getVaccinesAction } from "../../redux/slice/common/getVaccinesSlice";
import { getVaccineByIdAction } from "../../redux/slice/vaccine/getVaccineByIdSlice";
import { deleteVaccineAction } from "../../redux/slice/vaccine/deleteVaccineSlice";

const Vaccine = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpdateVaccine, setShowUpdateVaccine] = useState(false);
  const [showViewVaccine, setShowViewVaccine] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  // Getting values from store
  const vaccines = useSelector((s) => s.allVaccines);
  const addVaccine = useSelector((s) => s.addVaccine);
  const updateVaccine = useSelector((s) => s.updateVaccine);
  const deleteVaccine = useSelector((s) => s.deleteVaccine);

  useEffect(() => {
    dispatch(getVaccinesAction());
  }, [addVaccine.success, updateVaccine.success, deleteVaccine.success]);

  function handleStatusChange(value, id) {
    dispatch(vaccineStatusAction({ data: { status: value }, id }));
  }

  const startSerialNumber = 0 * 20 + 1;
  return (
    <>
      <div className="doctor-wrapper">
        <div className="container container-padd">
          <div className="mid-head mar-20">
            {/* <h2>Recordified Vaccines </h2> */}
          </div>

          <div className="colBox">
            <div className="mid-head">
              <h2>Vaccines Listing</h2>
            </div>
            <div>
              <button
                to="/doctors/add-doctor"
                className=" btn-md btn-md-blue"
                type="button"
                onClick={() => setIsOpen(true)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons-images/plus.svg`}
                  alt="icon"
                />
                Add Vaccine
              </button>
            </div>
          </div>
          {
            <div className="mid-table">
              <table
                className="table MobileTable"
                cellSpacing="2"
                cellPadding="0"
                border="0"
              >
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">No of Day</th>
                    <th scope="col">Vaccine Type</th>
                    <th scope="col">Parent Vaccine Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vaccines.vaccines?.map((v, i) => {
                    return (
                      <tr>
                        <td data-label="S.No" key={i}>
                          {(startSerialNumber + i).toString().padStart(2, "0")}
                        </td>
                        <td data-label="Title">{v.name}</td>
                        <td data-label="Description" className="BText">
                          {v.description?.length > 40
                            ? v.description?.slice(0, 40) + "..."
                            : v.description}
                        </td>
                        <td data-label="No of Day">{v.days}</td>
                        <td data-label="Vaccine Type">
                          {v.vaccineType === "1" ? "Parent" : "Child"}
                        </td>
                        <td data-label="Parent Vaccine Name">
                          {v.parentVaccinesName || "NA"}
                        </td>
                        <td data-label="Status" className="tdGape">
                          <Switch
                            switchValue={v.status}
                            switchId={v.id}
                            handleChange={handleStatusChange}
                          />
                        </td>
                        <td data-label="Action">
                          <button
                            className=" btn-small greenbg"
                            type="button"
                            onClick={() => {
                              setShowUpdateVaccine(true);
                              setId(v.id);
                              dispatch(getVaccineByIdAction(v.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/edit-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            type="button"
                            className=" btn-small yellowbg"
                            onClick={() => {
                              setShowViewVaccine(true);
                              setId(v.id);
                              dispatch(getVaccineByIdAction(v.id));
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/view-small.svg`}
                              alt="icon"
                            />
                          </button>
                          <button
                            className=" btn-small redbg"
                            type="submit"
                            onClick={() => {
                              setShowConfirm(true);
                              setId(v.id);
                            }}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/icons-images/delete-small.svg`}
                              alt="icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          }

          <Pagination />
        </div>
      </div>
      {isOpen && (
        <VaccineForm
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          setIsOpen={setIsOpen}
          title="Add Vaccine"
          type="add"
        />
      )}

      {showUpdateVaccine && (
        <VaccineForm
          isOpen={showUpdateVaccine}
          onClose={() => setShowUpdateVaccine(false)}
          title="Update Vaccine"
          setIsOpen={setShowUpdateVaccine}
          type="update"
          id={id}
        />
      )}

      {showViewVaccine && (
        <VaccineForm
          isOpen={showViewVaccine}
          onClose={() => setShowViewVaccine(false)}
          title="View Vaccine"
          setIsOpen={setShowViewVaccine}
          type="view"
          id={id}
          disabled={true}
        />
      )}

      {showConfirm && (
        <Confirm
          isOpen={showConfirm}
          handleConfirm={() => dispatch(deleteVaccineAction(id))}
          onClose={() => setShowConfirm(false)}
        />
      )}
    </>
  );
};

export default Vaccine;
