import { toast } from "react-toastify";

// Function to call api
// api/apiUtils.js
export async function makeApiRequest(
  url,
  { method = "GET", data = null, token = null, apiKey = null }
) {
  try {
    let newOptions = {
      method,
      body: data,
    };

    const headers = {};

    if (token) {
      headers["x-access-token"] = token;
    }

    if (apiKey) {
      headers["x-api-key"] = apiKey;
    }

    if (data === null) {
      newOptions = newOptions;
    } else if (data instanceof FormData) {
      // to check if the data has file
      newOptions = { ...newOptions, body: data };
    } else {
      headers["Content-Type"] = "application/json";
      newOptions = { ...newOptions, body: JSON.stringify(data) };
    }

    newOptions.method = method;
    newOptions.headers = headers;

    const response = await fetch(
      process.env.REACT_APP_BASE_URL + url,
      newOptions
    );
    const processedData = await response.json();

    // if (response.ok) {
    //   return processedData;
    // }
    // else {
    //   throw new Error(processedData.message);
    // }

    if (response.ok) {
      return processedData;
    } else {
      if (response.status === 401) {
        removeUserSession();
        window.location.href = "/";
      }
      throw new Error(processedData.message);
    }
  } catch (error) {
    throw error;
  }
}

export function truncateString(str, maxLength = 50) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

export function truncateHTML(html, maxLength) {
  // Create a temporary element to parse the HTML
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  // Extract the text content and apply the character limit
  const textContent = tempElement.textContent || tempElement.innerText;
  if (textContent.length > maxLength) {
    return textContent.slice(0, maxLength) + "...";
  } else {
    return textContent;
  }
}

// Show Validation Error
export const showError = (error, touched) => {
  if (touched && error) {
    return <div className="error-msg">{error}</div>;
  }
  return null;
};

// return the token from the Localstorage
export const getToken = () => {
  return localStorage.getItem("accessToken") || null;
};

// remove the token and user from the Localstorage
export const removeUserSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
};

// set the token and user from the Localstorage
export const setUserSession = (token, user) => {
  localStorage.setItem("accessToken", token);
  localStorage.setItem("user", JSON.stringify(user));
};

// Return the user data from the LocalStorage storage
export const getCurrentUserLT = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const userRole = () => {
  const user = getCurrentUserLT();
  if (user) {
    switch (user.roles[0]) {
      case "ROLE_ADMIN":
        return "ROLE_ADMIN";
        break;
      case "ROLE_SUPPORT":
        return "ROLE_SUPPORT";
        break;
      case "ROLE_SELLER":
        return "ROLE_SELLER";
        break;
      case "ROLE_BUYER":
        return "ROLE_BUYER";
        break;
      default:
        return undefined;
        break;
    }
  }
};

// Notification message
export const notify = (message, type = "success") => {
  const option = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  if (type === "success") {
    toast.success(message, option);
  } else if (type === "warn") {
    toast.warn(message, option);
  } else if (type === "error") {
    toast.error(message, option);
  }
};

export function getFormattedDate(dateString) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", options);

  const dayNumber = date.getDate();
  const suffix = getNumberSuffix(dayNumber);

  const [dayName, month, , year] = formattedDate.split(" ");

  return `${dayName} ${dayNumber}${suffix} ${month}, ${year}`;
}

function getNumberSuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

// export const getSerielNumber = (index, page = 0) => {
//   return `${index < 9 ? page : ""}${index + 1}`;
// };
export const getSerielNumber = (index, page = 0) => {
  const serialNumber = page * 10 + (index + 1);
  return serialNumber.toString().padStart(2, "0");
};

export const localDateFormat = (timeStamp) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (timeStamp) {
    const date = new Date(timeStamp);
    let day = 0;
    date.getDate() < 10 ? (day = "0" + date.getDate()) : (day = date.getDate());

    return day + " " + monthNames[date.getMonth()] + ", " + date.getFullYear();
  } else {
    return "dd-mm-yy";
  }
};

export function reverseDate(dateString) {
  const monthHash = {
    jan: "01",
    feb: "02",
    mar: "03",
    apr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    aug: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dec: "12",
  };
  const dateParts = dateString?.toString()?.split(" ");
  if (dateParts) {
    const year = dateParts[3];
    // const month = dateParts[1];
    const month = monthHash[dateParts[1].toLowerCase()] || dateParts[1];
    const day = dateParts[2];
    return `${day}-${month}-${year}`;
  } else {
    return "NA";
  }
}

// export const inputDate = (date) => {
//   const newDate = new Date(date);
//   const day =
//     newDate.getDay() < 10
//       ? "0" + (newDate.getDay() + 1)
//       : "" + (newDate.getDay() + 1);
//   const month =
//     newDate.getMonth() < 10
//       ? "0" + (newDate.getMonth() + 1)
//       : "" + (newDate.getMonth() + 1);
//   const year = newDate.getFullYear();
//   return day + "-" + month + "-" + year;
// };

export function inputDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Adding 1 to get the correct month (0 - 11)
  const year = date.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}-${month
    .toString()
    .padStart(2, "0")}-${year}`;

  return formattedDate;
}

export const getOrderStatus = (status) => {
  const allStatus = [
    "Draft",
    "Pending",
    "Payment Failed",
    "Cancel",
    "RTO",
    "Completed",
    "Shipped",
  ];
  return allStatus[status - 1];
};

export function convertDateFormat(dateStr) {
  if (dateStr === false || dateStr === "") return dateStr;
  // Create a new Date object with the input date string
  const date = new Date(dateStr);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    // Invalid date
    return dateStr; // Return the input as-is
  }
  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month as it's zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate the parts in the desired order with slashes
  const convertedDate = `${day}/${month}/${year}`;

  return convertedDate;
}

export function reverseDateFormat(dateStr) {
  if (dateStr === false || dateStr === "") return dateStr;
  // Create a new Date object with the input date string
  const date = new Date(dateStr);

  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month as it's zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Concatenate the parts in the desired order with slashes
  const convertedDate = `${year}-${month}-${day}`;
  return convertedDate;
}

// Converting 24 time to 12 hr time
export function Time12hr(time) {
  const dividedTime = time.split(":");
  if (dividedTime[0] > 12) {
    return `${String(24 - dividedTime[0]).padStart(2, "0")}:${
      dividedTime[1]
    } PM`;
  } else {
    return `${dividedTime[0]}: ${dividedTime[1]} AM`;
  }
}

// convert vaccine to parent child
export function organizeVaccines(data) {
  const vaccinesById = {};
  const parentChildMap = {};

  for (const vaccine of data) {
    vaccinesById[vaccine.id] = vaccine;
    const parentVaccineId = vaccine.parentVaccineId.toString();
    if (parentVaccineId !== "") {
      parentChildMap[parentVaccineId] = parentChildMap[parentVaccineId] || [];
      parentChildMap[parentVaccineId].push(vaccine.id);
    }
  }

  const organizedVaccines = [];

  for (const vaccine of data) {
    const vaccineCopy = { ...vaccine };
    const vaccineId = vaccine.id.toString();
    const childIds = parentChildMap[vaccineId] || [];
    const children = childIds.map((childId) => vaccinesById[childId]);

    if (children.length > 0) {
      vaccineCopy.children = children;
    }

    if (vaccine.parentVaccineId === "") {
      organizedVaccines.push(vaccineCopy);
    }
  }

  return organizedVaccines;
}

export function areAllErrorsCleared(customError) {
  // Iterate over the keys in the customError object
  for (const key in customError) {
    if (customError.hasOwnProperty(key)) {
      // Check if the value is not null and not false
      if (customError[key] !== null && customError[key] !== false) {
        // If any value is not null or false, return false
        return false;
      }
    }
  }
  // If all values are null or false, return true
  return true;
}

export const convertToCSV = (data, customHeader) => {
  // Implement your CSV conversion logic here
  const header = customHeader.join(","); // Use your custom header
  const rows = data.map((item) => Object.values(item).join(","));
  return header + "\n" + rows.join("\n");
};
