import React, { useEffect, useState } from "react";
import Success from "../../commonUI/Success";
import Switch from "../../commonUI/Switch";
import TextEditor from "../../commonUI/TextEditot";
import { useDispatch, useSelector } from "react-redux";
import { addCMSAction } from "../../../redux/slice/cms/addCMSSlice";
import { updateCMSAction } from "../../../redux/slice/cms/updateCMSSlice";
import { useFormik } from "formik";
import { showError } from "../../../utils/utils";
import { cmsAddSchema, cmsUpdateSchema } from "../../../utils/schema";

const initialValues = {
  cmsImage: "",
  title: "",
  description: "",
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
  status: "0",
  languageType: "0",
};

// CMSForm
const AddCMSForm = ({ onClose, disable, type }) => {
  const [success, setSuccess] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [routeFlag, setRouteFlag] = useState(false);

  const dispatch = useDispatch();
  const addCMS = useSelector((state) => state.addCMS);
  const getCMSById = useSelector((state) => state.getCMSById);
  const updateCMS = useSelector((state) => state.updateCMS);

  const [cmsValues, setCmsValues] = useState(initialValues);

  useEffect(() => {
    if (getCMSById.success) {
      if (type === "add") {
        setPreviewUrl("");
        setCmsValues(initialValues);
      } else {
        setPreviewUrl(getCMSById.cms?.cmsImage);
        setCmsValues(getCMSById.cms);
      }
    }
  }, [getCMSById.success, type]);

  const validationSchema = () => {
    if (type === "add") {
      return cmsAddSchema;
    } else if (type === "update") {
      return cmsUpdateSchema;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (addCMS.success && routeFlag) {
      setSuccess(true);
    } else if (updateCMS.success && routeFlag) {
      setSuccess(true);
    }
  }, [addCMS.success, updateCMS.success]);

  let validateForm = (values) => {
    let errors = {};
    if (values.description === "" || values.description == "<p><br></p>") {
      errors["description"] = "Please enter a description";
    }
    return errors;
  };

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: cmsValues,
    validate: validateForm,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      // Loop through the values object and append each key-value pair to the FormData
      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
      }

      if (type === "add") {
        dispatch(addCMSAction(formData));
      } else if (type === "update") {
        dispatch(updateCMSAction({ id: values.id, data: formData }));
      }
      setRouteFlag(true);
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mar-30">
          {/* fname lname */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Title<span>*</span>
                </label>
                <input
                  type="text"
                  className="input-control"
                  placeholder="Title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.title}
                  disabled={disable}
                />
                {showError(errors.title, touched.title)}
              </div>
            </div>
          </div>

          {/* Email Gender */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-12 ">
                <label>
                  Description<span>*</span>
                </label>
                <TextEditor
                  name="description"
                  setFieldValue={setFieldValue}
                  handleBlur={() => setFieldTouched("description")}
                  value={values?.description}
                  disabled={disable}
                />
                {showError(errors.description, touched.description)}
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Language</label>
                <select
                  className="input-control"
                  name="languageType"
                  value={values?.languageType}
                  disabled={disable}
                  onChange={handleChange}
                >
                  <option value="0">English</option>
                  <option value="1">Hindi</option>
                </select>
              </div>

              <div className=" col-md-6">
                <label>Meta Title</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaTitle"
                  placeholder="Meta title"
                  onChange={handleChange}
                  value={values?.metaTitle}
                  disabled={disable}
                ></textarea>
              </div>
            </div>
          </div>

          {/* phoneNumber roleType */}
          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Meta Keywords</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaKeyword"
                  placeholder="Meta keywords"
                  onChange={handleChange}
                  value={values?.metaKeyword}
                  disabled={disable}
                ></textarea>
              </div>
              <div className=" col-md-6 ">
                <label>Meta Description</label>
                <textarea
                  className="input-control textAreaHeight"
                  name="metaDescription"
                  onChange={handleChange}
                  placeholder="Meta description"
                  value={values?.metaDescription}
                  disabled={disable}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="Add-form-group">
            <div className="row">
              <div className=" col-md-6 ">
                <label>Choose File</label>
                <input
                  type="file"
                  name="cmsImage"
                  id="cmsImage"
                  tabIndex="1"
                  disabled={disable}
                  onChange={(e) => {
                    const file = e.currentTarget.files[0];
                    // Verify if the file is a valid image
                    if (file) {
                      setFieldValue("cmsImage", file);
                      setPreviewUrl(URL.createObjectURL(file));
                    } else {
                      // Handle the case when the selected file is not a valid image
                      setPreviewUrl(null);
                      setFieldValue("cmsImage", file);
                    }
                  }}
                  // onBlur={handleBlur}
                />
                {previewUrl && (
                  <div className="img">
                    <div className="squareImgPreview">
                      <img src={previewUrl} alt="profile" width="100" />
                    </div>
                  </div>
                )}
              </div>
              <div className=" col-md-6 ">
                <label>
                  Status<span>*</span>
                </label>
                <Switch
                  disabled={disable}
                  switchValue={values.status}
                  setFieldValue={setFieldValue}
                />
                {showError(errors.status, touched.status)}
              </div>
            </div>
          </div>

          {/* submit */}
          <div className="form-group aling-right">
            <button
              type="button"
              className="btn btn-outline-primary big-btn-padd"
              onClick={onClose}
            >
              Cancel
            </button>
            {!disable && (
              <button
                type="submit"
                className="btn btn-primary-big big-btn-padd"
                // onClick={() => {
                //   setSuccess(true);
                // }}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
      <Success
        isOpen={success}
        onClose={() => setSuccess(false)}
        message={` ${type === "add" ? "Added" : "Updated"} successfully`}
        descMessage={`CMS has been ${
          type === "add" ? "added" : "updated"
        }  successfully!`}
        closePreviousModal={onClose}
      />
    </>
  );
};

export default AddCMSForm;
